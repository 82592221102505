import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
// import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  params;
  baseUrl
  constructor(private  router: Router, private activatedRoute: ActivatedRoute) {
    // this.activatedRoute.params.subscribe((params) => {
    //   console.log(params, this.params);
    //   this.params = params.type;
    //   if ( this.params === '9c4204b11ba43719fa271f4c26ddbd2d') { // logged in so return true
    //     console.log('runnn');

      
    // } else {
    //           console.log('runnn else ');

    //   this.router.navigate(['/'], {
    //     // queryParams: {
    //     //   ver: Math.random().toString(36).substr(2, 5),
    //     //   nocache: 1
    //     // }
    //   });
    //   localStorage.clear();
    //   return false;
    // }
    // })
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // console.log(window.location.hostname);
      if(window && (window.location.hostname == 'staging.igmcorp.net' || window.location.hostname == 'localhost')) {
        return true;
      } else {
        this.router.navigate([''], {
           
        });
        return false;

      }

  
    return true;
  } 
  
}
