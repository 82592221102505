import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SharedService, ApiService, SeoServiceService } from '../service/index'
declare let $: any;
declare let jQuery: any;
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  location;
  categories;
  sliderData;
  @ViewChild('heroslider')
  heroslider: ElementRef;
  @ViewChild('gallery')
  gallery: ElementRef;
  tinyUrl = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  address: any;
  copyTextflag = false;
  constructor(private shared: SharedService, private api: ApiService,
    private router: Router, private activatedRoute: ActivatedRoute, private _seoService: SeoServiceService,
    private clipboard: Clipboard) {
    this.sliderdata()
    this.getCategory();
    this.shared.cleardata();
    this.getLocation();
    this.getSeoData()
  }

  ngOnInit(): void {
    console.log(this.tinyUrl)
    // this.router.events.pipe(
    //   filter((event) => event instanceof NavigationEnd),
    //   map(() => this.activatedRoute),
    //   map((route) => {
    //     while (route.firstChild) route = route.firstChild;
    //     return route;
    //   }),
    //   filter((route) => route.outlet === 'primary'),
    //   mergeMap((route) => route.data)
    //  )
    //  .subscribe((event) => {
    //    this._seoService.updateTitle(event['title']);
    //    //  this._seoService.updateDescription(event['title'] + event['description'])
    //   //  this._seoService.updateOgUrl(event['ogUrl']);
    //    //Updating Description tag dynamically with title
    //  }); 
  }
  ngAfterViewInit() {
    $(document).ready(function () {
      $('.hero-slider') ? $('.hero-slider').owlCarousel({
        loop: false,
        rewind: true,
        margin: 10,
        //animateOut: 'slideInRight',
        animateIn: 'slide',
        smartSpeed: 1000,
        //default settings:
        autoplay: true,
        autoplayTimeout: 4000,
        nav: false,
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      }) : '';

      //  gallery CAROUSEL HOOK
      $('#customers-gallery') ? $('#customers-gallery').owlCarousel({
        loop: false,
        rewind: true,
        center: true,
        //default settings:
        autoplay: true,
        autoplayTimeout: 3000,
        items: 3,
        nav: true,
        margin: 0,
        // autoplay: true,
        dots: false,
        smartSpeed: 450,
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 2
          },
          1170: {
            items: 2
          }
        }
      }) : "";

    });
  }

  selectlocation(value) {
    localStorage.setItem('location', value);
    this.router.navigate(['/' + value]);

  }

  getLocation() {
    this.api.fetchData('alllocation', {}, 'GET').subscribe(
      res => {
        if (res['status'] == 1) {
          this.location = res['location'];
        } else {
          this.location = [];

        }
      }
    )
  }

  getCategory() {
    this.api.fetchData('product_category', {}, 'GET').subscribe(
      res => {
        if (res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }
  sliderdata() {
    this.api.fetchData('sliderdata', {}, 'GET').subscribe(
      res => {
        if (res['status'] == 1) {
          this.sliderData = res['data']
          setTimeout(() => {
            this.ngAfterViewInit()

          }, 800);
        } else {
          this.sliderData = []
        }
      }
    )
  }
  getSeoData() {
    this.api.fetchDatalive('seodata', { page_name: 'home' }, 'GET').subscribe(
      res => {
        if (res['status'] == 1) {
          this._seoService.updateTitle(res['data'].title);
          this._seoService.updateDescription(res['data'].description)
          //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }

  // goto(cat){
  //   localStorage.setItem('pro_catagory',cat)
  //   this.router.navigate(['/selectlocation'], { queryParams: {'category': cat}});
  //   this.shared.selectedCat = cat
  // }

  getAddres() {
    return this.shared.homeAddress;
  }
  getPhone() {
    return this.shared.phone;
  }

  copyToClip(event, item) {
    event.stopPropagation();
    item.coppied = true;
    this.clipboard.copy(item.address);
    setTimeout(() => {
      item.coppied = false;

    }, 1000)
    // this.activeModal.close();
  }

  navigateRoute(url) {
    this.router.navigate(["/" + url]);
  }
}

