import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { helpForm, orderForm } from '../forms'
import { SharedService, ApiService, SeoServiceService } from '../service/index';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';

declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProductdetailComponent implements OnInit {
  noProductImage = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/placeholder.jpg';
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  tinyUrl2= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';  
  
  helpForm =helpForm;
  params;
  productdata;
  inventory;
  location_slug;
  cat_slug;
  orderForm= orderForm 
  ordersubmitted;
  submitted: boolean= false;
  selectedInventoryPro;
  location;
  currentlocation;
  loadMore
  message
  ordermessage;
  productDetailcosmos
  id;
  showDescription = false;
  qrdata 
  paclocation
  selectedImg;
  isBrowser: boolean;
  email

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    autoplay : true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    margin: 20,
    navSpeed: 400,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }
  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute,
    private api: ApiService, public shared: SharedService,
    @Inject(PLATFORM_ID) private platformId, private _seoService: SeoServiceService,
    private router: Router) {
    
      this.isBrowser = isPlatformBrowser(platformId);
      this.activatedRoute.params.subscribe((params) => {
        if(params.type == '' ) {
          let url = this.router.url.split("/");
          this.router.navigate(['/'+url[1]+'/'+ url[2]]);
        }
        this.qrdata = window.location.href;
        this.inventory = undefined;
        this.id = undefined;
        this.productdata = undefined;
        this.ordermessage = undefined;
        this.productDetailcosmos = undefined;
        this.message = undefined;
        this.params = params.type
        let data = this.router.url.split("/");
        localStorage.setItem('location',data[1]);
        localStorage.setItem('pro_catagory',data[2]);
        this.location_slug = data[1];
        this.cat_slug = data[2];
        this.ordersubmitted = false;
        this.submitted = false;
        this.currentlocation = undefined;
        this.loadMore = false;
        this.paclocation;
        this.helpForm = this.fb.group({
          name: ['', Validators.required],
          email: ['',  [Validators.required, Validators.email]],
          phone:['', [ Validators.required, 
            Validators.pattern("^[0-9]*$"),Validators.minLength(10),Validators.maxLength(13)]],
          city:['', Validators.required],
          state: ['', Validators.required],
          street:['', Validators.required],
          product:['', Validators.required],
          area: ['', Validators.required],
          message: ['', Validators.required],
        
        });
     
        this.orderForm = this.fb.group({
          fname: ['', Validators.required],
          lname: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          location: ['', Validators.required],
          company_name:[''],
          country:['', Validators.required],
          phone:['',[ Validators.required, 
            Validators.pattern("^[0-9]*$"),Validators.minLength(10),Validators.maxLength(13)]],
          state: ['', Validators.required],
          city:['', Validators.required],
          zip: ['',[ Validators.required, 
            Validators.pattern("^[0-9]*$"),Validators.minLength(5),Validators.maxLength(5)]],
          occupation: ['', Validators.required],
          address:['', Validators.required],
          message:[''],
        });
        this.shared.selectedlocation = localStorage.getItem('location') ? localStorage.getItem('location') : null;
        this.orderForm.patchValue({
          country: 'USA',
          location: this.location_slug
        })  
        this.getLocation();
        this.getLocationPac();
        this.getCategory();
        this.showDescription = false;
          this.productList();
          // this.getAllFilter();   
      });
      this.activatedRoute
      .queryParams
      .subscribe(params => {
        this.productDetailcosmos = undefined
        // Defaults to 0 if no query param provided.
        // if(this.cat_slug == 'quartz') {
        //   this.id = params['id'] ;
        //   this.showDescription = false;
        //   this.getproductDetailCosmos( this.id)
        //   }

      });
    
    }

  ngOnInit(): void {
 
  }

  ngAfterViewInit(){
    $(document).ready(function() {
      var sync1 = $(".slider");
      var sync2 = $(".navigation-thumbs");
      
      var thumbnailItemClass = '.owl-item';
      
      var slides = sync1.owlCarousel({
        video:true,
        startPosition: 12,
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        autoplayTimeout:6000,
        autoplayHoverPause:false,
        nav: true,
        dots: false
      }).on('changed.owl.carousel', syncPosition);
      
      function syncPosition(el) {
        var $owl_slider = $(this).data('owl.carousel');
        var loop = $owl_slider.options.loop;
      
        if(loop){
          var count = el.item.count-1;
          var current = Math.round(el.item.index - (el.item.count/2) - .5);
          if(current < 0) {
              current = count;
          }
          if(current > count) {
              current = 0;
          }
        }else{
           current = el.item.index;
        }
      
        var owl_thumbnail = sync2.data('owl.carousel');
        if(owl_thumbnail && owl_thumbnail.options){

          var itemClass = "." + owl_thumbnail?.options.itemClass;
          var thumbnailCurrentItem = sync2
          .find(itemClass)
          .removeClass("synced")
          .eq(current);
        
          thumbnailCurrentItem.addClass('synced');
        
          if (!thumbnailCurrentItem.hasClass('active')) {
            var duration = 300;
            sync2.trigger('to.owl.carousel',[current, duration, true]);
          }   
        }
        }
      
      
      // var thumbs = sync2.owlCarousel({
      //   startPosition: 12,
      //   items:1,
      //   loop:false,
      //   margin:10,
      //   autoplay:false,
      //   nav: false,
      //   dots: false,
      //    responsive: {
      //     0: {
      //       items: 4,
      //       margin:10,
      //     },
      //     700: {
      //       items: 3,
      //       margin:40,
      //     },
      //     1000: {
      //       items: 'auto',
      //     }
      //   },
  
      //   onInitialized: function (e) {
      //     var thumbnailCurrentItem =  $(e.target).find(thumbnailItemClass).eq(this._current);
      //     thumbnailCurrentItem.addClass('synced');
      //   },
      // })
      // .on('click', thumbnailItemClass, function(e) {
      //     e.preventDefault();
      //     var duration = 300;
      //     var itemIndex =  $(e.target).parents(thumbnailItemClass).index();
      //     sync1.trigger('to.owl.carousel',[itemIndex, duration, true]);
      // }).on("changed.owl.carousel", function (el) {
      //   var number = el.item.index;
      //   var $owl_slider = sync1.data('owl.carousel');
      //   $owl_slider.to(number, 100, true);
      // });
  
      // $('.owl-carousel2').owlCarousel({
      //   loop: (this.inventory && this.inventory.length > 3) ?true: false ,
      //   // loop: true ,
      //   margin: 40,
      //   //default settings:
      //   autoplay: true,
      //   nav: true,
      //   autoplayTimeout: 3500,
      //   autoplayHoverPause: false,
      //   responsiveClass: true,
      //   // items: 1,
      //   responsive: {
      //     0: {
      //       items: 1,
      //       nav: true
      //     },
      //     600: {
      //       items: 2,
      //       nav: true
      //     },
      //     1000: {
      //       items: 3,
      //       nav: true
      //     }
      //   }
      // });
  
  
      $('.filters ul li').click(function(){
        $('.filters ul li').removeClass('active');
        $(this).addClass('active');
        
        var data = $(this).attr('data-filter');
        $grid.isotope({
          filter: data
        })
      });
      
      var $grid = $(".grid").isotope({
        itemSelector: ".all",
        percentPosition: true,
        masonry: {
          columnWidth: ".all"
        }
      });
  
    })
 
    

$(document).ready(function(){

  $(".filter-button").click(function(){
     $(this).addClass('active');
      var value = $(this).attr('data-filter');
      
      if(value == "all")
      {
          //$('.filter').removeClass('hidden');
          $('.filter').show('1000');
         
      }
      else
      {
//            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
//            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
          $(".filter").not('.'+value).hide('3000');
          $('.filter').filter('.'+value).show('3000');
      
          
      }
  });
  
  $('.filter-button').removeClass('active');

  


  $(".filter-button2").click(function(){
     $(this).addClass('active');
      var value = $(this).attr('data-filter');
      
      if(value == "all")
      {
          //$('.filter').removeClass('hidden');
          $('.filter2').show('1000');
         
      }
      else
      {
//            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
//            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
          $(".filter2").not('.'+value).hide('3000');
          $('.filter2').filter('.'+value).show('3000');
      
          
      }
  });
  
  $('.filter-button2').removeClass('active');


});
this.callAllslider()
}
  callAllslider(){
 
  }

  get f() { return this.orderForm.controls; }
  get g() { return this.helpForm.controls; }


  onsubmit(d) {
    this.submitted = true;
    this.loadMore = true;
     // stop here if form is invalid
     if (this.helpForm.invalid) {
      this.loadMore = false;
      return;
     }
    this.message = undefined
    
    let formData: FormData = new FormData(); 
    formData.append('location',  this.currentlocation.city_name ); 
    formData.append('name', d.name); 
    formData.append('email', d.email); 
    formData.append('phone', d.phone); 
    formData.append('street', d.street); 
    formData.append('state', d.state); 
    formData.append('city', d.city); 
    formData.append('product', d.product); 
    formData.append('occupation', d.area); 
    formData.append('message', d.message); 

  this.api.fetchData('getintouch',formData, 'POST' ).subscribe(res=> {
    if(res.status == 1) {
      this.helpForm.reset();
      this.submitted = false;
      this.loadMore = false;
     this.message = res.msg
     setTimeout(()=>{
      this.message = '';
     }, 3000)
    } else {
      this.message = res.msg
      this.submitted = false;
      this.loadMore = false;

    }
  })
  }

  productList() {
    this.loadMore = true
    this.api.fetchData('productdetail',{'location' :this.location_slug, 'category_name':this.cat_slug,'productSlug': this.params  },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.productdata = res;
          setTimeout(()=> {
            this.ngAfterViewInit()
          },1500)
          this.getInventory(this.productdata.productDetails.ProductName);
          this.helpForm.patchValue({
            product: this.productdata.productDetails.ProductName
          })  
          this._seoService.updateTitle(this.productdata.productDetails.seo_title);
          this._seoService.updateDescription(this.productdata.productDetails.seo_description)
          this.loadMore = false;

        }else {
          this.router.navigate(['**']);
          this.productdata  =[];
          this.loadMore = false;

        }
      }
    )
  }

  getInventory(name) {

    this.api.fetchData('getInventry',{'location' :this.location_slug, 'product_name':name  },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.inventory = res
          // this.inventory.products.push(this.inventory.products[0])
          // this.inventory.products.push(this.inventory.products[0])
          setTimeout(()=> {
            this.ngAfterViewInit()
          },2000)
        }else {
          this.inventory  =[];
          this.ngAfterViewInit()

        }
      }
    )
  }
//   getAllFilter(){
//     this.api.fetchData('allfilters',{'city_slug': this.location_slug},'GET').subscribe(
//       res => {
//         if(res['status'] == 1) {
//           // this.shared.categoryList = res['filters']['category']
//         }else {
          
//           this.shared.categoryList =[];
//         }
//   })
// }
  

  onOrder(d){

    this.ordersubmitted = true;
    this.loadMore = true;
    // stop here if form is invalid
    if (this.orderForm.invalid) {
      this.loadMore = false;
     return;
    }
   this.ordermessage = undefined
   let locationdata =this.location.filter(f => f.city_name == d.location);
   let location =  locationdata[0].city_slug;
   console.log(location);
   console.log(  this.paclocation[location],this.paclocation[location],d.location)
   let formData: FormData = new FormData(); 
   if(this.cat_slug == 'quartz') {
    formData.append('product_name', this.productDetailcosmos.product.ProductName ); 
   } else {
     formData.append('product_name', this.productdata.productDetails.ProductName ); 
   }
   formData.append('location',  d.location ); 
   formData.append('lname',  d.lname ); 
   formData.append('fname',  d.fname ); 
   formData.append('email',  d.email ); 
   formData.append('phone',  d.phone ); 
   formData.append('company',  d.company_name ); 
   formData.append('country',  d.country ); 
   formData.append('state',  d.state ); 
   formData.append('city',  d.city ); 
   formData.append('zip',  d.zip ); 
   formData.append('occupation',  d.occupation ); 
   formData.append('address',  d.address ); 
   formData.append('message',  d.message ); 
   formData.append('poc_name', this.paclocation[location].name); 
   formData.append('poc_phone',this.paclocation[location].phone); 
   formData.append('poc_email', this.paclocation[location].email); 
   formData.append('poc_address', this.paclocation[location].address); 
   formData.append('poc_city_slug', this.paclocation[location].city_slug); 
   formData.append('poc_city_name', this.paclocation[location].city_name); 

  

 this.api.fetchData('order-sample',formData, 'POST' ).subscribe(res=> {
   if(res.status == 1) {
     this.orderForm.reset();
     this.ordersubmitted = false;
     this.loadMore = false;
    // this.ordermessage = res.msg;
    document.getElementById('orderForm-close').click();
    setTimeout(()=> {
      document.getElementById('order-pop').click();
    },500)
   } else {
     this.ordermessage = res.msg
     this.ordersubmitted = false;
     this.loadMore = false;
   }
 })
  }
  
  resetForm(){
    this.orderForm.reset();
    this.ordersubmitted = false;
    this.ordermessage = undefined;
    this.orderForm.patchValue({
      country: 'USA',
      location: this.location_slug
    }) 
  }

  onclickinventory(item, i) {
    document.getElementById('inv-image-pop').click();
    this.selectedInventoryPro = item;
    this.selectedInventoryPro['index'] = i;
    this.selectedInventoryPro['length'] = this.inventory['products'].length;
    if( (i) == 0) {
      this.selectedInventoryPro['pos'] = 'first'
    }
    if(( i+1) == this.inventory['products'].length) {
      this.selectedInventoryPro['pos'] = 'last'
    }
    
  }
 
  
  onclickArraow(item, key) {
    if(item['index'] != undefined )  {
      let i  = parseInt(item['index']);
      if( key == 'pre') {
        this.selectedInventoryPro = this.inventory['products'][i-1];
        this.selectedInventoryPro['length'] = this.inventory['products'].length;

        this.selectedInventoryPro['index']  = (i -1) ? i-1 :0
        if( i == 0) {
          this.selectedInventoryPro['pos'] = 'first'
          // this.selectedInventoryPro['index'] = i ;
        }
        if(( i+1) ==this.inventory['products'].length) {
          // this.selectedInventoryPro['index'] = i -1;
          this.selectedInventoryPro['pos'] = 'last'
        }
  
      }
      if( key == 'next') {
        this.selectedInventoryPro = this.inventory['products'][i+1];
        this.selectedInventoryPro['length'] = this.inventory['products'].length;

        this.selectedInventoryPro['index']  = (i +1)
        // this.selectedInventoryPro['index'] =  i+1;
        if( i == 0) {
          // this.selectedInventoryPro['index'] =  i+1;
          this.selectedInventoryPro['pos'] = 'first'
        }
        if((i+1) == this.inventory['products'].length) {
          // this.selectedInventoryPro['index'] =  i+1;
          this.selectedInventoryPro['pos'] = 'last'
        }
  
      }
    }
    
    // document.getElementById('inv-image-pop').click();

  }

  getLocation() {
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
          this.location.map(f => {
            if(f.city_slug == this.location_slug) {
              this.currentlocation = f;
              this.orderForm.patchValue({
                country: 'USA',
                location: f.city_name
              })  
              this.helpForm.patchValue({
                city:  f.city_name
              })  
            }
            })
        } else {
          this.location =  [];

        }
      }
    )
  }

  openForm() {
    this.ordermessage = ''
    this.orderForm.reset();
    this.orderForm.patchValue({
      country: 'USA',
      location: this.currentlocation.city_name
    })
  }
  
  getproductDetailCosmos(id){
    this.loadMore = true;
    this.api.fetchDataCosmos('specialproductdetailquartz',{id,website:'igm'},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.loadMore = false;
          this.productDetailcosmos = res
          this.helpForm.patchValue({
            product: this.productDetailcosmos.product.ProductName
          })  
          setTimeout(()=> {
            this.ngAfterViewInit()

          },2000)
        } else {
          this.loadMore = false;
          this.productDetailcosmos = [];
        }
      }
    )
  }

  getCategory() {
    this.api.fetchData('product_category',{location:this.location_slug},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }

  changeImage() {
    const options = {
      init(img) {
        img.crossOrigin = 'anonymous'
      }
    };
    // watermark(['http://host.com/photo.jpg', 'http://host.com/logo.png'], options)
    //   .image(watermark.image.lowerRight(0.5))
    //   .then(img => document.getElementById('container').appendChild(img));
  }

  addSpace(text) {
    return text ? text.replace(/,/g, ", ") :''
  }

  downloadQr() {
    var canvas =  document.getElementsByTagName("canvas");
    if(canvas) {
      let image = canvas[0].toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream");
      var link = document.createElement('a');
      link.download = this.productdata.productDetails.ProductName + '.png';
      link.href = image;
      link.click();

    }
  

  }

  
  getLocationPac() {
    this.api.fetchDataCosmos('allLocationPoc',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.paclocation =   res['locations'];
        } else {
          this.paclocation = undefined;

        }
      }
    )
  }

  onclickImg(item) {
    document.getElementById('m-image-pop').click();
    this.selectedImg = item;
   
    
  }
  gotoliveInvetory(id){
    document.getElementById(id)?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: 'smooth'

    });
  }

}
