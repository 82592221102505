import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { helpForm, orderForm , uoloadImage} from '../forms'
import { SharedService, ApiService, SeoServiceService } from '../service/index';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment.prod';
import { filter, map, mergeMap } from 'rxjs/operators';
// import { ViewChild } from '@angular/core';


// import * as AWS from 'aws-sdk';


// const spacesEndpoint = new AWS.Endpoint('https://cosmosgranite.nyc3.digitaloceanspaces.com');
// const s3 = new AWS.S3({
//   endpoint: spacesEndpoint,
//   accessKeyId: 'JW55N4YGZMOGLMEMBNSO',
//   secretAccessKey: 'SvM01S7YKDE+PJ4BkNg13CjXZ5od2PsxlL5CFDtJRfI'
// });
// const bucket  = 'cosmosgranite';
// const folder  = 'rawimages';
// const thubfolder  = 'rawimages/thumb';



//      const str = "user_id="+id+"&email="+email+"&role="+reqdata.user_type;
// let bs64 = Buffer.from(str).toString('base64')
// 
//     let decrypte_data = Buffer.from(reqdata.key, 'base64').toString()
// const urlParams = new URLSearchParams(decrypte_data);

//  IGMsurface-6LfBH_0ZAAAAAO@knQytXdQohMxhHF*yPFZU2tJzbQm
//  9c4204b11ba43719fa271f4c26ddbd2d

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.css']
})
export class FileuploadComponent implements OnInit {
  tinyUrl2= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';  
  
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('myInput')
  myInputVariable: ElementRef;
   
  uoloadImageForm= uoloadImage;
  submitted = false;
  message = '';
  loadMore = false;
  cosmosFilter = [];
  category = [];
  productdata = [];
  selectedFile = '';
  location = [];
  quartsData = [];
  imagerror = false;
  imageuploader = false
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  isBrowser: boolean;
  siteKey = environment.siteKey
  errroMessage = '';
  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute,
    private api: ApiService, public shared: SharedService, @Inject(PLATFORM_ID) private platformId,
    private router: Router, private _seoService: SeoServiceService)  {
      this.isBrowser = isPlatformBrowser(platformId) ?isPlatformBrowser(platformId): true;

      this.activatedRoute.params.subscribe((params) => {
     
        if ( params.type == '9c4204b11ba43719fa271f4c26ddbd2d') { // logged in so return true
          
        } else {
          this.router.navigate(['/'], {
            // queryParams: {
            //   ver: Math.random().toString(36).substr(2, 5),
            //   nocache: 1
            // }
          });
          localStorage.clear();
          return false;
        }
        this.shared.cleardata();

        this.uoloadImageForm = this.fb.group({
          image_option: ['', Validators.required],
          image_title: ['',  [Validators.required]],
          product_id:['' ],
          website_name:[''],
          cat_id: ['', Validators.required],
          cat_name:[''],
          job_name:[''],
          customer_name: [''],
          product_name: ['', Validators.required],
          image: [''],
          image_thumb: ['' ],
          captcha: ['', Validators.required ],
          imagedata: ['', [Validators.required,
            RxwebValidators.extension({extensions:["jpeg","png","jpg"]})] ],
          location: ['', Validators.required ],
          terms: ['',Validators.required ]

        });
        this.getCategory();
        this.getAllQuartzFilter();
        this.getLocation();
        this.uoloadImageForm.patchValue({
          location: 'atlanta'
        })
        
      })
     
     }

  ngOnInit(): void {
    // this.router.events.pipe(
    //   filter((event) => event instanceof NavigationEnd),
    //   map(() => this.activatedRoute),
    //   map((route) => {
    //     while (route.firstChild) route = route.firstChild;
    //     return route;
    //   }),
    //   filter((route) => route.outlet === 'primary'),
    //   mergeMap((route) => route.data)
    //  )
    //  .subscribe((event) => {
    //    this._seoService.updateTitle(event['title']);
    //   //  this._seoService.updateOgUrl(event['ogUrl']);
    //    //Updating Description tag dynamically with title
    //   //  this._seoService.updatenoIndex('noindex');
    //   //  console.log('noindexWork')
    //  }); 
  }
  get f() { return this.uoloadImageForm.controls; }

  onsubmit(data) {
    let d = JSON.parse(JSON.stringify(data))
    this.submitted = true;
    this.loadMore = true;
     // stop here if form is invalid
     if (this.uoloadImageForm.invalid || this.errroMessage != '') {
      this.loadMore = false;
      return;
     }
    this.message = undefined
    d['website_name'] = 'igm'
    d['image_option'] = d['image_option'].replace(' ','_').toLowerCase()
 
  this.api.fileUpload('upload-image',d, 'POST' ).subscribe(res=> {
    if(res.status == 1) {
      this.uoloadImageForm.reset();
      this.submitted = false;
      this.loadMore = false;
     this.message = res.msg
     this.imagerror = false;
     this.uoloadImageForm.patchValue({
       imagedata: '',
       captcha:'',
       location: 'atlanta'

     })
     this.captchaElem.resetCaptcha();
     this.myInputVariable.nativeElement.value = "";
     setTimeout(()=>{
      this.message = '';
     }, 10000)
    } else {
      this.message = res.msg
      this.submitted = false;
      this.loadMore = false;
      this.imagerror = false;

    }
  })
  }

  getCategory() {
    this.api.fetchData('product_category',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
          this.category = res['category']
        }
      }
    )
  }

  getAllQuartzFilter(){
    // https://staging.cosmosgranite.com/api/getAllFilter
    this.api.fetchDataCosmos('getAllFilter',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
       this.cosmosFilter = res['filter']['filter_by_images']
        } else {
       
        }
      }
    )
  }


  productList(cat) {
    this.api.fetchData('productlist',{'location':this.uoloadImageForm.value.location,'category_name':cat , offset:0, limit: 5000 },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.productdata = res['product'];
        }else {
          this.productdata  =[];

        }
      }
    )
  }

  onSelectCat(e){
    let value = e.target.value;
    let x = this.category.findIndex(f => f.ID == value)
    if(x != -1) {
      let item  = this.category[x];
      this.uoloadImageForm.patchValue({
        cat_name:item.category_name
      })
    if(item.category_slug != 'quartz' ) {
      this.quartsData = [];
      this.productList(item.category_slug);
    } else  {
      this.productdata = [];
      this.getQuartsProduct();
    }
    }



  }

  getQuartsProduct() {
    this.api.fetchDataCosmos('specialproductlistquartz', {page:0,limit:100,website:'igm' },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.quartsData = res['product']
        } else {
          this.quartsData = [];
        }
      }
    )
  }

  onSelectPro(e){
    let value = e.target.value;
    if(this.uoloadImageForm.value.cat_name != 'Coalesce Quartz') {
      let x = this.productdata.findIndex(f => f.ID == value)
      if(x != -1) {
        let item  = this.productdata[x];
        this.uoloadImageForm.patchValue({
          product_name:item.ProductName
        })
      }

    } else {
      let x = this.quartsData.findIndex(f => f.ID == value)
      if(x != -1) {
        let item  = this.quartsData[x];
        this.uoloadImageForm.patchValue({
          product_name:item.ProductName
        })
      }
    }
  }

  onFileChanged(event) {
    let regex  =new RegExp('^.*\.(jpg|JPG|jpeg|JPEG|png|PNG)$')
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile['type']);
    this.selectedFile['type']
    console.log(this.selectedFile['type'].match(regex))
    if (!this.selectedFile['type'].match(regex)) {

      console.log(this.selectedFile )
        this.imagerror = true;
        // this.uoloadImageForm.patchValue({
        //   imagedata: ''
        // })
       // this.myInputVariable.nativeElement.value = "";

        return false
      }
      // if (event.target.files[0].size > 20909000) {
      if (event.target.files[0].size > 50001200) {
        this.errroMessage = 'Maximum size allowed is 50 Mb';
        this.imagerror = true;
        return false;
      }
      if (event.target.files[0].size < 20000) {
        console.log('test')
        this.errroMessage = 'Minimum image size allowed is 20 Kb';
        this.imagerror = true;
        return false;
      }
      this.errroMessage = '';
    const reader = new FileReader();
    this.imageuploader = true;
    this.uoloadImageForm.patchValue({
      image: '',
      image_thumb: '',
    })
				reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event) => {
        let data =  event.target['result'];
          let obj ={
            image: data,
            type: this.selectedFile['type']
          }
          this.api.fileUpload('image-upload',obj,'POST').subscribe(
            res => {
              console.log(res);
              if(res['status'] == 1) {
              this.uoloadImageForm.patchValue({
                image: res['imageURL'],
                image_thumb: res['imageURLThumb'],
              })
              this.imageuploader = false;

              } else {
                this.uoloadImageForm.patchValue({
                  imagedata: ''
                })
                this.imageuploader = false;

              }
            }
          )
        }
  }

  handleSuccess(e) {
  }

  getLocation() {
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
        } else {
          this.location =  [];

        }
      }
    )
  }

  onClickcheckBox(event){
    if(event.target.checked == false) {
      this.uoloadImageForm.patchValue({
        terms: '',
       
      })
    }
  }

}
