<main>
   <section class="impress_area" style="background-image: url('{{tinyUrl}}/bg-location.jpg')">
      <div class="container py-3">
         <div class="row justify-content-between align-items-center">
            <div class="mx-auto mx-lg-0 mb-3 mt-lg-0">
               <button class="btn  mx-1" (click)="selectedLink = 'igm';clearData(); getInventoryDetails()" [ngClass]="{ 'btn-search-btn':selectedLink != 'igm',
               'btn-primary':selectedLink == 'igm' }">IGM</button>
               <button class="btn mx-1" (click)="selectedLink = 'Cosmos'; clearData(); onClickCosmosTab()" [ngClass]="{ 'btn-search-btn':selectedLink != 'Cosmos',
               'btn-primary':selectedLink == 'Cosmos' }">Cosmos</button>
            </div>
            <div class="col-lg">
               <div class="search-Row">
                  <div class="row justify-content-center align-items-center flex-nowrap">
                     <div class="col-md-12">
                        <div class="w-100 search_container">
                           <form [formGroup]="searchForm" (ngSubmit)="onSearch(searchForm.value)" ngNativeValidate>
                              <div class="finder">
                                 <div class="finder__outer">
                                    <div class="finder__inner">
                                       <input class="finder__input" formControlName="search" type="text" name="q"
                                          placeholder="Search and Enter">
                                       <div *ngIf="!searchedPro || !flag " (click)="onSearch(searchForm.value)"
                                          class="finder__icon" ref="icon"></div>
                                       <div *ngIf="searchedPro  && flag == 'search'" (click)="clearSearch()">
                                          <i class="fa fa-times fa-2x"></i>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
            <div class="mx-auto mx-lg-0 mt-3 mt-lg-0">
               <div class="form-check float-right">
                  <input type="checkbox" class="mt-2 form-check-input" [(ngModel)]="showImages"
                     [checked]="showImages == true" id="exampleCheck1">
                  <label class="form-check-label h6" for="exampleCheck1">Show All Products images</label>
               </div>
            </div>
         </div>

         <div class="row mt-3">
            <div class="col-lg-12  d-flex align-items-center justify-content-center" *ngIf="selectedLink == 'igm'">
               <ng-container *ngIf="filtersdata">

                  <div class="button-group">
                     <button type="button" class="mx-1 btn btn-outline-dark btn-sm dropdown-toggle"
                        data-toggle="dropdown">Location</button>
                     <ul class="dropdown-menu">
                        <li class="list-group-item px-0 border-0 position-relative py-1"
                           *ngFor="let item of filtersdata.alllocations; let i = index">
                           <label class="px-3" for="UdefaultrCheck1234_{{i}}">
                              <input class="mr-2" type="checkbox" value="" id="UdefaultCheck123f4_{{i}}"
                                 (click)="checkFilter($event, item.city_name,'location')"
                                 [checked]="getCheckedValue(item.city_name)">{{item.city_name}}
                           </label>
                        </li>
                     </ul>
                  </div>
                  <div class="button-group">
                     <button type="button" class="mx-1 btn btn-outline-dark btn-sm dropdown-toggle"
                        data-toggle="dropdown">Category</button>
                     <ul class="dropdown-menu">
                        <li class="list-group-item px-0 border-0 position-relative py-1"
                           *ngFor="let item of filtersdata.category; let i = index">
                           <label class="px-3" for="UdefaultrCheck2134_{{i}}">
                              <input class="mr-2" type="checkbox" value="" id="UdefaultrCheck2134_{{i}}"
                                 (click)="checkFilter($event, item.category_name,'category')"
                                 [checked]="getCheckedValue(item.category_name)">{{item.category_name}}
                           </label>
                        </li>
                     </ul>
                  </div>
               </ng-container>
            </div>
            <!-- <div class="col-lg-12  d-flex align-items-center justify-content-center" *ngIf="selectedLink == 'Cosmos'">
            <ng-container *ngIf="cosmosFilter"> 
               <div class="button-group">
                  <button type="button" class="mx-1 btn btn-outline-dark btn-sm dropdown-toggle" data-toggle="dropdown">Location</button>
                  <ul class="dropdown-menu">
                     <li class="list-group-item px-0 border-0 position-relative py-1" *ngFor="let item of getKeys(cosmosFilter.alllocations); let i = index">
                        <label class="px-3" for="UdefaultrCheck1234_{{i}}">
                        <input class="mr-2" type="checkbox" value="" id="UdefaultCheck123f4_{{i}}"
                        (click)="checkFilter($event, item,'location')"
                        [checked]="getCheckedValue(item)"
                        >{{item}}
                        </label>
                     </li>
                  </ul>
               </div>
               <div class="button-group">
                  <button type="button" class="mx-1 btn btn-outline-dark btn-sm dropdown-toggle" data-toggle="dropdown">Category</button>
                  <ul class="dropdown-menu">
                     <li class="list-group-item px-0 border-0 position-relative py-1" *ngFor="let item of cosmosFilter.category; let i = index">
                        <label class="px-3" for="UdefaultrCheck2134_{{i}}">
                        <input class="mr-2" type="checkbox" value="" id="UdefaultrCheck2134_{{i}}"
                        (click)="checkFilter($event, item.category_name,'category')"
                        [checked]="getCheckedValue(item.category_name)"
                        >{{item.category_name}}
                        </label>
                     </li>
                  </ul>
               </div>
            </ng-container>

         </div> -->
         </div>

         <div class="row chips-row">
            <div class="col-lg-12 d-flex flex-wrap justify-content-center">
               <ng-container *ngFor="let item of filters; let i = index">
                  <div class="chip ml-1 mt-3">
                     {{item.name}} <span *ngIf="item.type == 'thickness'">cm </span>
                     <i class="close fa fa-times" (click)="removeFilter(i)"></i>
                  </div>
               </ng-container>
            </div>
         </div>
      </div>
      <div class="row message-box mt-1 text-center"
         *ngIf="selectedLink == 'igm'  || (selectedLink != 'igm' && searchedPro)">
         <div class="col-md-12 text-center">
            <p class="mb-0">To view our inventory of specific location, please click on location name.
               Or, to view all location's inventory please click on Product Name </p>
         </div>
      </div>
   </section>
   <!-- <section class="impress_area"> -->


   <!-- </section> -->

   <section class="pt-3 pt-sm-5 pb-5" *ngIf="selectedLink == 'igm'">
      <div class="container-fluid">
         <div class="row">
            <div class="prodiuct-list" [ngClass]="{'col-md-12 col-lg-12 order-md-2 col-xl-12':!showInvetory,
               'col-md-6 col-lg-8  order-md-1 col-xl-9':showInvetory != false }">
               <div class="grid-repeat">
                  <ng-container>
                     <div *ngFor="let data of productdata; let  i= index">
                        <div class="row mx-0">
                           <div class="col-md-12 order-md-2 col-lg-12 col-sm-12">
                              <div class="mx-0 row mb-3 align-items-center justify-content-center">
                                 <div class="col-xl-12 col-md-12 px-sm-0 text-center">
                                    <div class="large-title">
                                       <h2 class="mb-3">{{data.category_name | uppercase}}</h2>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row mx-0">
                           <ng-container *ngIf="showImages">
                              <div class="img-shine  col-md-6 overflow-hd px-1 mb-sm-2 mb-0 flex-grow-1"
                                 [ngClass]="{'col-md-12 col-lg-4 col-xl-3':!showInvetory, 'col-md-12 col-lg-6 col-xl-4':showInvetory != false }"
                                 *ngFor="let item of data['productData']; let  i= index">
                                 <div class="imgFlatShow" id="{{item.ProductName}}">
                                    <img *ngIf="item.ProductPicture" src="{{item.ProductPicture}}"
                                       onerror="this.onerror=null;this.src='https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/placeholder.jpg';" />
                                    <img *ngIf="!item.ProductPicture" src="{{tinyUrl}}/placeholder.jpg" />

                                 </div>
                                 <div class="productdeatils">
                                    <span class="cursor-pointer " (click)="openInventory(item, 'all')"
                                       [ngClass]="{'active-product text-white px-1': selectedProduct == item.ProductName}">
                                       {{item.ProductName}}
                                    </span>
                                    <ng-container *ngIf="item.inventory_data">
                                       <small>
                                          <div class="row mxw-290">
                                             <div class="col px-0">
                                                <div class="row mb-0 pb-0 mx-0 border">
                                                   <div class="col"> &nbsp;</div>
                                                </div>
                                                <div class="row mb-0 pb-0 mx-0 border">
                                                   <small class="text-center d-block w-100">
                                                      Slab
                                                   </small>
                                                </div>
                                                <div class="row mb-0 pb-0 mx-0 border">
                                                   <small class="text-center d-block w-100">
                                                      SqFt
                                                   </small>
                                                </div>
                                             </div>
                                             <div class="col px-0" *ngFor="let child of item.inventory_data">
                                                <div class="row cursor-pointer mb-0 pb-0 mx-0 border"
                                                   (click)="openInventory(item,child.city_slug)"
                                                   [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.city_slug) }">
                                                   <small class="text-center d-block w-100 loc-color">
                                                      {{child.location_text}}
                                                   </small>
                                                </div>
                                                <div class="row mb-0 pb-0 mx-0 border">
                                                   <small class="text-center d-block w-100 loc-data">
                                                      {{child.slab}}
                                                   </small>
                                                </div>
                                                <div class="row mb-0 pb-0 mx-0 border">
                                                   <small class="text-center d-block w-100 loc-data">
                                                      {{child.qty}}
                                                   </small>
                                                </div>
                                             </div>
                                          </div>
                                       </small>
                                    </ng-container>
                                 </div>
                              </div>
                           </ng-container>
                           <ng-container>
                              <div class="row  oPNS justify-content-center" *ngIf="showImages != true">
                                 <div class="m-2 card shadow-sm"
                                    *ngFor="let item of data['productData']; let  i= index">
                                    <div class="card-body" id="{{item.ProductName}}">
                                       <h6 class="mb-2 text-center cursor-pointer " (click)="openInventory(item,'all')"
                                          [ngClass]="{'active-product text-white px-1': selectedProduct == item.ProductName}">
                                          {{item.ProductName}}</h6>
                                       <ng-container *ngIf="item.inventory_data">
                                          <small>
                                             <div class="row mxw-290">
                                                <div class="col px-0">
                                                   <div class="row mb-0 pb-0 mx-0  border">
                                                      <div class="col "> &nbsp;</div>
                                                   </div>
                                                   <div class="row mb-0 pb-0 mx-0  border">
                                                      <small class="text-center d-block w-100">
                                                         Slab
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 pb-0 mx-0  border">
                                                      <small class="text-center d-block w-100">
                                                         SqFt
                                                      </small>
                                                   </div>
                                                </div>
                                                <div class="col px-0" *ngFor="let child of item.inventory_data">
                                                   <div class="row cursor-pointer mb-0 pb-0 mx-0 border"
                                                      (click)="openInventory(item,child.city_slug)"
                                                      [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.city_slug) }">
                                                      <small class="text-center d-block w-100 loc-color">
                                                         {{child.location_text}}
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 pb-0 mx-0  border">
                                                      <small class="text-center d-block w-100 loc-data">
                                                         {{child.slab}}
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 pb-0 mx-0  border">
                                                      <small class="text-center d-block w-100 loc-data">
                                                         {{child.qty}}
                                                      </small>
                                                   </div>
                                                </div>
                                             </div>
                                          </small>
                                       </ng-container>
                                    </div>
                                 </div>
                              </div>
                           </ng-container>
                           <hr class="my-5">
                           <div *ngIf="productdata && productdata.length == 0"
                              class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                              <div class=" bg-light py-2 px-5 text-center">
                                 <p class="mb-0 mt-1"> No product found.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </ng-container>
                  <ng-container>
                     <div *ngFor="let data of searchedPro; let  i= index">
                        <div class="row mx-0">
                           <div class="col-md-12 order-md-2 col-lg-12 col-sm-12">
                              <div class="row mb-3 align-items-center justify-content-center">
                                 <div class="col-xl-12 col-md-12 px-sm-0 text-center">
                                    <div class="large-title">
                                       <h2 class="mb-3">{{data.category_name | uppercase}}</h2>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row mx-0">
                           <ng-container *ngIf="showImages">
                              <div class="img-shine  col-md-6 overflow-hd px-1 mb-sm-2 mb-0 flex-grow-1"
                                 [ngClass]="{'col-md-6 col-lg-4 col-xl-3':!showInvetory, 'col-md-12 col-lg-6 col-xl-6':showInvetory != false }"
                                 *ngFor="let item of data['productData']; let  i= index">
                                 <div class="imgFlatShow" id="{{item.ProductName}}">
                                    <img *ngIf="item.ProductPicture" src="{{item.ProductPicture}}"
                                       onerror="this.onerror=null;this.src='https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/placeholder.jpg';">
                                    <img *ngIf="!item.ProductPicture" class="img-placeholder"
                                       src="{{tinyUrl}}/placeholder.jpg" />

                                 </div>
                                 <div class="productdeatils">
                                    <span class="cursor-pointer " (click)="openInventory(item,'all') "
                                       [ngClass]="{'active-product text-white px-1': selectedProduct == item.ProductName}">
                                       {{item.ProductName}}
                                    </span>
                                    <ng-container *ngIf="item.inventory_data">
                                       <small>
                                          <div class="row mxw-290">
                                             <div class="col px-0">
                                                <div class="row mb-0 mx-0 border">
                                                   <div class="col"> &nbsp;</div>
                                                </div>
                                                <div class="row mb-0 mx-0 border">
                                                   <small class="text-center d-block w-100">
                                                      Slab
                                                   </small>
                                                </div>
                                                <div class="row mb-0 mx-0 border">
                                                   <small class="text-center d-block w-100">
                                                      SqFt
                                                   </small>
                                                </div>

                                             </div>
                                             <div class="col px-0" *ngFor="let child of item.inventory_data">
                                                <div class="row cursor-pointer mb-0 mx-0 border"
                                                   (click)="openInventory(item,child.city_slug)"
                                                   [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.city_slug) }">
                                                   <small class="text-center d-block w-100 loc-color">
                                                      {{child.location_text}}
                                                   </small>
                                                </div>
                                                <div class="row mb-0 mx-0 border">
                                                   <small class="text-center d-block w-100 loc-data">
                                                      {{child.slab}}
                                                   </small>
                                                </div>
                                                <div class="row mb-0 mx-0 border">
                                                   <small class="text-center d-block w-100 loc-data">
                                                      {{child.qty}}
                                                   </small>
                                                </div>
                                             </div>
                                          </div>
                                       </small>
                                    </ng-container>
                                 </div>
                              </div>
                           </ng-container>
                           <ng-container>
                              <div class="row oPNS justify-content-center" *ngIf="showImages  != true">
                                 <div class="m-2 card shadow-sm"
                                    *ngFor="let item of data['productData']; let  i= index">
                                    <div class="card-body" id="{{item.ProductName}}">
                                       <h6 class="mb-0 text-center cursor-pointer " (click)="openInventory(item,'all')"
                                          [ngClass]="{'active-product text-white px-1': selectedProduct == item.ProductName}">
                                          {{item.ProductName}}</h6>
                                       <ng-container *ngIf="item.inventory_data">
                                          <small>
                                             <div class="row">
                                                <div class="col-2 px-0">
                                                   <div class="row mb-0 mx-0 border">
                                                      <div class="col mb-0"> &nbsp;</div>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                         Slab
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                         SqFt
                                                      </small>
                                                   </div>
                                                </div>
                                                <div class="col-2 px-0" *ngFor="let child of item.inventory_data">
                                                   <div class="row cursor-pointer mb-0 mx-0 border"
                                                      (click)="openInventory(item,child.city_slug)"
                                                      [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.city_slug) }">
                                                      <small class="text-center d-block w-100 loc-color">
                                                         {{child.location_text}}
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100 loc-data">
                                                         {{child.slab}}
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100 loc-data">
                                                         {{child.qty}}
                                                      </small>
                                                   </div>
                                                </div>
                                             </div>
                                          </small>
                                       </ng-container>
                                    </div>
                                 </div>
                              </div>
                           </ng-container>
                           <hr class="my-5">
                           <div *ngIf="searchedPro && searchedPro.length == 0"
                              class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                              <div class=" bg-light py-2 px-5 text-center">
                                 <p class="mb-0 mt-1"> No product found.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </ng-container>
                  <div *ngIf="loadMore" role="status"
                     class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                     <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                        <div class="d-flex align-items-center">
                           <img src="{{tinyUrl}}/loader.svg" class="img-fluid" width="50" />
                           <p class="mb-0 mt-1 mr-3">Loading...</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="mob-card col-md-6 col-lg-4 order-md-1 col-xl-3" *ngIf="showInvetory">
               <div class="inveFix igmtab" *ngIf="inventory">
                  <div class="card">
                     <div class="card-header">
                        <h4 class="d-flex mb-0 justify-content-between">
                           INVENTORY
                           <span class="cursor-pointer"
                              (click)="showInvetory = false; selectedinvLocation = undefined; selectedProduct= undefined">
                              <i class="fa fa-times fa-1x cursor-pointer"></i>
                           </span>
                        </h4>
                        <p class="text-left mb-0">
                           {{selectedProduct}}
                        </p>
                     </div>
                     <div class="row ">
                        <div class="col-12" *ngFor="let item of inventory; let i = index">
                           <div class="card-body p-1">
                              <div class="item">
                                 <div class="iLiveBox cursor-pointer" *ngIf="showImages" (click)="openImgInv(item)">
                                    <div class="showLiveImg" *ngIf="item.LotBundlePicture"
                                       style="background-image: url('{{item.LotBundlePicture}}')"></div>
                                 </div>
                              </div>
                           </div>
                           <div class="card-footer p-0">
                              <table class="table-responsive table table-bordered table-sm table-striped mb-0">
                                 <thead class="thead-dark">
                                    <tr>
                                       <th *ngIf="item.LotNumber">Lot#</th>
                                       <th *ngIf="item.BundleNumber">Bundle#</th>
                                       <th *ngIf="item.AvgSlabLength && item.AvgSlabWidth">Size</th>
                                       <th *ngIf="item.location_name ">Location:</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td *ngIf="item.LotNumber">{{item.LotNumber}}</td>
                                       <td *ngIf="item.BundleNumber">{{item.BundleNumber}}</td>
                                       <td *ngIf="item.AvgSlabLength && item.AvgSlabWidth">
                                          {{item.AvgSlabLength}}x{{item.AvgSlabWidth}}</td>
                                       <td *ngIf="item.location_name ">{{item.location_name |uppercase}} </td>
                                    </tr>
                                 </tbody>
                              </table>

                           </div>
                        </div>
                        <table *ngIf="inventory.length == 0">
                           <tbody>
                              <tr>
                                 <td>
                                    <p class="mb-0 ml-2 pl-2"> No Inventory detail Found... </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>

   <section class="pt-3 pt-sm-5 pb-5" *ngIf="selectedLink == 'Cosmos'">
      <div class="container-fluid">
         <div class="row">
            <div class="prodiuct-list" [ngClass]="{'col-md-12 col-lg-12 order-md-2 col-xl-12':!showInvetory,
               'col-md-6 col-lg-8  order-md-1 col-xl-9':showInvetory != false }">
               <div class="grid-repeat">
                  <ng-container>
                     <div *ngFor="let data of cosmosProduct; let  i= index">
                        <div class="row mx-0">
                           <div class="col-md-12 order-md-2 col-lg-12 col-sm-12">
                              <div class="mx-0 row mb-3 align-items-center justify-content-center">
                                 <div class="col-xl-12 col-md-12 px-sm-0 text-center">
                                    <div class="large-title">
                                       <h2 class="mb-3">{{data.category_name | uppercase}}</h2>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row mx-0">
                           <ng-container *ngIf="showImages">
                              <div class="img-shine  col-md-6 overflow-hd px-1 mb-sm-2 mb-0 flex-grow-1"
                                 [ngClass]="{'col-md-12 col-lg-4 col-xl-5':!showInvetory, 'col-md-12 col-lg-6 col-xl-6':showInvetory != false }"
                                 *ngFor="let item of data['productData']; let  i= index">
                                 <ng-container *ngIf="item.inventory_data && item.inventory_data.length > 0">
                                    <div class="imgFlatShow">
                                       <img *ngIf="item.ProductPicture" src="{{item.ProductPicture}}" alt=""
                                          onerror="this.onerror=null;this.src='https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/placeholder.jpg';">
                                       <img *ngIf="!item.ProductPicture" src="{{tinyUrl}}/placeholder.jpg" alt="">
                                       <!-- <a *ngIf="item.ProductPicture"   href="javascript:void(0)" style="background-image: url('{{item.ProductPicture}}');" ></a> -->
                                       <!-- <a *ngIf="!item.ProductPicture"   class="img-placeholder"  href="javascript:void(0)" style="background-image: url({{tinyUrl}}/placeholder.jpg);">
                                    </a> -->
                                    </div>
                                    <div class="productdeatils">
                                       <span class="cursor-pointer "
                                          (click)="openInventorycosmos(item.ProductName, 'all')"
                                          [ngClass]="{'active-product text-white px-1': selectedProduct == item.ProductName}">
                                          {{item.ProductName}}
                                       </span>
                                       <ng-container *ngIf="item.inventory_data">
                                          <small>
                                             <div class="row mxw-290">
                                                <div class="col px-0">
                                                   <div class="row mb-0 pb-0 mx-0 border">
                                                      <div class="col"> &nbsp;</div>
                                                   </div>
                                                   <div class="row mb-0 pb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                         Slab
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 pb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                         SqFt
                                                      </small>
                                                   </div>
                                                </div>
                                                <div class="col px-0" *ngFor="let child of item.inventory_data">
                                                   <div class="row cursor-pointer mb-0 pb-0 mx-0 border loc-color">
                                                      <!-- <small class="text-center d-block w-100" *ngIf="child.location_text == 'east'" (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                   [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                      East
                                                   </small> -->
                                                      <small class="text-center d-block w-100 loc-data"
                                                         *ngIf="child.location_text == 'raleigh'"
                                                         [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                         RDU
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-data"
                                                         *ngIf="child.location_text == 'greensboro'"
                                                         [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                         GSO
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-data"
                                                         *ngIf="child.location_text == 'nashville'"
                                                         [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                         TN
                                                      </small>
                                                      <small class="text-center d-block w-100"
                                                         *ngIf="child.location_text == 'northeast'"
                                                         (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                         [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                         NE
                                                      </small>
                                                      <small class="text-center d-block w-100"
                                                         *ngIf="child.location_text == 'dallas'"
                                                         (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                         [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                         DFW
                                                      </small>
                                                      <small class="text-center d-block w-100"
                                                         *ngIf="child.location_text == 'charlotte'"
                                                         (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                         [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                         CLT
                                                      </small>
                                                      <small class="text-center d-block w-100"
                                                         *ngIf="child.location_text == 'charleston'"
                                                         (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                         [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                         CHS
                                                      </small>
                                                      <small class="text-center d-block w-100"
                                                         *ngIf="child.location_text == 'dc'"
                                                         (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                         [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                         DC
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 pb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                         <ng-container *ngIf="child.slab == 'NAN'">
                                                            0
                                                         </ng-container>
                                                         <ng-container *ngIf="child.slab != 'NAN'">
                                                            {{child.slab}}
                                                         </ng-container>
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 pb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                         {{child.qty}}
                                                      </small>
                                                   </div>
                                                </div>
                                             </div>
                                          </small>
                                       </ng-container>
                                    </div>

                                 </ng-container>
                              </div>
                           </ng-container>
                           <ng-container>
                              <div class="row  oPNS justify-content-center" *ngIf="showImages != true">
                                 <div class="m-2 card shadow-sm"
                                    *ngFor="let item of data['productData']; let  i= index">
                                    <div class="card-body">
                                       <ng-container *ngIf="item.inventory_data && item.inventory_data.length > 0">

                                          <h6 class="mb-2 text-center cursor-pointer "
                                             (click)="openInventorycosmos(item.ProductName, 'all')"
                                             [ngClass]="{'active-product text-white px-1': selectedProduct == item.ProductName}">
                                             {{item.ProductName}}</h6>
                                          <ng-container *ngIf="item.inventory_data">
                                             <small>
                                                <div class="row mxw-290">
                                                   <div class="col px-0">
                                                      <div class="row mb-0 pb-0 mx-0  border">
                                                         <div class="col "> &nbsp;</div>
                                                      </div>
                                                      <div class="row mb-0 pb-0 mx-0  border">
                                                         <small class="text-center d-block w-100">
                                                            Slab
                                                         </small>
                                                      </div>
                                                      <div class="row mb-0 pb-0 mx-0  border">
                                                         <small class="text-center d-block w-100">
                                                            SqFt
                                                         </small>
                                                      </div>
                                                   </div>
                                                   <div class="col px-0" *ngFor="let child of item.inventory_data">
                                                      <div class="row cursor-pointer mb-0 pb-0 mx-0 border ">
                                                         <!-- <small class="text-center d-block w-100" *ngIf="child.location_text == 'east'" (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                      [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                         East
                                                      </small> -->
                                                         <small class="text-center d-block w-100 loc-color"
                                                            *ngIf="child.location_text == 'raleigh'"
                                                            [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                            RDU
                                                         </small>
                                                         <small class="text-center d-block w-100 loc-color"
                                                            *ngIf="child.location_text == 'greensboro'"
                                                            [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                            GSO
                                                         </small>
                                                         <small class="text-center d-block w-100 loc-color"
                                                            *ngIf="child.location_text == 'nashville'"
                                                            [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                            TN
                                                         </small>
                                                         <small class="text-center d-block w-100 loc-color"
                                                            *ngIf="child.location_text == 'northeast'"
                                                            (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                            [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                            NE
                                                         </small>
                                                         <small class="text-center d-block w-100 loc-color"
                                                            *ngIf="child.location_text == 'dallas'"
                                                            (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                            [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                            DFW
                                                         </small>
                                                         <small class="text-center d-block w-100 loc-color"
                                                            *ngIf="child.location_text == 'charlotte'"
                                                            (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                            [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                            CLT
                                                         </small>
                                                         <small class="text-center d-block w-100 loc-color"
                                                            *ngIf="child.location_text == 'charleston'"
                                                            (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                            [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                            CHS
                                                         </small>
                                                         <small class="text-center d-block w-100 loc-color"
                                                            *ngIf="child.location_text == 'dc'"
                                                            (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                            [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                            DC
                                                         </small>
                                                      </div>
                                                      <div class="row mb-0 pb-0 mx-0  border">
                                                         <small class="text-center d-block w-100">
                                                            <ng-container *ngIf="child.slab == 'NAN'">
                                                               0
                                                            </ng-container>
                                                            <ng-container *ngIf="child.slab != 'NAN'">
                                                               {{child.slab}}
                                                            </ng-container>
                                                         </small>
                                                      </div>
                                                      <div class="row mb-0 pb-0 mx-0  border">
                                                         <small class="text-center d-block w-100">
                                                            {{child.qty}}
                                                         </small>
                                                      </div>
                                                   </div>
                                                </div>
                                             </small>
                                          </ng-container>
                                       </ng-container>
                                    </div>
                                 </div>
                              </div>
                           </ng-container>
                           <hr class="my-5">
                           <div *ngIf="productdata && productdata.length == 0"
                              class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                              <div class=" bg-light py-2 px-5 text-center">
                                 <p class="mb-0 mt-1"> No product found.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row" *ngIf="searchedPro && searchedPro.length ==0 ">
                        <div class="col-md-12 text-center">

                           No product found.
                        </div>
                     </div>
                  </ng-container>
                  <ng-container>
                     <div *ngFor="let data of searchedPro; let  i= index">
                        <div class="row mx-0">
                           <div class="col-md-12 order-md-2 col-lg-12 col-sm-12">
                              <div class="row mb-3 align-items-center justify-content-center">
                                 <div class="col-xl-12 col-md-12 px-sm-0 text-center">
                                    <div class="large-title">
                                       <h2 class="mb-3">{{data.category_name | uppercase}}</h2>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row mx-0">
                           <ng-container>
                              <div class="img-shine  col-md-6 overflow-hd px-1 mb-sm-2 mb-0 flex-grow-1"
                                 [ngClass]="{'col-md-6 col-lg-4 col-xl-4':!showInvetory, 'col-md-12 col-lg-6 col-xl-6':showInvetory != false }"
                                 *ngFor="let item of data['productData']; let  i= index">
                                 <ng-container *ngIf="item.inventory_data && item.inventory_data.length > 0">
                                    <div class="imgFlatShow" *ngIf="showImages" id="{{item.ProductName}}">
                                       <img *ngIf="item.ProductPicture" src="{{item.ProductPicture}}" alt=""
                                          onerror="this.onerror=null;this.src='https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/placeholder.jpg';">
                                       <img *ngIf="!item.ProductPicture" src="{{tinyUrl}}/placeholder.jpg" alt="">
                                       <!-- <a *ngIf="item.ProductPicture"   href="javascript:void(0)" style="background-image: url('{{item.ProductPicture}}');" ></a>
                                 <a *ngIf="!item.ProductPicture"   class="img-placeholder"  href="javascript:void(0)" style="background-image: url({{tinyUrl}}/placeholder.jpg);">
                                 </a> -->
                                    </div>
                                    <div class="productdeatils">
                                       <span class="cursor-pointer "
                                          (click)="openInventorycosmos(item.ProductName, 'all')"
                                          [ngClass]="{'active-product text-white px-1': selectedProduct == item.ProductName}">
                                          {{item.ProductName}}
                                       </span>
                                       <ng-container *ngIf="item.inventory_data">
                                          <small>
                                             <div class="row mxw-290">
                                                <div class="col px-0">
                                                   <div class="row mb-0 mx-0 border">
                                                      <div class="col"> &nbsp;</div>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                         Slab
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                         SqFt
                                                      </small>
                                                   </div>
                                                </div>
                                                <div class="col px-0" *ngFor="let child of item.inventory_data">
                                                   <div class="row cursor-pointer mb-0 pb-0 mx-0 border loc-color"
                                                      (click)="openInventorycosmos(item.ProductName, child.location_text)"
                                                      [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                      <!-- <small class="text-center d-block w-100 loc-data" *ngIf="child.location_text == 'east'">
                                                   East
                                                </small> -->
                                                      <small class="text-center d-block w-100 loc-color"
                                                         *ngIf="child.location_text == 'raleigh'">
                                                         RDU
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-color"
                                                         *ngIf="child.location_text == 'greensboro'">
                                                         GSO
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-color"
                                                         *ngIf="child.location_text == 'nashville'">
                                                         TN
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-color"
                                                         *ngIf="child.location_text == 'northeast'">
                                                         NE
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-color"
                                                         *ngIf="child.location_text == 'dallas'">
                                                         DFW
                                                      </small>
                                                      <small class="text-center d-block w-100  loc-color"
                                                         *ngIf="child.location_text == 'charlotte'">
                                                         CLT
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-color"
                                                         *ngIf="child.location_text == 'charleston'">
                                                         CHS
                                                      </small>
                                                      <small class="text-center d-block w-100  loc-color"
                                                         *ngIf="child.location_text == 'dc'">
                                                         DC
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100  loc-data">
                                                         <ng-container *ngIf="child.slab == 'NAN'">
                                                            0
                                                         </ng-container>
                                                         <ng-container *ngIf="child.slab != 'NAN'">
                                                            {{child.slab}}
                                                         </ng-container>
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border ">
                                                      <small class="text-center d-block w-100 loc-data">
                                                         {{child.qty}}
                                                      </small>
                                                   </div>
                                                </div>
                                             </div>
                                          </small>
                                       </ng-container>
                                    </div>

                                 </ng-container>
                              </div>
                           </ng-container>
                           <!-- code commented for duplicate and without image single product view issue -->
                           <!-- <ng-container *ngIf="false">
                        <div class="row oPNS justify-content-center" *ngIf="showImages  != true">
                           <div class="mb-3 mb-md-0 col-md-6 "  *ngFor="let item of data['productData']; let  i= index" 
                           [ngClass]="{'col-md-6 col-lg-4 col-xl-4':!showInvetory, 'col-md-12 col-lg-6 col-xl-6':showInvetory != false }">
                              <div class="m-2 card  shadow-sm "  >
                                 <div class="card-body">
                                    <ng-container *ngIf="item.inventory_data && item.inventory_data.length > 0">
                                       <h6 class="mb-0 text-center cursor-pointer "(click)="openInventorycosmos(item.ProductName, 'all')" 
                                       [ngClass]="{'active-product text-white px-1': selectedProduct == item.ProductName}"  id="{{item.ProductName}}">{{item.ProductName}}</h6>
                                       <ng-container *ngIf="item.inventory_data">
                                          <small class="sameCol">
                                             <div class="row">
                                                <div class="col px-0">
                                                   <div class="row mb-0 mx-0 border">
                                                      <div class="col mb-0"> &nbsp;</div>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                      Slab
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100">
                                                      SqFt
                                                      </small>
                                                   </div>
                                                </div>
                                                <div class="col px-0" *ngFor="let child of item.inventory_data">
                                                   <div class="row cursor-pointer mb-0 pb-0 mx-0 border loc-color" (click)="openInventorycosmos(item.ProductName, child.location_text)" 
                                                   [ngClass]="{'active-product-location text-white ': selectedProduct == item.ProductName && (selectedinvLocation == 'all' || selectedinvLocation == child.location_text) }">
                                                   <small class="text-center d-block w-100 loc-color" *ngIf="child.location_text == 'raleigh'">
                                                      RDU
                                                   </small>
                                                   <small class="text-center d-block w-100 loc-color" *ngIf="child.location_text == 'greensboro'">
                                                      GSO
                                                   </small>
                                                   <small class="text-center d-block w-100 loc-color" *ngIf="child.location_text == 'nashville'">
                                                      TN
                                                   </small>
                                                      <small class="text-center d-block w-100 loc-color" *ngIf="child.location_text == 'northeast'">
                                                         NE
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-color" *ngIf="child.location_text == 'dallas'">
                                                         DFW
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-color" *ngIf="child.location_text == 'charlotte'">
                                                         CLT
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-color" *ngIf="child.location_text == 'charleston'">
                                                         CHS
                                                      </small>
                                                      <small class="text-center d-block w-100 loc-color" *ngIf="child.location_text == 'dc'">
                                                         DC
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100 loc-data">
                                                         <ng-container *ngIf="child.slab == 'NAN'">
                                                            0
                                                           </ng-container>
                                                           <ng-container *ngIf="child.slab != 'NAN'">
                                                              {{child.slab}}
                                                           </ng-container>
                                                      </small>
                                                   </div>
                                                   <div class="row mb-0 mx-0 border">
                                                      <small class="text-center d-block w-100 loc-data">
                                                      {{child.qty}}
                                                      </small>
                                                   </div>
                                                </div>
                                             </div>
                                          </small>
                                       </ng-container>
   
                                    </ng-container>
                                 </div>
                              </div>
                             
                           </div>
                        </div>
                     </ng-container> -->

                           <!-- code commented for duplicate and without image single product view issue  end here-->

                           <hr class="my-5">
                           <div *ngIf="searchedPro && searchedPro.length == 0"
                              class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                              <div class=" bg-light py-2 px-5 text-center">
                                 <p class="mb-0 mt-1"> No product found.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </ng-container>
                  <div *ngIf="loadMore" role="status"
                     class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                     <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                        <div class="d-flex align-items-center">
                           <img src="{{tinyUrl}}/loader.svg" class="img-fluid" width="50" />
                           <p class="mb-0 mt-1 mr-3">Loading...</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="mob-card col-md-6 col-lg-4 order-md-1 col-xl-3" *ngIf="showInvetory">
               <div class="inveFix" *ngIf="cosmosInventory">
                  <div class="card">
                     <div class="card-header">
                        <h4 class="d-flex mb-0 justify-content-between">
                           INVENTORY
                           <span class="cursor-pointer" (click)="closeInventory()">
                              <i class="fa fa-times fa-1x cursor-pointer"></i>
                           </span>
                        </h4>
                        <p class="text-left mb-0">
                           {{selectedProduct}}
                        </p>
                     </div>
                     <div class="row ">
                        <div class="col-12" *ngFor="let item of cosmosInventory; let i = index">
                           <div class="card-body p-1">
                              <div class="item">
                                 <div class="iLiveBox cursor-pointer" *ngIf="showImages" (click)="openImgInv(item)">
                                    <!-- <div class="showLiveImg" *ngIf="item.LotBundlePicture" style="background-image: url('{{item.LotBundlePicture}}')"></div> -->
                                    <img class="showLiveImg" *ngIf="item.LotBundlePicture"
                                       src="{{item.LotBundlePicture}}" alt="">

                                 </div>
                              </div>
                           </div>
                           <div class="card-footer p-0">
                              <table class="table-responsive table table-bordered table-sm table-striped mb-0">
                                 <thead class="thead-dark">
                                    <tr>
                                       <th *ngIf="item.LotNumber">Lot#</th>
                                       <th *ngIf="item.BundleNumber">Bundle#</th>
                                       <th *ngIf="item.AvgSlabLength && item.AvgSlabWidth">Size</th>
                                       <th *ngIf="item.location_name ">Location:</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td *ngIf="item.LotNumber">{{item.LotNumber}}</td>
                                       <td *ngIf="item.BundleNumber">{{item.BundleNumber}}</td>
                                       <td *ngIf="item.AvgSlabLength && item.AvgSlabWidth">
                                          {{item.AvgSlabLength}}x{{item.AvgSlabWidth}}</td>
                                       <td *ngIf="item.location_name ">{{item.location_name |uppercase}} </td>
                                    </tr>
                                 </tbody>
                              </table>

                           </div>
                        </div>
                        <table *ngIf="cosmosInventory.length == 0">
                           <tbody>
                              <tr>
                                 <td>
                                    <p class="mb-0  ml-2 pl-2"> No Inventory detail Found... </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>

   <button type="button " class="btn btn-primary d-none" id="open-inv-img" data-toggle="modal"
      data-target="#exampleModalCenter-1">
      Launch demo modal
   </button>

   <!-- Modal -->
   <div class="modal fade" id="exampleModalCenter-1" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
         <div class="modal-content">
            <div class="modal-header">
               <h5 class="modal-title" *ngIf="selectedProduct" id="exampleModalLongTitle">{{selectedProduct}}</h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body" *ngIf="selectedImg">
               <lib-ngx-image-zoom [thumbImage]=selectedImg [fullImage]=selectedImg [magnification]="1"
                  [enableScrollZoom]="true" [enableLens]="true" [lensWidth]="200"
                  [magnification]="2"></lib-ngx-image-zoom>
               <!-- <div class="owl-nav d-flex align-items-center justify-content-between"  *ngIf="selectedInventoryPro['length']  != 1">
                     <button type="button" role="presentation" class="btn btn-light owl-prev"
                     [disabled]=" selectedInventoryPro['index'] == 0" (click)="onclickArraow(selectedInventoryPro, 'pre')">
                     <span aria-label="Previous"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                     </button>
                     <button type="button" role="presentation" class="btn btn-light owl-next"
                     [disabled]="selectedInventoryPro['index']+1 == selectedInventoryPro['length']" (click)="onclickArraow(selectedInventoryPro, 'next')">
                     <span aria-label="Next"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                     </button>
                  </div> -->
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
               <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
         </div>
      </div>
   </div>
</main>