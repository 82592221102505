import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService, ApiService, SeoServiceService } from '../service/index';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent implements OnInit {
  timer = 5
  constructor(private router: Router) { 
    // this._seoService.updateTitle('404 Page Not Found.');
    this.redirect()
  }

  ngOnInit(): void {
  }
  redirect() {
    // if(this.timer >= 0) {
      // setInterval(() => {
      //   this.timer--;
      // },1000)
    // } 
  setTimeout(() => {
    this.router.navigate(['']);
    }, 5000);
  }

}
