<main class="d-flex align-item-center">
   <section class="text-center d-flex w-100 align-item-center">
      <div class="container align-items-center d-flex justify-content-center">
         <div class="row">
            <div class="col-md-12">
               <div class="d-flex align-item-center justify-content-center h-100 flex-wrap">
                  <div class="py-5">
                     <h1 class="mb-3">
                        Oops! Something Went Wrong
                     </h1>
                     <h2 class="mb-3 text-danger">
                        404 Not Found
                     </h2>
                     <div class="error-details text-danger mb-3">
                        Sorry, an error has occured, Requested page not found! we are redirecting you to home page in {{timer}} seconds...
                     </div>
                     <!-- <div class="error-actions">
                        <a href="javascript:void(0)" routerLink="" class="mx-2 btn btn-primary btn-lg"><span class="fa fa-home"></span>
                        Take Me Home </a>
                        <a href="http://www.jquery2dotnet.com" class="mx-2 btn btn-light btn-lg"><span class="fa fa-envelope"></span> Contact Support </a>
                     </div> -->
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>