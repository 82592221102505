<main>
    <section class="impress_area pb-5">
       <div class="container py-md-5 pt-3 pb-4">
        <div class="large-title text-center">
          <h2 class="mb-0">File Upload</h2>
          <!-- <p>Book your Customer appointment.</p> -->
        </div>
        <div class="card shadow">
            <div class="card-body">
                <form [formGroup]="uoloadImageForm"  
        (ngSubmit)="onsubmit(uoloadImageForm.value)"  ngNativeValidate>
            <div class="row">
                <div class="form-group col-12">
                    <label for="exampleFormControlFile1"></label>
                    <input type="file" class="form-control-file" formControlName="imagedata" (change)="onFileChanged($event)"
                    [ngClass]="{ 'is-invalid':( (submitted || imagerror) && f.imagedata.errors)||(imagerror && errroMessage) }" id="image-upload" #myInput>
                    <div *ngIf="((submitted || imagerror) && f.imagedata.errors)||(imagerror && errroMessage)" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="f.imagedata&&f.imagedata.errors&&f.imagedata.errors.required">Image is required</div>
                        <div *ngIf="f.imagedata&&f.imagedata.errors&&f.imagedata.errors.extension">Only jpg and png images are allowed.</div>
                        <div *ngIf="errroMessage">{{errroMessage }}</div>
                     </div>
                     <div *ngIf="imageuploader" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                        <div class="bg-light py-0 px-1  rounded shadow-lg">
                           <div class="d-flex align-items-center">
                              <img src="{{tinyUrl2}}/loader.svg" class="img-fluid" width="50" /> 
                              <p class="mb-0 mt-1 mr-3">Loading...</p>
                           </div>
                        </div>
                     </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <label for="formGroupExampleInput">Image Title*</label>
                    <input type="text" class="form-control"  placeholder="Image Title" formControlName="image_title"
                    [ngClass]="{ 'is-invalid': submitted && f.image_title.errors }">
                    <div *ngIf="submitted && f.image_title.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="f.image_title.errors.required">Image Title is required</div>
                     </div>
                </div>
                <div class="col-12 col-md-6">
                    <label for="formGroupExampleInput">Image Type*</label>
                    <select  class="form-control" formControlName="image_option"
                    [ngClass]="{ 'is-invalid': submitted && f.image_option.errors }">
                        <option *ngFor="let item of cosmosFilter" value="{{item}}">{{item}}</option>
                    </select>  
                    <div *ngIf="submitted && f.image_option.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="f.image_option.errors.required">Image Type is required</div>
                     </div>        
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-12 col-md-4">
                    <label for="formGroupExampleInput">Location </label>
                    <select  class="form-control" (change)="onSelectCat($event)" formControlName="location"
                    [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
                   
                        <option  value=""> </option>
                        <option  *ngFor="let item of location" value="{{item.city_slug}}"> {{item.city_name}}</option>
                        </select>   
                        <div *ngIf="submitted && f.location.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                            <div *ngIf="f.location.errors.required">Location is required</div>
                         </div>        
                    </div> -->
                <div class="col-12 col-md-6">
                <label for="formGroupExampleInput">Category* </label>
                <select  class="form-control" (change)="onSelectCat($event)" formControlName="cat_id"
                [ngClass]="{ 'is-invalid': submitted && f.cat_id.errors }">
               
                    <option  value=""> </option>
                    <option  *ngFor="let item of category" value="{{item.ID}}"> 
                        <span *ngIf="item.category_name == 'Coalesce Quartz'">
                            Quartz
                        </span>
                        <span *ngIf="item.category_name != 'Coalesce Quartz'">
                            {{item.category_name}}
                        </span>
                    </option>
                    </select>   
                    <div *ngIf="submitted && f.cat_id.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="f.cat_id.errors.required">Category is required</div>
                     </div>        
                </div>
               
                <div class="col-12 col-md-6">
                    <label for="formGroupExampleInput">Material Name*</label>
                 <input type="text" class="form-control"  placeholder="Material Name" formControlName="product_name"
                    [ngClass]="{ 'is-invalid': submitted && f.product_name.errors }">
                    <!-- <select  class="form-control" (change)="onSelectCat($event)" formControlName="product_name"
                    [ngClass]="{ 'is-invalid': submitted && f.product_name.errors }"  (change)="onSelectPro($event)">
                       
                        </select>    -->
                    <div *ngIf="submitted && f.product_name.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="f.product_name.errors.required">Material Name is required</div>
                     </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-12 col-md-6">
                <label for="formGroupExampleInput">Job Name </label>
                <input type="text" class="form-control"  placeholder="Job Name" formControlName="job_name">
           
            
            </div> -->
                <div class="col-12 col-md-12">
                    <label for="formGroupExampleInput">Comment </label>
                    <!-- <input type="text" class="form-control"  placeholder="Comment" formControlName="customer_name"> -->
                    <textarea class="form-control"  placeholder="Please mention your name, lot/bundle number and other specific details about the product image" formControlName="customer_name">

                    </textarea>
                    <!-- [ngClass]="{ 'is-invalid': submitted && f.customer_name.errors }">
                    <div *ngIf="submitted && f.customer_name.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="f.customer_name.errors.required">Customer Name is required</div>
                     </div> -->
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="exampleterms"
                        formControlName="terms"  [checked]="uoloadImageForm.controls.terms.value == true" (change)="onClickcheckBox($event)" [ngClass]="{ 'is-invalid': submitted && f.terms.errors }">
                        <label class="form-check-label" for="exampleterms">
                            <small>
                            I hereby agree and express my consent to the commercial or non-commercial usage of the uploaded image across any platform, including but not limited to social media, print media, website, etc., within IGM Surfaces and Cosmos Granite & Marble.
                            </small>
                        </label>
                        <div *ngIf="submitted && f.terms.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                            <div *ngIf="f.terms.errors.required">Terms & Conditions are required</div>
                         </div> 
                      </div>
                </div>
            </div>
            <div class="form-group row mt-2">
                <div class="col-12" *ngIf="isBrowser">
                    <!-- 6LfBH_0ZAAAAAO-_knQytXdQohMUyPFZU2tJzbQm -->
                    <ngx-recaptcha2 #captchaElem
                    [siteKey]="siteKey"
                    (success)="handleSuccess($event)"
                    [useGlobalDomain]="false"
                    [size]="size"
                    [hl]="lang"
                    [theme]="theme"
                    [type]="type"
                    formControlName="captcha">
                  </ngx-recaptcha2>
                  <!-- <ngx-recaptcha2 #captchaElem
                [siteKey]="'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'"
                (success)="handleSuccess($event)"
                [useGlobalDomain]="false"
                [size]="size"
                [hl]="lang"
                [theme]="theme"
                [type]="type"
                formControlName="captcha">
              </ngx-recaptcha2> -->
              <div *ngIf="submitted && f.captcha.errors" class=" alert alert-danger py-1 px-3 mt-2">
                <div > Select captcha  first.</div>
             </div>
                </div>
                
             
              
            </div>
            
            <div class="row">
                
                <div  *ngIf="message"class="col-12 alert alert-success">
                    
                    <p>{{message}}</p>
                </div>
            </div>
            <div class="form-group row mt-2">
                <div class="col-12">
                    <div class="text-center d-flex justify-content-center">
                    <button  class="d-flex align-items-center shadow btn btn-theme py-3 px-5 d-flex"
                    [disabled]="!uoloadImageForm.controls.image.value">Submit</button>
                    </div>
                </div>
            </div>
            <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                   <div class="d-flex align-items-center">
                      <img src="{{tinyUrl2}}/loader.svg" class="img-fluid" width="50" /> 
                      <p class="mb-0 mt-1 mr-3">Loading...</p>
                   </div>
                </div>
             </div>
            </form>
            </div>
        </div>
    </div>
    </section>
</main>