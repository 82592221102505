import { Injectable } from '@angular/core';

declare let document: any;
@Injectable({
  providedIn: 'root'
})

export class SharedService {

  constructor() { }
  categoryList;
  newArival;
  selectedCat;
  selectedlocation
  showFilter = false;
  resetFilter;
  showCookieMsg = false;
  homeAddress:any;
  phone:any;
  cleardata() {
    localStorage.removeItem('pro_catagory');
    localStorage.removeItem('location');
    this.selectedlocation = undefined
  }

  // getCookie(cname) {
  //   var name = cname + "=";
  //   var ccooki = document.cookie
  //   var ca = ccooki.split(';');
  //   for(var i = 0; i < ca.length; i++) {
  //     var c = ca[i];
  //     while (c.charAt(0) == ' ') {
  //       c = c.substring(1);
  //     }
  //     if (c.indexOf(name) == 0) {
  //       return c.substring(name.length, c.length);
  //     }
  //   }
  //   return "";
  // }
}
