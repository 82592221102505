import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from './../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';

const md5 = new Md5();
const currentTimestaps = new Date().getTime().toString();
/**
 * auth header is created with the help of md5 generation 
 * pass (70bcceab4041ad1843196f17f67d65b9) parameter decrepted value is covetusDev 
 * @param currentTimestaps parameter is current time stapm,
 * @param authstr parameter is current timestamps,
 * @return a md5 generated string
 */
const authstr = md5.appendStr('user=covetusDev&pass=70bcceab4041ad1843196f17f67d65b9&token=' + currentTimestaps).end();
/**
 * Common header to call and varify all APIs 
 * for more info contact jayesh sharda sir and akshay patidar sir.
 * @param 'Authorization': authstr.toString(),
 * @param 'Authtimestamp':currentTimestaps
 * @return authenticate the all endpoints
 */
const headers = {
	headers: new HttpHeaders({
		// 'Content-Type': 'application/json',
		'Authorization': authstr.toString(),
		'Authtimestamp': currentTimestaps
	})
};
const headers2 = {
	headers: new HttpHeaders({
		// 'Content-Type': 'application/json',
		'Authorization': authstr.toString(),
		'Authtimestamp': currentTimestaps
	})
};

@Injectable({
	providedIn: 'root'
})

export class ApiService {

	constructor(private http: HttpClient) {
	}
	/**
 * Common function to call all APIs
 * @param endpoint
 * @param args
 * @param httpmethod
 * @return response as obserbalbe
 */

	fetchData(endpoint, args = {}, httpmethod = 'GET'): Observable<any> {

		let requestURL = environment.baseUrl + endpoint;

		/**
		 * To covert parameters into query string
		 */

		if (httpmethod == 'GET') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.get(requestURL, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'DELETE') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.delete(requestURL, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'POST') {
			return this.http.post(requestURL, args, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'PUT') {
			return this.http.put(requestURL, args, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}
	}
	fetchDatalive(endpoint, args = {}, httpmethod = 'GET'): Observable<any> {

		let requestURL = 'https://slabs.igmsurfaces.com/api/v1/' + endpoint;

		/**
		 * To covert parameters into query string
		 */

		if (httpmethod == 'GET') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.get(requestURL, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'DELETE') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.delete(requestURL, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'POST') {
			return this.http.post(requestURL, args, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'PUT') {
			return this.http.put(requestURL, args, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}
	}
	fetchDataCosmos(endpoint, args = {}, httpmethod = 'GET'): Observable<any> {

		let requestURL = environment.cosmosgranitebaseUrl + endpoint;

		/**
		 * To covert parameters into query string
		 */

		if (httpmethod == 'GET') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.get(requestURL, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'DELETE') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.delete(requestURL, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'POST') {
			return this.http.post(requestURL, args, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'PUT') {
			return this.http.put(requestURL, args, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}
	}
	fetchDataCosmoslive(endpoint, args = {}, httpmethod = 'GET'): Observable<any> {

		let requestURL = 'https://www.cosmossurfaces.com/api/' + endpoint;

		/**
		 * To covert parameters into query string
		 */

		if (httpmethod == 'GET') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.get(requestURL, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'DELETE') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.delete(requestURL, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'POST') {
			return this.http.post(requestURL, args, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'PUT') {
			return this.http.put(requestURL, args, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}
	}
	fileUpload(endpoint, args = {}, httpmethod = 'POST'): Observable<any> {

		let requestURL = environment.cosmosgranitebaseUrl + endpoint;

		/**
		 * To covert parameters into query string
		 */

		if (httpmethod == 'POST') {
			return this.http.post(requestURL, args, headers)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}


	}

	inventory(endpoint, args = {}, httpmethod = 'GET'): Observable<any> {
		// ?op=GetInventoryLots
		let requestURL = 'https://igmcorp.stoneprofits.com/custom/igmcorp/WebService.asmx/' + endpoint;

		/**
		 * To covert parameters into query string
		 */

		if (httpmethod == 'GET') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.get(requestURL, { observe: 'body', responseType: 'text' })
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'DELETE') {
			requestURL = this.createUrl(requestURL, httpmethod, args);
			return this.http.delete(requestURL)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'POST') {
			return this.http.post(requestURL, args)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}

		if (httpmethod == 'PUT') {
			return this.http.put(requestURL, args)
				.pipe(map(data => data),
					catchError(this.handleError('Error in getting data', []))
				);
		}
	}


	/**
	 * Create request url with params and required options
	 * @param requestURI string
	 * @param args objext
	 * @param httpmethod get/put/post/delete
	 * @return string
	 */
	createUrl(requestURI, httpmethod, args) {
		let parameters = {};

		if (httpmethod === 'GET') {

			if (Object.keys(args).length > 0) {
				for (const key in args) {
					parameters[key] = args[key];
				}
			}
		}

		requestURI = requestURI + '?' + this.toQueryString(parameters); // To covert parameters into query string

		return requestURI;
	}

	/**
	 * @param obj object
	 * @return string
	 */
	private toQueryString(obj) {
		const parts = [];

		for (const i in obj) {
			if (obj.hasOwnProperty(i)) {
				parts.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]));
			}
		}

		return parts.join('&');
	}

	/**
	 * Handle error
	 * @param operation
	 * @param result
	 */
	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => { // TODO: send the error to remote logging infrastructure
			return of(result as T);
		};
	}

}
