import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { searchForm } from '../forms';
import { SharedService, ApiService, SeoServiceService } from '../service/index';
// import { CookieService } from 'ngx-cookie-service';



declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-locationdetail',
  templateUrl: './locationdetail.component.html',
  styleUrls: ['./locationdetail.component.css']
})
export class LocationdetailComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  tinyUrl2= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';  
  
  quartsData;
  searchForm = searchForm;
  filters = [];
  allData = undefined;
  locationData;
  submitted: boolean= false;
  searchedPro;
  location_slug;
  filtersdata;
  loadMore = false;
  flage = undefined;
  productData = undefined;
  showFilter = false;
  popupdata;
  constructor(private fb: FormBuilder, private api: ApiService,
    private router: Router, private shared: SharedService, private _seoService:SeoServiceService,
   ) {
    let data = this.router.url.split("/");
    this.allData = undefined;
    this.productData = undefined;
    this.quartsData = undefined;
    this.searchedPro = undefined;
    this.location_slug = data[1];
    localStorage.setItem('location',data[1]);
    this.showFilter = false;
    this.loadMore = false;
    this.getAllFilter();
    this.getProductData();
    this.getQuartsProduct();
    this.getCategory();
    this.getSingleLocation();
    this.getSeoData();
    this.getPopupdata();
    this.searchedPro = undefined;
    this.shared.selectedlocation = localStorage.getItem('location') ? localStorage.getItem('location') : null;


  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: ['', ''],
  
    });
    this.searchForm.patchValue({
      search:null
    })
  }

  ngAfterViewInit(){
    $(document).ready(function() {
      var sync1 = $(".slider");
  var sync2 = $(".navigation-thumbs");
  
  var thumbnailItemClass = '.owl-item';
  
  var slides = sync1.owlCarousel({
    video:true,
    startPosition: 12,
    items:1,
    loop:true,
    margin:0,
    autoplay:true,
    autoplayTimeout:6000,
    autoplayHoverPause:false,
    nav: true,
    dots: false
  }).on('changed.owl.carousel', syncPosition);
  
  function syncPosition(el) {
    var $owl_slider = $(this).data('owl.carousel');
    var loop = $owl_slider.options.loop;
  
    if(loop){
      var count = el.item.count-1;
      var current = Math.round(el.item.index - (el.item.count/2) - .5);
      if(current < 0) {
          current = count;
      }
      if(current > count) {
          current = 0;
      }
    }else{
       current = el.item.index;
    }
  
    var owl_thumbnail = sync2.data('owl.carousel');
    var itemClass = "." + owl_thumbnail.options.itemClass;
  
  
    var thumbnailCurrentItem = sync2
    .find(itemClass)
    .removeClass("synced")
    .eq(current);
  
    thumbnailCurrentItem.addClass('synced');
  
    if (!thumbnailCurrentItem.hasClass('active')) {
      var duration = 300;
      sync2.trigger('to.owl.carousel',[current, duration, true]);
    }   
  }
  var thumbs = sync2.owlCarousel({
    startPosition: 12,
    items:4,
    loop:false,
    margin:0,
    autoplay:false,
    nav: false,
    dots: false,
    onInitialized: function (e) {
      var thumbnailCurrentItem =  $(e.target).find(thumbnailItemClass).eq(this._current);
      thumbnailCurrentItem.addClass('synced');
    },
  })
  .on('click', thumbnailItemClass, function(e) {
      e.preventDefault();
      var duration = 300;
      var itemIndex =  $(e.target).parents(thumbnailItemClass).index();
      sync1.trigger('to.owl.carousel',[itemIndex, duration, true]);
  }).on("changed.owl.carousel", function (el) {
    var number = el.item.index;
    var $owl_slider = sync1.data('owl.carousel');
    $owl_slider.to(number, 100, true);
  });
  
  $('.owl-carousel2').owlCarousel({
    loop: true,
    margin: 40,
    //default settings:
    autoplay: true,
    autoplayTimeout: 3500,
    autoplayHoverPause: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: false
      },
      600: {
        items: 2,
        nav: false
      },
      1000: {
        items: 3,
        nav: false
      }
    }
  });
  
  
      $('.filters ul li').click(function(){
        $('.filters ul li').removeClass('active');
        $(this).addClass('active');
        
        var data = $(this).attr('data-filter');
        $grid.isotope({
          filter: data
        })
      });
      
      var $grid = $(".grid").isotope({
        itemSelector: ".all",
        percentPosition: true,
        masonry: {
          columnWidth: ".all"
        }
      });
    
    })

    

$(document).ready(function(){

  $(".filter-button").click(function(){
     $(this).addClass('active');
      var value = $(this).attr('data-filter');
      
      if(value == "all")
      {
          //$('.filter').removeClass('hidden');
          $('.filter').show('1000');
         
      }
      else
      {
//            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
//            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
          $(".filter").not('.'+value).hide('3000');
          $('.filter').filter('.'+value).show('3000');
      
          
      }
  });
  
  $('.filter-button').removeClass('active');

  


  $(".filter-button2").click(function(){
     $(this).addClass('active');
      var value = $(this).attr('data-filter');
      
      if(value == "all")
      {
          //$('.filter').removeClass('hidden');
          $('.filter2').show('1000');
         
      }
      else
      {
//            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
//            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
          $(".filter2").not('.'+value).hide('3000');
          $('.filter2').filter('.'+value).show('3000');
      
          
      }
  });
  
  $('.filter-button2').removeClass('active');

});

  }

  get f() { return this.searchForm.controls; }

  onSearch(e) {
    this.submitted = true;
     // stop here if form is invalid
     if (this.searchForm.invalid) {
      return;
     }
     this.filters = [];
    let value = e.target.value.trim()
    if(value.length > 2) {
      this.flage = 'search'
      this.loadMore = true;
      this.productData = undefined;
      this.quartsData = undefined;
      this.api.fetchData('filters',{'city_slug' :this.location_slug,search:value  },'GET').subscribe(
          res => {
            if(res['status'] == 1) {
            this.searchedPro = res['data'];
            this.loadMore = false;
          } else {
            this.searchedPro = [];
            this.loadMore = false;
            }
          }
        )
    }
    if(value.length == 0) {
      this.getProductData();
      this.getQuartsProduct();
      this.flage = undefined;
    }
  }
  
  clearSearch() {
    this.productData = undefined;
    this.quartsData = undefined;
    this.getProductData();
    // this.getQuartsProduct();
    this.searchedPro = undefined;
    this.flage = undefined;
    this.searchForm.patchValue({
      search:null
    })
    
  }

  selectFliter(item, type){
    let name  = '';
    if(type == 'category') {
      name = item.category_name
    }
    if(type == 'color' || type == 'thickness' || type == 'group') {
      name = item
    }
    let obj = {
      type: type,
      name: name
    }
    let x  = this.filters.findIndex(f => f.name == name);
    if(x == -1) {
      this.filters.push(obj)
      this.searchByfilter()
    }
  }

  clearFilter(){
    this.productData = undefined;
    this.searchedPro = undefined;
    this.quartsData = undefined;
    this.filters = [];
    this.getProductData()
    // this.getQuartsProduct()
  }
  removeFilter(x) {
    this.filters.splice(x,1);
    if(this.filters.length == 0) {
      this.clearFilter()
    }else{
      this.searchByfilter()
    }
  }

  checkFilter(event, key, type) {
    let name = ''
    if(type == 'usages' || type == 'Finish' || type == 'origine') {
      name = key
    }
 
    let obj = {
      type: type,
      name: name
    }
    if(event.target.checked) {
      this.filters.push(obj)
    } else {
      let x  = this.filters.findIndex(f => f.name == key);
      this.filters.splice(x,1);
    }
    this.searchByfilter()
  }

  getCheckedValue(key) {
    let x  = this.filters.findIndex(f => f.name == key);
    return (x == -1) ? false : true;  
  }


  searchByfilter(){
  this.searchedPro = undefined;
  this.quartsData = undefined;
  this.searchForm.patchValue({
    search:null
  })
  this.productData = undefined;
  let color = [];
  let category = [];
  let origine = [];
  let group = [];
  this.filters.map((f,i) => {
    if(f.type == 'color') {
      color.push(f.name)
    }
    if(f.type == "category") {
      category.push(f.name)
    }
    if(f.type == "origine") {
      origine.push(f.name)
    }
    if(f.type == "group") {
      group.push(f.name)
    }
    if((i+1) == this.filters.length) {
      this.loadMore = true;
      this.api.fetchData('filters',{'city_slug' :this.location_slug, category_slug:category.toString(),
      color:color.toString(),
      origin:origine.toString(),group:group.toString() },'GET').subscribe(
        res => {
        
          if(res['status'] == 1) {
          this.searchedPro = res['data']
          this.loadMore = false;
        } else {
          this.searchedPro = [];
          this.loadMore = false;
          }
        }
      )
    }
  }) 
  }

 getProductData(){
  this.api.fetchData('filters',{'city_slug' :this.location_slug},'GET').subscribe(
    res => {
      if(res['status']==1) {
      this.productData = res['data']
      }else {
        this.productData = [];
      }
    }
  )
 }

  getAllFilter(){
    this.api.fetchData('allfilters',{'city_slug': this.location_slug},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.filtersdata = res['filters'];
          // this.shared.categoryList = res['filters']['category']
          this.allData = res['filters']['category'];            
        }else {
          // this.shared.categoryList =[];
        }
  })
}
 
  productList( slug, i) {
    this.allData[i].counter++;
    this.api.fetchData('productlist',{'location' :this.location_slug,category_name:slug, offset:this.allData[i].counter*4, limit:4  },'GET').subscribe(
      res => {
        if(res['status']==1) {
          this.allData[i]['productData']['product'] = this.allData[i]['productData']['product'].concat( res['product']);
          this.allData[i]['productData']['more'] = res['more'];
        }else {
        }
      }
    )
  }

  goto(product, slug) {
    // let selectedcat = localStorage.getItem('pro_catagory')
    localStorage.setItem('pro_catagory',slug)
    this.router.navigate(['/'+this.location_slug+'/'+slug+'/'+ product.productSlug]);
  }

  getSingleLocation() {
    this.api.fetchData('location',{'city_slug' :this.location_slug },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.locationData = res['location'][0]
        } else {
          this.locationData = '';
        }
      }
    )
  }


  gotocategory( cat) {
    localStorage.setItem('pro_catagory',cat)
    this.router.navigate(['/'+this.location_slug+'/'+cat+'/']);

  }

  getQuartsProduct() {
    this.api.fetchDataCosmos('specialproductlistquartz', {page:1,website:'igm' },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.quartsData = res['product'];
        } else {
          this.quartsData = [];
        }
      }
    )
  }

  getCategory() {
    this.api.fetchData('product_category',{location:this.location_slug},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }

  getSeoData(){
    let patname =  this.router.url;
    patname = patname.slice(0, 0) + patname.slice(1);
    this.api.fetchDatalive('seodata',{page_name: patname},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
        this._seoService.updateTitle(res['data'].title);
        this._seoService.updateDescription(res['data'].description)
  //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }
  getPopupdata() {
    // this.cookieService.set( 'Test', 'Hello World' );
    // this.cookieValue = this.cookieService.get('Test');
    // let cookie =this.cookieService.get(this.location_slug);
    let cookie = this.getCookie(this.location_slug)
    console.log(cookie);
    let todaydate = new Date();
    todaydate.setDate(todaydate.getDate() + 1);

    if(!cookie) {
      // this.cookieService.put(this.location_slug,'closed',{
      //   expires : todaydate,
      //   httpOnly: true,
      //   secure:true
      // });
      // console.log('runnn')
      
      this.api.fetchData('popupdata',{location:this.location_slug}, 'GET').subscribe(
        res => {
          if(res['response'] == 1) {
            this.setCookie(this.location_slug, 'closed', todaydate)
            this.popupdata = res['data'];
            setTimeout(()=> {
              document.getElementById('locaion-pop-notification').click();
            },300)
            setTimeout(()=> {
              document.getElementById('close-popup-loc').click();
            },10000)
          }
        }
      )
    }
  }

   setCookie(cname, cvalue, exdays) {
    // var d = new Date();
    // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+exdays.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
   getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

}
