<main>
   <section class="tringle about-area section-padding2 bg-light">
      <div class="container py-5">
         <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
               <div class="about-caption">
                  <!-- Section Tittle -->
                  <div class="large-title text-left">
                     <!-- <span>Product</span> -->
                     <h2 class="mb-3">About Product</h2>
                  </div>
                  <p class="text-left mb-1">
                     We offer a wide array of stone products, ranging from quartz to natural stones like porcelain, calcite, and marble, to make your space look beautiful and last for years long.
                  </p>
                  <p class="text-left mb-1">Whether you are looking to renovate your home or office, we have different stone products to suit your space. Commercial projects are our specialty, and we deliver the best products in the stone market. Our warehouse is equipped with superior facilities to deliver your products at the doorstep with 100% safety.</p>
                  <p class="text-left mb-1">Furthermore, we have a team of experts to guide you in selecting the best products for your space. Come and visit our store at different locations to see the products live.</p>
               </div>
            </div>
            <div class="col-lg-6 col-md-12">
               <div class="owl-carousel owl-theme theme-owl slider4">
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                     <img src="{{tinyUrl}}/products/dolomite.jpg" class="rounded m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                     <img src="{{tinyUrl}}/products/granite.jpg" class="rounded m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                     <img src="{{tinyUrl}}/products/limestone.jpg" class="rounded m-auto w-auto img-fluid">
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="pt-5 bg-white" id="accordion">
      <div class="container-fluid">
         <div class="row mb-3 align-items-center justify-content-center">
            <div class="col-xl-10 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">FIND YOUR PERFECT MATCH</h2>
                  <p class="mb-4">
                     We offer a wide array of stone products, ranging from quartz to natural stones like porcelain, calcite, and marble, to make your space look beautiful and last for years long.
                  </p>
               </div>
               <!-- <button class="btn-theme btn border-0">VIEW OUR NEWEST DESIGNS<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button> -->
            </div>
         </div>
         <div class="">
            <div class="d-flex row productRow mt-2 pl-4">
               <div class="d-flex flex-wrap justify-content-center owl-carousel owl-theme theme-owl product-carousel">
                  <div class="item">
                     <div id="headingQuartz" class="col-sm-auto col-10 img-shine overflow-hd mb-sm-2 mb-0 mx-auto">
                        <div class="thumb" data-toggle="collapse" data-target="#collapseQuartz" aria-expanded="true" aria-controls="collapseQuartz">
                           <a href="javascript:void(0)" style="background-image: url({{tinyUrl}}/products/quartz.jpg);">
                           </a>
                        </div>
                        <div class="productdeatils">Coalesce Quartz </div>
                     </div>
                  </div>
                  <div class="item">
                     <div id="headingGranite" class="col-sm-auto col-10 img-shine overflow-hd mb-sm-2 mb-0 mx-auto">
                        <div class="thumb collapsed" data-toggle="collapse" data-target="#collapseGranite" aria-expanded="false" aria-controls="collapseGranite">
                           <a href="javascript:void(0)" style="background-image: url({{tinyUrl}}/products/granite.jpg);">
                           </a>
                        </div>
                        <div class="productdeatils">Granite</div>
                     </div>
                  </div>
                  <div class="item">
                     <div id="headingMarble" class="col-sm-auto col-10 img-shine overflow-hd mb-sm-2 mb-0 mx-auto">
                        <div class="thumb collapsed" data-toggle="collapse" data-target="#collapseMarble" aria-expanded="false" aria-controls="collapseMarble">
                           <a href="javascript:void(0)" style="background-image: url({{tinyUrl}}/products/marble.jpg);">
                           </a>
                        </div>
                        <div class="productdeatils">Marble</div>
                     </div>
                  </div>
                  <div class="item">
                     <div id="headingQuartzite" class="col-sm-auto col-10 img-shine overflow-hd mb-sm-2 mb-0 mx-auto">
                        <div class="thumb collapsed" data-toggle="collapse" data-target="#collapseQuartzite" aria-expanded="false" aria-controls="collapseQuartzite">
                           <a href="javascript:void(0)" style="background-image: url({{tinyUrl}}/products/quartzite.jpg);">
                           </a>
                        </div>
                        <div class="productdeatils">Quartzite</div>
                     </div>
                  </div>
                  <div class="item">
                     <div id="headingPorcelain" class="col-sm-auto col-10 img-shine overflow-hd mb-sm-2 mb-0 mx-auto">
                        <div class="thumb collapsed" data-toggle="collapse" data-target="#collapsePorcelain" aria-expanded="false" aria-controls="collapsePorcelain">
                           <a href="javascript:void(0)" style="background-image: url({{tinyUrl}}/products/porcelain.jpg);">
                           </a>
                        </div>
                        <div class="productdeatils">Porcelain</div>
                     </div>
                  </div>
                  <div class="item">
                     <div id="headingDolomite" class="col-sm-auto col-10 img-shine overflow-hd mb-sm-2 mb-0 mx-auto">
                        <div class="thumb collapsed" data-toggle="collapse" data-target="#collapseDolomite" aria-expanded="false" aria-controls="collapseDolomite">
                           <a href="javascript:void(0)" style="background-image: url({{tinyUrl}}/products/dolomite.jpg);">
                           </a>
                        </div>
                        <div class="productdeatils">Dolomite</div>
                     </div>
                  </div>
                  <div class="item">
                     <div id="headingCalcite" class="col-sm-auto col-10 img-shine overflow-hd mb-sm-2 mb-0 mx-auto">
                        <div class="thumb collapsed" data-toggle="collapse" data-target="#collapseCalcite" aria-expanded="false" aria-controls="collapseCalcite">
                           <a href="javascript:void(0)" style="background-image: url({{tinyUrl}}/products/marble.jpg);">
                           </a>
                        </div>
                        <div class="productdeatils">Calcite</div>
                     </div>
                  </div>
                  <div class="item">
                     <div id="headingSlate" class="col-sm-auto col-10 img-shine overflow-hd mb-sm-2 mb-0 mx-auto">
                        <div class="thumb collapsed" data-toggle="collapse" data-target="#collapseSlate" aria-expanded="false" aria-controls="collapseSlate">
                           <a href="javascript:void(0)" style="background-image: url({{tinyUrl}}/products/marble.jpg);">
                           </a>
                        </div>
                        <div class="productdeatils">Slate</div>
                     </div>
                  </div>
                  <div class="item">
                     <div id="headingSoapStone" class="col-sm-auto col-10 img-shine overflow-hd mb-sm-2 mb-0 mx-auto">
                        <div class="thumb collapsed" data-toggle="collapse" data-target="#collapseSoapStone" aria-expanded="false" aria-controls="collapseSoapStone">
                           <a href="javascript:void(0)" style="background-image: url({{tinyUrl}}/products/Black-Soapstone.jpg);">
                           </a>
                        </div>
                        <div class="productdeatils">Soap Stone</div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row mt-3">
               <div id="collapseQuartz" class="collapse show" aria-labelledby="headingQuartz" data-parent="#accordion" style="">
                  <div class="bg-light card border-0 p-5">
                     <h2 class="h4 mb-2 text-left">Coalesce Quartz</h2>
                     <p class="text-left mb-1">
                        Be it a home or office space: quartz shines everywhere. If you are planning to modify your existing space, then quartz should be your choice.
                     </p>
                     <p class="text-left mb-1"> 
                        We offer premium quality quartz products that are made up of 90% natural quartz. At IGM, we only choose products that retain the qualities of natural stone and look beautiful for years.
                     </p>
                     <p class="text-left mb-1"> 
                        Visit our store at your nearby location and select from the quartz products, ranging from zodiac yellow to ginger tan. We also offer different sizes of quartz slabs to suit your requirements. We have a team of experts who assist you during the selection process and also deliver your chosen quartz product on time
                     </p>
                     <p class="text-left mt-2 mb-0"> 
                        <button class="btn btn-theme mb-2 border-0" [routerLink]="['/quartz']">VIEW COALESCE QUARTZ PRODUCTS<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                     </p>
                  </div>
               </div>
               <div id="collapseGranite" class="collapse" aria-labelledby="headingGranite" data-parent="#accordion" style="">
                  <div class="bg-light card border-0 p-5">
                     <h2 class="h4 mb-2 text-left">Granite</h2>
                     <p class="text-left mb-1">
                        We have over 70 years of experience and are one of the leading stores in delivering granite stone products. Be it a bathroom countertop or exterior of your workspace: we have a complete range of granite products for designers, architects, and individuals. Our different varieties of granite products can add warmth to your space. 
                     </p>
                     <p class="text-left mb-1"> 
                        The granite stone is resistant to heat and stain and doesn’t require extra maintenance. Visit our IGM store at your nearby location and choose the right granite product for your existing or new space. We are a certified exporter of granite in the country and deliver the best customer satisfaction.
                     </p>
                     <p class="text-left mt-2 mb-0"> 
                        <button class="btn btn-theme mb-2 border-0"[routerLink]="['/selectlocation']" [queryParams]="{ category: 'granite'}">VIEW GRANITE PRODUCTS<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                     </p>
                  </div>
               </div>
               <div id="collapseMarble" class="collapse" aria-labelledby="headingMarble" data-parent="#accordion" style="">
                  <div class="bg-light card border-0 p-5">
                     <h2 class="h4 mb-2 text-left">Marble</h2>
                     <p class="text-left mb-1">
                        Marble is an excellent natural stone for decor lovers. The natural look and feel of marble have pleased everyone for years. We offer a broad range of marble stones that feature elegant textures and myriads of designs.
                     </p>
                     <p class="text-left mb-1"> 
                        Marble stones look amazingly gorgeous and make a bold statement in any space. If maintained well, marble enhances the value of the property. Some find it expensive, but marbles are worth installing and look truly unique.
                     </p>
                     <p class="text-left mb-1"> 
                        We offer a variety of marbles, right from the rainbow to teakwood, to redefine your space. Our services revolve around client requirements, and we supply premium quality marbles at reasonable prices. 
                     </p>
                     <p class="text-left mt-2 mb-0"> 
                        <button class="btn btn-theme mb-2 border-0" [routerLink]="['/selectlocation']" [queryParams]="{ category: 'marble'}">VIEW MARBLE PRODUCTS<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                     </p>
                  </div>
               </div>
               <div id="collapseQuartzite" class="collapse" aria-labelledby="headingQuartzite" data-parent="#accordion" style="">
                  <div class="bg-light card border-0 p-5">
                     <h2 class="h4 mb-2 text-left">Quartzite</h2>
                     <p class="text-left mb-1">Quartzite is an excellent choice among professionals due to its natural strength and low-maintenance. Its crystalline sparkle transforms work surfaces, feature walls, and other spaces. The natural soul of this product makes it resistant to wear and tear. </p>
                     <p class="text-left mb-1">Its unique properties give rise to old bathrooms and kitchens. The durability of the product offers an extra shine to space and makes it look more appealing and the finest choice for commercial and residential projects. Come and have a look at the diverse collection of quartzite stones available at our stores in different locations. You can also consult our experts for further guidance.</p>
                     <p class="text-left mt-2 mb-0"> 
                        <button class="btn btn-theme mb-2 border-0" [routerLink]="['/selectlocation']" [queryParams]="{ category: 'quartzite'}">VIEW QUARTZITE PRODUCTS<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                     </p>
                  </div>
               </div>
               <div id="collapsePorcelain" class="collapse" aria-labelledby="headingPorcelain" data-parent="#accordion" style="">
                  <div class="bg-light card border-0 p-5">
                     <h2 class="h4 mb-2 text-left">Porcelain</h2>
                     <p class="text-left mb-1">With thoughtful design and a fascinating range of products, we at IGM Surfaces offer varieties of porcelain products to furnish your project. These tiles are known for strength, durability, and beauty and offer better benefits than other natural products. They are extremely attractive, resistant to wear and tear, and are less prone to staining. </p>
                     <p class="text-left mb-1">Our porcelain tiles are best to use in high traffic areas and look stunning overall. Furthermore, they are durable, have more moisture resistant, and act as a reliable form of flooring. Come and pay a visit to our store at your nearby location to explore our range of porcelain products. </p>
                     <p class="text-left mt-2 mb-0"> 
                        <button class="btn btn-theme mb-2 border-0" [routerLink]="['/selectlocation']" [queryParams]="{ category: 'porcelain'}">VIEW PORCELAIN PRODUCTS<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                     </p>
                  </div>
               </div>
               <div id="collapseDolomite" class="collapse" aria-labelledby="headingDolomite" data-parent="#accordion" style="">
                  <div class="bg-light card border-0 p-5">
                     <h2 class="h4 mb-2 text-left">Dolomite</h2>
                     <p class="text-left mb-1">The dolomites are harder than the marble but softer than granite. These products are resistant to pressure and need sealing to prevent staining. Dolomite is a great option for backsplashes and countertops. It is also known as dolomite rock and is porous by nature. </p>
                     <p class="text-left mb-1">
                        We offer Dolomite products that are less expensive than marble and are a great alternative to other products. Know the specifics of dolomite products and the pros of installing them for your residential and commercial projects. We are committed to delivering the best products & services for decades. Come and take a close look at our dolomite products by visiting our nearby store. 
                     </p>
                     <p class="text-left mt-2 mb-0"> 
                        <button class="btn btn-theme mb-2 border-0" [routerLink]="['/selectlocation']" [queryParams]="{ category: 'dolomite'}">VIEW DOLOMITE PRODUCTS<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                     </p>
                  </div>
               </div>
               <div id="collapseCalcite" class="collapse" aria-labelledby="headingCalcite" data-parent="#accordion" style="">
                  <div class="bg-light card border-0 p-5">
                     <h2 class="h4 mb-2 text-left">Calcite</h2>
                     <p class="text-left mb-1">Calcite is one of the most beautiful stones in our collection. The elegant texture and soft veins on its surface make it a good choice for home and office settings. It is a soft stone and can be molded into any shape. These slabs have a classic appearance and blend well with almost any design. Our calcite slabs are a perfect choice if you want to adore your home with a classy stone. </p>
                     <p class="text-left mb-1">The stones comprise the sophistication and beauty of natural stone and make a bold statement. We have our warehouses at different locations, and our team of trained salespeople can guide you in selecting the best according to trend. </p>
                     <p class="text-left mt-2 mb-0"> 
                        <button class="btn btn-theme mb-2 border-0" [routerLink]="['/selectlocation']" [queryParams]="{ category: 'calcite'}">VIEW CALCITE<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                     </p>
                  </div>
               </div>
               <div id="collapseSlate" class="collapse" aria-labelledby="headingSlate" data-parent="#accordion" style="">
                  <div class="bg-light card border-0 p-5">
                     <h2 class="h4 mb-2 text-left">Slate</h2>
                     <p class="text-left mb-1">Slate is resistant to slips and is considered to be a non-porous flooring material. It is a great choice for homeowners because it is resistant to water and fire</p>
                     <p class="text-left mb-1">Natural Slate brings beauty to flooring and features a combination of durability and strength. It is durable and adds value to your property. As it comes in innumerable colors, Slate manifests in several ways and can be matched with any decorative style. </p>
                     <p class="text-left mb-1">It is considered an upscale flooring material and immediately attracts visitors. We, at the IGM store, offer a range of slate products ranging from polished to honed finish to give your space a surreal look. </p>
                     <p class="text-left mt-2 mb-0"> 
                        <button class="btn btn-theme mb-2 border-0"  [routerLink]="['/selectlocation']" [queryParams]="{ category: 'slate'}">VIEW SLATE PRODUCTS<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                     </p>
                  </div>
               </div>
               <div id="collapseSoapStone" class="collapse" aria-labelledby="headingSoapStone" data-parent="#accordion" style="">
                  <div class="bg-light card border-0 p-5">
                     <h2 class="h4 mb-2 text-left">Soap Stone</h2>
                     <p class="text-left mb-1">Soapstone is the finest choice of designers and architects. It is resistant to heat and non-porous. It is attractive and gives a custom feel to the home. It is extremely durable, and thus, a top choice for scientific laboratories. </p>
                     <p class="text-left mb-1">Furthermore, the soapstone is environmentally friendly and does not let acids and bacteria to etch the surface. It is soft and thus easier to carve and mold into any shape. It can withstand the heat of hot pans and baking sheets.</p>
                     <p class="text-left mb-1">Come and browse through our collection of soapstone at our different stores and give a mesmerizing look to your home. If you have any query, then feel free to talk to our experts for further guidance. </p>
                     <p class="text-left mt-2 mb-0"> 
                        <button class="btn btn-theme mb-2 border-0" [routerLink]="['/selectlocation']" [queryParams]="{ category: 'soap-stone'}">VIEW SOAP STONE PRODUCTS<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>