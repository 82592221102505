import { Component } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { fadeAnimation, stepper } from '../animations';
import { SeoServiceService } from './service/seo-service.service'
import {  SharedService} from './service/index';
declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations:[fadeAnimation, stepper]
})
export class AppComponent {
  title = 'IGM Surfaces';
  currenturl ;
  constructor(private router: Router, private activatedRoute:ActivatedRoute,
    private _seoService :SeoServiceService, public shared: SharedService) {
     this.currenturl = window.location.pathname;
   
  }
  ngAfterViewInit() {
     
    window.onscroll = function() {myFunction()};
    $(document).ready(function(){
      let pathname = window.location.pathname;
      var header = document.getElementById("igm-body");
      if(pathname.indexOf("/quartz/") != -1) {
        header.classList.add("noSticky");
      } else {
        header.classList.remove("noSticky");

      }
      
     
    });
    var header = document.getElementById("headeDesign");
    var sticky =  header ? header.offsetTop : null;
    
    function myFunction() {
      if(header != null) {
        if (window.pageYOffset > sticky) {
          // let x = header.classList.findIndex(f => f == 'sticky' )
          header.classList.add("sticky");
          $('.slider-heading-product').addClass('stick');
          $('.filterbtn').removeClass('filterSearch');

        } else {
          header.classList.remove("sticky");
          $('.slider-heading-product').removeClass('stick');
           $('.filterbtn').addClass('filterSearch');
        }
      }
    }
    
    
    (function ($) {
      $(function () {
        $(document).off('click.bs.tab.data-api', '[data-hover="tab"]');
        $(document).on('mouseenter.bs.tab.data-api', '[data-toggle="tab"], [data-hover="tab"]', function () {
          $(this).tab('show');
        });
      });
    })(jQuery);
  }

  changeOfRoutes() {
    this.ngAfterViewInit()
    // console.log('route chamgeed')
    // this.router.events.pipe(
    //   filter((event) => event instanceof NavigationEnd),
    //   map(() => this.activatedRoute),
    //   map((route) => {
    //     while (route.firstChild) route = route.firstChild;
    //     return route;
    //   }),
    //   filter((route) => route.outlet === 'primary'),
    //   mergeMap((route) => route.data)
    //  )
    //  .subscribe((event) => {
    //    this._seoService.updateTitle(event['title']);
    //   //  this._seoService.updateOgUrl(event['ogUrl']);
    //    //Updating Description tag dynamically with title
    //   //  this._seoService.updateDescription(event['title'] + event['description'])
    //  }); 
  }




}
