import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, SharedService, SeoServiceService } from '../service/index'


declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  projects ;
  selectedProject
  projectListdata= [
    {   
   Company:  'EGM - Paterson NJ',
   Quantity:  '100 slabs',
   Material:  '3cm Pebble Beach Quartz',
   Project:  'Hotel Kitchens and Bathrooms NJ',
   desc: `One of our clients in New Jersey was looking for a durable and beautiful material to revamp their hotel kitchens and bathrooms. And as kitchen and bathroom spaces have high footfall, a quartz slab serves as the ideal choice due to its non-slippery nature, scratch & stain resistance, and easy maintenance properties. So, our expert team suggested various products from our broad range of quartz collection, and the product that stood out for them was the wonderful white-colored Pebble Beach quartz. Keeping up with the style statement of the hotel, this quartz added a whole new dimension to its overall aesthetics.`
    },
   {
   Company:  'Tile Bar',
   Quantity:  'Approximately 7,200/sf mixed of all 3 materials',
   Fabricator:  'Faithful Countertop - Paterson NJ',
   Material:  '5cm Steel Grey Flamed/ 5cm Black Absolute Flamed/ 5cm Black Pearl Flamed',
   Project: ' Outside of Building Staten Island NY',
   desc:`For a project located outside of Building Staten Island in New York, our team of professionals analyzed its
    specific needs and chose to go ahead with a combination of three dark-hued materials, namely Steel Grey,
     Black Absolute, and Black Pearl. As these materials were intended for the exterior setting,
      a flamed finish was applied to each of these materials as slip-resistance is extremely crucial in outdoor applications.
    They have not only enhanced the value of the building’s outdoor space but also its durability.`
   },
   
   {Company:  'EMM - Newark NJ',
   Quantity:  '35 slabs',
   Material:  '3cm White Zen quartz 135x77 Jumbo',
   Project:  'Apartment Building NJ',
   desc:`We, at IGM SURFACES, come with several years of experience and expertise in designing and remodeling residential projects.
    To cater to the customized requirements for an apartment building project in New Jersey, we showed the client a wide range of quartz products available at our showroom. Considering the exquisite beauty, pristine white color, and durable properties of quartz, they chose the White Zen quartz slab for multiple indoor/outdoor applications for their apartment building. `
  },
   
  //  {Company:  'Elite Kitchen & Bath - Clifton NJ',
  //  Material:  '3cm White Lace Quartz',
  //  Quantity:  '10 slabs' ,
  //  Project:  'Apartment Building NJ'},
   
   {Company:  'Allstiles - Monroe NY',
   Quantity:  '30 slabs',
   Material:  '3cm Calacatta Oro Quartz',
   Project: 'Apartment Buildings NY',
   desc:`For one of our apartment buildings project in New York, we walked the client through an exclusive range of quartz products available at our NY-based showroom. Based on their custom requirements,
    they picked the Calacatta Oro quartz slab as it perfectly suited their unique design scheme. Being a phenomenal stone choice, its characteristic white backdrop featuring veining patterns helped add a sense of depth and style to these apartment buildings. `
  },
  //  {
  //  Company:  'Barraco Marble Granite - Chestnut Ridge NY',
  //  Material:  '3cm Steel Grey DF + 3cm White Lace',
  //  Quantity:  '15 slabs Steel Grey', //. 10 slabs White Lace,
  //  Project: 'Apartment Building NY'}

   ];

  constructor(private api: ApiService, private shared: SharedService,
    private activatedRoute: ActivatedRoute,private router: Router, private _seoService: SeoServiceService) { 
      this.activatedRoute.fragment.subscribe( fragment =>{
        let items = ['residential', 'commercial'];
        let x  = items.findIndex(f => f == fragment);
        if(x != -1) {
          setTimeout(() => {
            
            document.getElementById(fragment).click();
          }, 500);
        }
      })
      this.getCategory();
      this.getProjects();
      this.shared.cleardata();
      this.getSeoData();
    

  }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    $(document).ready(function() {  

      $('.slider4').owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        //animateOut: 'flipOutX',
        animateIn: 'fadeInRight',
        smartSpeed: 450,
        //default settings:
        autoplay: true,
        autoplayTimeout: 3500,
        nav: false,
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });
      
      $('.product-carousel').owlCarousel({
        loop: true,
        margin: 20,
        //default settings:
        autoplay: true,
        nav: true,
        autoplayTimeout: 3500,
        autoplayHoverPause: false,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1
          },
          450: {
            items: 2,
            margin: 10
          },
          768: {
            items: 3
          },
          1000: {
            items: 5
          },
          1200: {
            items: 6
          }
        }
      });
      
     
      $('.filters ul li').click(function(){
        $('.filters ul li').removeClass('active');
        $(this).addClass('active');
        
        var data = $(this).attr('data-filter');
        $grid.isotope({
          filter: data
        })
      });
      
      var $grid = $(".grid").isotope({
        itemSelector: ".all",
        percentPosition: true,
        masonry: {
          columnWidth: ".all"
        }
      });
  
  
  
    })

    $(document).ready(function(){

      $(".filter-button").click(function(){
         $(this).addClass('active');
          var value = $(this).attr('data-filter');
          
          if(value == "all")
          {
              //$('.filter').removeClass('hidden');
              $('.filter').show('1000');
             
          }
          else
          {
  //            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
  //            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
              $(".filter").not('.'+value).hide('3000');
              $('.filter').filter('.'+value).show('3000');
          
              
          }
      });
      
      $('.filter-button').removeClass('active');
  
      
  
  
      $(".filter-button2").click(function(){
         $(this).addClass('active');
          var value = $(this).attr('data-filter');
          
          if(value == "all")
          {
              //$('.filter').removeClass('hidden');
              $('.filter2').show('1000');
             
          }
          else
          {
  //            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
  //            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
              $(".filter2").not('.'+value).hide('3000');
              $('.filter2').filter('.'+value).show('3000');
          
              
          }
      });
      
      $('.filter-button2').removeClass('active');
  
  });
    $( '.modal-backdrop fade show').on('click', function(event) {
      $(".ekko-lightbox modal fade in show").each(function(){
        // $("#lightbox").hide();
        // Test if the div element is empty
        console.log('click runnn')
        $(this).hide();
        // if($(this).is(":empty")){
        // }
      });
    });
    
  }

  getCategory() {
    this.api.fetchData('product_category',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }
  getProjects() {
    this.api.fetchData('projects',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.projects = res['data']
        }
      }
    )
  }

  getSeoData(){
    let patname =  window.location.pathname
    patname = patname.slice(0, 0) + patname.slice(1);

    this.api.fetchDatalive('seodata',{page_name: 'projects'},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
        this._seoService.updateTitle(res['data'].title);
        this._seoService.updateDescription(res['data'].description)
  //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }

  onClickBookAppoimnent(){
    localStorage.setItem('page' , "Project");
    this.router.navigate(['/book-appointment'])
    // [routerLink]="['../book-appointment']"
  }


}
