<section class="partners-logo">
   <div class="container">
      <div class="row justify-content-center">
         <div class="col-md-12 text-center">
            <picture>
               <source srcset="{{tinyUrl}}/partner/m-multi-partner.png" media="(max-width: 767px)">
               <img src="{{tinyUrl}}/partner/multi-partner.png" class="img-fluid" alt="partner" />
            </picture>
         </div>
      </div>
   </div>
</section>
<footer class="py-4 section footer-classic context-dark shadow">
   <div class="container">
      <div class="row mb-0 align-items-center">
         <div class="mb-3 mb-md-0 col-md-8">
            <a class="brand" routerLink="/" href="javascript:void(0)"><img id="img-id-footer"
                  class="mx-0 brand-logo-light" src="{{tinyUrl}}/igm-logo.png" alt="" width="300"></a>
            <div class="copy-add">
               <p class="mb-0 mt-3">Home Office: {{address}}</p>
               <!-- <i class="fa fa-clone" [ngClass]="{'active': copyTextflag}" (click)="copyToClip()" aria-hidden="true"
                  title="Copy"></i>
               <span class="text-c-tool " *ngIf="copyTextflag"> <i class="arrow left"></i>Copied</span> -->
            </div>
            <div class="mt-2 row">
               <div class="col-md-12 col-xl-12">
                  <ul class="nav-list">
                     <li><a href="javascript:void(0)" routerLink="/">Home Page
                        </a>
                     </li>
                     <li><a href="javascript:void(0)" [routerLink]="['company/']">About Us
                        </a>
                     </li>
                     <li><a routerLink="contct" href="javascript:void(0)" [routerLink]="['contact/']">Contact Us
                        </a>
                     </li>
                     <li><a href="javascript:void(0)" [routerLink]="['company']" [fragment]="'Professionals'">For Design
                           Professionals
                        </a>
                     </li>
                     <li><a [routerLink]="['privacypolicy/']"> Privacy Policy</a></li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="mb-3 mb-md-0 col-md-4">
            <ul class="social-network nav">
               <li><a class="waves-effect waves-dark" target="_blank" href="https://www.facebook.com/IGMWindsor/"><i
                        class="fa fa-facebook"></i></a></li>
               <li><a class="waves-effect waves-dark" target="_blank" href="https://vimeo.com/40376116"><i
                        class="fa fa-youtube"></i></a></li>
            </ul>
            <h6 class="mt-3">GET THE IGM APP</h6>
            <ul class="nav-list">
               <li class="bg-transparent px-0 text-left row mx-0">
                  <a href="https://apps.apple.com/us/app/igm-surfaces/id1511126501" target="_blank"
                     class="text-left col-6 px-0"><img class="brand-logo-light img-fluid" src="{{tinyUrl}}/ios.png"
                        alt="">
                  </a><a href="https://play.google.com/store/apps/details?id=com.igmcorp" target="_blank"
                     class="text-left col-6 px-0"><img class="brand-logo-light img-fluid"
                        src="{{tinyUrl}}/playstore.png" alt="">
                  </a>
               </li>
            </ul>
         </div>
      </div>
   </div>
</footer>