<app-header *ngIf="currenturl != '/admin/allproducts'"></app-header>
<!-- <div class="container-fluid"  [@stepper]="o.isActivated ? o.activatedRoute : ''">
  <router-outlet #o="outlet"></router-outlet>
</div> -->
<div class="content d-none" role="main">
  <span>{{ title }} app is running!</span>
</div>
<router-outlet (activate)="changeOfRoutes()"></router-outlet>
<app-footer  *ngIf="currenturl != '/admin/allproducts'"></app-footer>
<app-cookies *ngIf="currenturl != '/admin/allproducts'"></app-cookies>
<!-- <div class="container-fluid footer-container">

</div> -->
