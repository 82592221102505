<div id="cookies-eu-banner" class="shadow-lg align-items-center justify-content-center" *ngIf="shared.showCookieMsg"  data-wait-remove="1200">
    <div class="d-block d-md-flex align-items-center justify-content-center">
      <div class="cookies-inner">
       <h3>
         Cookie Control
       </h3>
      <p>
         We use the cookies necessary for the website's basic functioning. We ensure that you get the best experience while you continue browsing this site.  
      </p>
    </div>
   <div class="ib mt-2 mt-mb-0">
     <!-- <button id="cookies-eu-reject">Ablehnen</button> -->
     <button type="button" class="btn btn-dark" id="cookies-eu-accept" (click)="allowsCookies()">Allow Cookies  </button>
   </div>
    </div>
 </div>