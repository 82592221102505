import { Component, OnInit } from '@angular/core';
import { SharedService, ApiService, SeoServiceService } from '../service/index';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-selectlocation',
  templateUrl: './selectlocation.component.html',
  styleUrls: ['./selectlocation.component.css']
})
export class SelectlocationComponent implements OnInit {
  tinyUrl2= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';  
  location = [];
  cat;
  loadMore;
  constructor(private api: ApiService ,private router: Router,
    private route: ActivatedRoute, private shared: SharedService, private _seoService: SeoServiceService) { 
    this.getLocation();
    this.getCategory();
    this.loadMore = true;
    this.route
    .queryParams
    .subscribe(params => {
      // Defaults to 0 if no query param provided.
      this.cat = params['category'];
      let patname =  'selectlocation'
      // patname = patname.slice(0, 0) + patname.slice(1);
      if(this.cat != undefined) {
        patname = patname + '?category='+this.cat 
      }
      this.getSeoData(patname);
      });
  }

  ngOnInit(): void {
  }

  selectlocation(value){
    localStorage.setItem('location',value);
    if(this.cat ){
      this.router.navigate(['/'+value+ '/'+this.cat ]);
    } else {
      this.router.navigate(['/'+value]);

    }
  }

  getLocation() {
    this.loadMore = true;
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
          this.loadMore = false;
        } else {
          this.loadMore = false;
          this.location =  [];

        }
      }
    )
  }

  getCategory() {
    this.api.fetchData('product_category',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }

  getSeoData(patname){

    this.api.fetchDatalive('seodata',{page_name: patname},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
        this._seoService.updateTitle(res['data'].title);
        this._seoService.updateDescription(res['data'].description)
  //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }


}
