import { Component, OnInit,  PLATFORM_ID } from '@angular/core';
import { SharedService} from '../service/index';
import { isPlatformBrowser } from '@angular/common';
import { Inject } from '@angular/core';

declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {
  isBrowser: boolean;

  constructor(public shared: SharedService,  @Inject(PLATFORM_ID) private platformId,) { 
    this.isBrowser = isPlatformBrowser(platformId) ?isPlatformBrowser(platformId): false;


  }

  ngOnInit(): void {
    if(this.isBrowser) {
      this.checkCookie()   
    }
  }

  allowsCookies() {
    let todaydate = new Date();
    let expdate = 30

    this.setCookie('cookieacceptence','true',expdate )
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    // console.log(exdays);
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;secure:true;httpOnly:true;";
    this.shared.showCookieMsg = false;
  }

  getCookie(cname) {
    var name = cname + "=";
    console.log( document.cookie);
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
    
  }

  checkCookie() {
      let value = this.getCookie('cookieacceptence');
      if(value != null && value != '') {
        // console.log(value);
        
      } else {
        this.shared.showCookieMsg = true;
      }
  }
  




}
