<main>
   <section class="impress_area pb-5">
      <div class="container py-md-5 pt-3 pb-4">
         <div class="large-title text-center">
            <h2 class="mb-0">Book an appointment</h2>
            <!-- <p>Book your Customer appointment.</p> -->
         </div>
         <div class="card shadow">
            <div class="card-body">
               <form [formGroup]="apponitmentForm" 
               (ngSubmit)="onsubmit(apponitmentForm.value)"  ngNativeValidate class="w-100">
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label for="name">Your Name *</label>
                     <input type="text" name="name" class=" form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.name.errors }" formControlName="name">
                     <div *ngIf="submitted && g.name.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.name.errors.required">Name is required</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label for="email">Your Email *</label>
                     <input type="email" class=" form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.email.errors }" formControlName="email">
                     <div *ngIf="submitted && g.email.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.email.errors.required">Email is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label for="name">Phone *</label>
                     <input type="text" name="name" class=" form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.phone.errors }" formControlName="phone">
                     <div *ngIf="submitted && g.phone.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.phone.errors.required">Phone is required</div>
                        <div *ngIf="g.phone.errors.pattern"> Only Number  is required</div>
                        <div *ngIf="g.phone.errors.minlength">Minimum 10 digit is required.</div>
                        <div *ngIf="g.phone.errors.maxlength">Maximum 13 digits allowed.</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label for="city">City </label>
                     <input type="text" class=" form-control bg-transparent"
                        formControlName="city">
                     <!-- [ngClass]="{ 'is-invalid': submitted && g.city.errors }" formControlName="city"> -->
                     <!-- <div *ngIf="submitted && g.city.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.city.errors.required">City is required</div>
                        </div> -->
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-4 form-group">
                     <label for="city">Warehouse *</label>
                     <select  class="form-control bg-transparent" formControlName="location"
                        [ngClass]="{ 'is-invalid': submitted && g.location.errors }" >
                        <option  value="" class="text-dark">Please select Warehouse</option>
                        <option  *ngFor="let item of location" value="{{item.city_slug}}"> {{item.city_name}}</option>
                     </select>
                     <div *ngIf="submitted && g.location.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.location.errors.required">Warehouse is required</div>
                     </div>
                  </div>
                  
                  <div class="col-md-4 ">
                     <div class=" form-group " >
                        <label for="city">Date *</label>
                        <div class="input-group mb-3">
                           <input class="form-control"  formControlName="date_time" placeholder="mm-dd-yyyy" [markDisabled]="markDisabled"
                           name="dp" ngbDatepicker [minDate]="mindate" #d="ngbDatepicker" readonly  [ngClass]="{ 'is-invalid': submitted && g.date_time.errors }" >
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                           </div>
                           <div *ngIf="submitted && g.date_time.errors" class="invalid-feedback alert alert-danger py-1 px-3 mt-1">
                              <div *ngIf="g.date_time.errors.required">Date  is required</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-4  form-group">
                     <label for="city">Time *</label>
                     <!-- <ngx-material-timepicker #range></ngx-material-timepicker>
                     <input placeholder="" class="form-control"   [ngClass]="{ 'is-invalid': submitted && g.time.errors }"  
                     formControlName="time" aria-label="24hr format" [format]="24"  [ngxTimepicker]="range" min="8:00 am" max="5:00 pm" readonly> -->
                     <div class="ngx-timepicker-field-example">
                        <ngx-timepicker-field [format]="24" min="8:00 am" max="5:00 pm" formControlName="time"  ></ngx-timepicker-field>
                    </div>
                     <div *ngIf="submitted && g.time.errors"  class="invalid-feedback alert alert-danger py-1 px-3 mt-1">
                        <!-- <div *ngIf="g.time.errors.required"> Time is required</div> -->
                        Time is required
                     </div>
                  </div>
               </div>
               <div class="form-group">
                  <label for="message">Message *</label>
                  <textarea class=" form-control bg-transparent"
                     [ngClass]="{ 'is-invalid': submitted && g.message.errors }" formControlName="message"></textarea>
                  <div *ngIf="submitted && g.message.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                     <div *ngIf="g.message.errors.required">Message is required</div>
                  </div>
               </div>
               <div *ngIf="message" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center alert alert-success">
                  <div class=" py-2 px-5 text-center">
                     <p id="res_message mt-2 text-center" *ngIf="message" class=""> {{message}} </p>
                  </div>
               </div>
               <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                     <div class="d-flex align-items-center">
                        <img src="{{tinyUrl}}/loader.svg" class="img-fluid" width="50" /> 
                        <p class="mb-0 mt-1 mr-3">Loading...</p>
                     </div>
                  </div>
               </div>
               <div class="text-center d-flex justify-content-center">
                  <button class="d-flex align-items-center shadow btn btn-theme py-3 px-5 d-flex">Submit  <i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
               </div>
               </form>
            </div>
         </div>
      </div>
   </section>
</main>