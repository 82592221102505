import { Component, OnInit } from '@angular/core';
import { SharedService, ApiService, SeoServiceService } from '../service/index';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  tinyUrl2= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';  
  location = [];
  loadMore
  constructor(private api: ApiService,private router: Router, private shared: SharedService, private _seoService: SeoServiceService) { 
    this.getLocation();
    this.loadMore =  true;
    this.getCategory();
    this.getSeoData();
  }

  ngOnInit(): void {
  }
  
  selectlocation(value){
    // debugger;
    if(value != 'indore') {
      localStorage.setItem('location',value);
      this.router.navigate(['/'+value]);

    }

  }

  getLocation() {
    this.loadMore =  true;
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
          this.loadMore =  false;
        } else {
          this.location =  [];
          this.loadMore =  false;
        }
      }
    )
  }

  getCategory() {
    this.api.fetchData('product_category',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }

  getSeoData(){
    let patname =  this.router.url;
    patname = patname.slice(0, 0) + patname.slice(1);

    this.api.fetchDatalive('seodata',{page_name: patname},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
        this._seoService.updateTitle(res['data'].title);
        this._seoService.updateDescription(res['data'].description)
  //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }


}
