import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, SharedService, SeoServiceService} from '../service/index'
declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  constructor(private api: ApiService, private shared: SharedService,
    private activatedRoute: ActivatedRoute,private router: Router, private _seoService: SeoServiceService) { 
      this.activatedRoute.fragment.subscribe( fragment =>{
        let items = ['Fabricators', 'Kitchen', 'Designer', 'Architect'];
        let x  = items.findIndex(f => f == fragment);
        if(x != -1) {
          setTimeout(() => {
            
            document.getElementById(fragment).click();
          }, 500);
        }
      })
    this.shared.cleardata();

    this.getCategory();
    this.getSeoData();
   }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    $(document).ready(function() {
      $('.slider4').owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        //animateOut: 'flipOutX',
        animateIn: 'fadeInRight',
        smartSpeed: 450,
        //default settings:
        autoplay: true,
        autoplayTimeout: 3500,
        nav: false,
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });
  
      $('.slider5').owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        //animateOut: 'flipOutX',
        animateIn: 'fadeInRight',
        smartSpeed: 450,
        //default settings:
        autoplay: true,
        autoplayTimeout: 3500,
        nav: false,
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });
    
    
    })
  }

  getCategory() {
    this.api.fetchData('product_category',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }
  getSeoData(){
    let patname =  window.location.pathname
    patname = patname.slice(0, 0) + patname.slice(1);

    this.api.fetchDatalive('seodata',{page_name: 'company'},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
        this._seoService.updateTitle(res['data'].title);
        this._seoService.updateDescription(res['data'].description)
  //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }


}
