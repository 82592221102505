<div class="headeDesign shadow-lg" id="headeDesign">
   <nav class="p-0 navbar navbar-expand-lg navbar-light mx-background-top-linear">
      <div class="container-fluid pl-0">
         <a class="navbar-brand p-0"  routerLink="" href="javascript:void(0)"  style="text-transform: uppercase;"><img src="{{tinyUrl}}/igm-logo.png" id="img-id" class="img-fluid" width="250" (click)="mobileToggle()"></a>
         <button class="navbar-toggler mr-3" type="button" data-toggle="collapse" data-target="#collapsingNavbar" id="togglebtn" (click)="appendBodyCls()">
         <span class="navbar-toggler-icon"></span>
         </button>
         <button class="btn d-none" type="button" id="main-logo-head"></button>
         <div class="navbar-collapse collapse" id="collapsingNavbar">
            <ul class="nav navbar-nav ml-auto">
               <li class="dropdown mob-droupdown">
                  <a class="py-2 nav-link px-lg-3 py-lg-3 px-lg-2 text-dark arrow-toggle" [routerLink]="['products/']" href="javascript:void(0)" >Product<span class="caret"></span></a>
                  <button class="mob-toggle btn btn-link px-4" ><i class="fa fa-caret-down text-dark" aria-hidden="true"></i></button>
                  <ul class="dropdown-large dropdown-menu dropdownhover-bottom animated fadeInDown pb-5 overScroll" role="menu">
                     <li>
                        <div class="d-flex flex-wrap flex-md-nowrap">
                           <div class="min-col col-md-6 col-sm-6 col-12">
                              <h6 class="title">Our Products</h6>
                              <div class="row mx-0 h-100" *ngIf="this.shared.categoryList">
                                 <div [ngClass]="{'border-0 mb-0':item.category_slug == 'quartz'}" class="col-md-12 card mb-2 px-0" *ngFor="let item of this.shared.categoryList">
                                    <div class="row no-gutters h-100">
                                    
                                       <a (click)="mobileToggle()" *ngIf="!shared.selectedlocation && item.category_slug == 'quartz'" routerLink="/{{item.category_slug}}"    href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">

                                          <div class="border-0 col-md-12 px-0 noOtherImg">
                                             <div class="">
                                             <img src="{{tinyUrl2}}/COALESCE_LOGO.png" class="img-fluid">
                                             </div>
                                          </div>

                                          <div class="cardfix1">
                                             <img *ngIf="item.image" src="{{tinyUrl}}/products/{{item.image}}">
                                             <img *ngIf="!item.image" src="{{tinyUrl}}/placeholder-rec.jpg">
                                          </div>
                                       </a>
                                       <a (click)="mobileToggle()" *ngIf="!shared.selectedlocation && item.category_slug != 'quartz'" routerLink="/selectlocation"  [queryParams]="{ category: item.category_slug}"  href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">                                      
                                       <div class="cardfix1">
                                          <img *ngIf="item.image" src="{{tinyUrl}}/products/{{item.image}}">
                                          <img *ngIf="!item.image" src="{{tinyUrl}}/placeholder-rec.jpg">
                                       </div>
                                       <span class="" 
                                       [ngClass]="{'headcoalDetail':item.category_slug == 'quartz'}"> 

                                          {{item.category_name}}
                                       </span>
                                       </a>
                                       <a (click)="mobileToggle()" *ngIf="shared.selectedlocation  && item.category_slug == 'quartz'" routerLink="/{{item.category_slug}}"  href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">
                                          <div class="border-0 col-md-12 px-0 noOtherImg">
                                             <div class="">
                                             <img src="{{tinyUrl2}}/COALESCE_LOGO.png" class="img-fluid">
                                             </div>
                                          </div>

                                          
                                          <div class="cardfix1">
                                          <img *ngIf="item.image" src="{{tinyUrl}}/products/{{item.image}}">
                                          <img *ngIf="!item.image" src="{{tinyUrl}}/placeholder-rec.jpg">
                                          </div>                                      
                                       
                                       </a>
                                       <a (click)="mobileToggle()" *ngIf="shared.selectedlocation  && item.category_slug != 'quartz'" routerLink="/{{ shared.selectedlocation}}/{{item.category_slug}}"  href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">
                                       <div class="cardfix1">
                                          <img *ngIf="item.image" src="{{tinyUrl}}/products/{{item.image}}">
                                          <img *ngIf="!item.image" src="{{tinyUrl}}/placeholder-rec.jpg">
                                       </div>
                                       <span class="" 
                                       [ngClass]="{'headcoalDetail':item.category_slug == 'quartz'}"> 

                                          {{item.category_name}}
                                       </span>
                                       </a>
                                    </div>
                                 </div>
                                 
                              </div>
                           </div>
                           <div class="min-col col-md-6 col-sm-6 col-12">
                              <h6 class="title">New Arrival
                              </h6>
                              <div class="row mx-0 h-100">
                                 <div class="col-md-12 card border-0 px-0">
                                    <div class="row no-gutters h-100" *ngIf="this.shared.newArival">

                                       <ng-container *ngFor="let item of this.shared.newArival; let i = index" >
                                          <div class="col-md-12 d-flex align-items-center bgRec_1 mb-1"  *ngIf="i < 4">
                                             <a (click)="mobileToggle()" class="p-0" href="javascript:void(0)"   routerLink="/{{item.category_slug}}/{{item.productSlug}}"  >
                                             <img  *ngIf="item.thumbnail"  src="{{item.thumbnail}}"  height="150">
                                             <img *ngIf="!item.thumbnail"  src="{{tinyUrl}}/placeholder-rec.jpg"  height="150">
                                             <span>{{item.ProductName}}</span>
                                             </a>
                                          </div>

                                       </ng-container>

                                       <span class="d-none"></span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <!-- end col-3 -->
                        </div>
                     </li>
                  </ul>
               </li>
               <li class="dropdown mob-droupdown">
                  <a  href="javascript:void(0)"  class="py-2 nav-link px-lg-3 py-lg-3 px-lg-2 text-dark arrow-toggle"  [routerLink]="['projects/']"  
                     >Projects<span class="caret"></span></a>
                  <button class="mob-toggle btn btn-link px-4" ><i class="fa fa-caret-down text-dark" aria-hidden="true"></i></button>
                  <ul class="dropdown-large dropdown-menu dropdownhover-bottom animated fadeInDown" role="menu">
                     <li>
                        <div class="d-flex flex-wrap flex-md-nowrap">
                           <div class="min-col col-md-6 col-sm-6 col-12">
                              <h6 class="title">Residential</h6>
                              <div class="row mx-0">
                                 <div class="col-md-12 card mb-2 px-0">
                                    <div class="row no-gutters">
                                       <a [routerLink]="['projects']"  [fragment]="'residential'" href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">                                      
                                       <div class="cardfix1">
                                          <img src="{{tinyUrl}}/slider/1.jpg">
                                       </div>
                                       Kitchen
                                       </a>
                                    </div>
                                 </div>
                                 <div class="col-md-12 card mb-2 px-0">
                                    <div class="row no-gutters">
                                       <a [routerLink]="['projects']"  [fragment]="'residential'" href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">                                      
                                       <div class="cardfix1">
                                          <img src="{{tinyUrl}}/slider/2.jpg">
                                       </div>
                                       Bathroom
                                       </a>
                                    </div>
                                 </div>
                                 <div class="col-md-12 card mb-2 px-0">
                                    <div class="row no-gutters">
                                       <a [routerLink]="['projects']"  [fragment]="'residential'" href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">                                      
                                       <div class="cardfix1">
                                          <img src="{{tinyUrl}}/newColor/1.png">   
                                       </div>
                                       Living Space
                                       </a>
                                    </div>
                                 </div>
                                 <div class="col-md-12 card mb-2 px-0">
                                    <div class="row no-gutters">
                                       <a [routerLink]="['projects']"  [fragment]="'residential'" href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">                                      
                                       <div class="cardfix1">
                                          <img src="{{tinyUrl}}/newColor/2.png">
                                       </div>
                                       Bedroom
                                       </a>
                                    </div>
                                 </div>
                                 <div class="col-md-12 card mb-2 px-0">
                                    <div class="row no-gutters">
                                       <a [routerLink]="['projects']"  [fragment]="'residential'" href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">                                      
                                       <div class="cardfix1">
                                          <img src="{{tinyUrl}}/newColor/1.png">
                                       </div>
                                       Landry Room
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <!-- end col-3 -->
                           <div class="min-col col-md-6 col-sm-6 col-12">
                              <h6 class="title">Commercial</h6>
                              <div class="row mx-0">
                                 <div class="col-md-12 card mb-2 px-0">
                                    <div class="row no-gutters" >
                                       <a [routerLink]="['projects']"  [fragment]="'commercial'" href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">                                      
                                       <div class="cardfix1">
                                          <img src="{{tinyUrl}}/newColor/1.png">
                                       </div>
                                       Reception
                                       </a>
                                    </div>
                                 </div>
                                 <div class="col-md-12 card mb-2 px-0" >
                                    <div class="row no-gutters">
                                       <a [routerLink]="['projects']"  [fragment]="'commercial'" href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">                                      
                                       <div class="cardfix1">
                                          <img src="{{tinyUrl}}/newColor/2.png">
                                       </div>
                                       Lobby
                                       </a>
                                    </div>
                                 </div>
                                 <div class="col-md-12 card mb-2 px-0" >
                                    <div class="row no-gutters">
                                       <a [routerLink]="['projects']"  [fragment]="'commercial'" href="javascript:void(0)" class="py-0 col-md-12 d-flex align-items-center">                                      
                                       <div class="cardfix1">
                                          <img src="{{tinyUrl}}/newColor/1.png">
                                       </div>
                                       Waiting Area
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <!-- end col-3 -->
                        </div>
                     </li>
                  </ul>
               </li>
               <li class="dropdown mob-droupdown">
                  <a   href="javascript:void(0)" [routerLink]="['customerService']" class="py-2 nav-link px-lg-3 py-lg-3 px-lg-2 text-dark arrow-toggle">Customer Service <span class="caret"></span></a>
                  <button class="mob-toggle btn btn-link px-4" ><i class="fa fa-caret-down text-dark" aria-hidden="true"></i></button>
                  <ul class="dropdown-large dropdown-menu dropdownhover-bottom animated fadeInDown" role="menu">
                     <li>
                        <div class="col-12 px-0 d-flex flex-wrap flex-md-nowrap">
                           <div class="min-col col-md-6 col-sm-6 mb-2 px-2">
                              <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0" href="javascript:void(0)"   [routerLink]="['location/']">
                                 <div class="mb-1 text-center">
                                    <i class="fa fa-map-marker fa-2x"></i>
                                 </div>
                                 Location / Where to Buy
                              </a>
                           </div>
                           <div class="min-col col-md-6 col-sm-6 mb-2 px-2">
                              <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0" href="javascript:void(0)"   [routerLink]="['contact/']">
                                 <div class="mb-1 text-center">
                                    <i class="fa fa-phone fa-2x"></i>
                                 </div>
                                 Contact
                              </a>
                           </div>
                        </div>
                       
                        <div class="col-12 px-0 d-flex flex-wrap flex-md-nowrap">
                        
                           <div class="min-col col-md-6 col-sm-6 mb-2 px-2">
                              <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0" [routerLink]="['customerService']"  [fragment]="'care'">
                              <div class="mb-1 text-center">
                                 <i class="fa fa-headphones fa-2x"></i>
                              </div>
                              Care and Cleaning
                              </a>
                           </div>
                           <div class="min-col col-md-6 col-sm-6 mb-2 px-2">
                              <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0"[routerLink]="['customerService']"  [fragment]="'faq'" >
                              <div class="mb-1 text-center">
                                 <i class="fa fa-question fa-2x"></i>
                              </div>
                              FAQ
                              </a>
                           </div>
                        </div>
                     <div class="col-12 px-0 d-flex flex-wrap flex-md-nowrap">
                         <div class="min-col col-md-12 col-sm-12 mb-2 px-2">
                           <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0" [routerLink]="['book-appointment']">
                              <div class="mb-1 text-center">
                                 <i class="fa fa-newspaper-o fa-2x text-dark"></i>
                              </div>
                              <div class="fontSt1 text-dark"     href="javascript:void(0)">Book An Appointment
                              </div>
                           </a>
                        </div>
                     </div>
                        <!-- end row -->
                     </li>
                  </ul>
               </li>
               <li class="dropdown mob-droupdown">
                  <a  href="javascript:void(0)"   [routerLink]="['company']"   class="py-2 nav-link px-lg-3 py-lg-3 px-lg-2 text-dark arrow-toggle" >Company<span class="caret"></span></a>
                  <button class="mob-toggle btn btn-link px-4" ><i class="fa fa-caret-down text-dark" aria-hidden="true"></i></button>
                  <ul class="dropdown-large dropdown-menu dropdownhover-bottom animated fadeInDown" role="menu">
                     <li>
                        <div class="col-12 px-0 mb-2 d-flex flex-wrap flex-md-nowrap">
                           <div class="min-col col-md-6 col-sm-6 mb-0 px-2">
                              <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0" [routerLink]="['company']"  [fragment]="'story'"  href="javascript:void(0)">
                              <div class="mb-1 text-center">
                                 <i class="fa fa-book fa-2x"></i>
                              </div>
                              Our Story
                              </a>
                           </div>
                           <div class="min-col col-md-6 col-sm-6 mb-0 px-2">
                              <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0"  [routerLink]="['company']"  [fragment]="'careers'"   href="javascript:void(0)">
                              <div class="mb-1 text-center">
                                 <i class="fa fa-question fa-2x"></i>
                              </div>
                              Careers
                              </a>
                           </div>
                        </div>
                        <div class="col-12 px-0 d-flex flex-wrap flex-md-nowrap">
                           <div class="min-col col-md-12 col-12 px-2">
                              <a class="mt-3 col-12 text-center d-block pb-2">
                                 <div class="mb-1 text-center">
                                    <i class="fa fa-newspaper-o fa-2x text-dark"></i>
                                 </div>
                                 <div class="fontSt1 text-dark"  [routerLink]="['company']"  [fragment]="'Professionals'"  href="javascript:void(0)">Professionals
                                 </div>
                           </a>
                           <div class="mt-1 col-12 px-0 d-flex flex-wrap">
                              <div class="col-md-6 mb-2 px-2">
                                 <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0" [routerLink]="['company']"  [fragment]="'Architect'"  href="javascript:void(0)">
                                 <div class="mb-1 text-center">
                                    <img src="{{tinyUrl}}/icon/architect.svg" height="30">
                                 </div>
                                 Architect
                                 </a>
                              </div>
                              <div class="col-md-6 mb-2 px-2">
                                 <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0" [routerLink]="['company']"  [fragment]="'Designer'"  href="javascript:void(0)">
                                 <div class="mb-1 text-center">
                                    <img src="{{tinyUrl}}/icon/interior-design.svg" height="30">
                                 </div>
                                 Designer
                                 </a>
                              </div>
                           </div>
                           <div class="mt-1 col-12 px-0 d-flex flex-wrap">
                              <div class="col-12 px-0 d-flex flex-wrap flex-md-nowrap">
                                 <div class="col-md-6 mb-2 px-2">
                                    <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0" [routerLink]="['company']"  [fragment]="'Kitchen'"  href="javascript:void(0)">
                                    <div class="mb-1 text-center">
                                       <img src="{{tinyUrl}}/icon/kitchen.svg" height="30">
                                    </div>
                                    Kitchen and Bath Studios
                                    </a>
                                 </div>
                                 <div class="col-md-6 mb-2 px-2">
                                    <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0"  [routerLink]="['company']"  [fragment]="'Fabricators'"  href="javascript:void(0)">
                                    <div class="mb-1 text-center">
                                       <img src="{{tinyUrl}}/icon/fabric.svg" height="30">
                                    </div>
                                    Fabricators
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        </div>
                     </li>
                  </ul>
               </li>
               <li class="dropdown mob-droupdown" *ngIf="shared.selectedlocation && params != 'quartz'"   (mouseover)="showDivWithHoverStyles(1)" 
               (mouseout)="showAllDivsWithDefaultStyles()" >
                  <a  href="javascript:void(0)" *ngIf="!shared.selectedlocation" class="d-flex align-items-center py-2 nav-link px-lg-3 py-lg-3 px-lg-2 text-dark arrow-toggle  dropdown-toggle"
                     routerLink="/selectlocation" 
                     >
                  <i  class="fa fa-map-marker pr-2 fa-2x" aria-hidden="true"></i>Locations<span class="caret"></span>
                  </a>
                  <a href="javascript:void(0)" *ngIf="shared.selectedlocation"  class="d-flex align-items-center py-2 nav-link px-lg-3 py-lg-3 px-lg-2 text-dark arrow-toggle  dropdown-toggle"
                     routerLink="/{{shared.selectedlocation}}" 
                     >
                  <i *ngIf="!shared.selectedlocation " class="fa fa-map-marker pr-2 fa-2x" aria-hidden="true"></i>{{findLocationName(shared.selectedlocation)}}<span class="caret"></span>
                  </a>
                  <button class="mob-toggle btn btn-link px-4" (click)="hoveredDivId = !hoveredDivId" ><i class="fa fa-caret-down text-dark" aria-hidden="true"></i></button>
                  <ul class="dropdown-large dropdown-menu  dropdownhover-bottom animated fadeInDown" role="menu"     
                  [ngStyle]="hoveredDivId ===true ? hoveredDivStyles : defaultDivStyles">
                     <li>
                        <div class="d-flex flex-wrap flex-md-nowrap">
                           <div class="min-col col-md-6 col-sm-6 col-12">
                              <!-- <h6 class="title">Locations</h6> -->
                              <div class="row mx-0">
                                 <div class="col-md-12 card mb-2 px-0" *ngFor="let item of AlllocationList">
                                    <div class="row no-gutters" >
                                       <a  [ngClass]="{ 'a_disabled': item.city_slug == 'indore' }"  (click)="mobileToggle()" routerLink="/{{item.city_slug}}"  href="javascript:void(0)" 
                                          class="py-0 col-md-12 d-flex align-items-center">
                                          <div class="cardfix1">
                                             <img src="{{item.image}}">
                                          </div>
                                          {{item.city_name}}
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <!-- end col-3 -->
                           <!-- end col-3 -->
                        </div>
                     </li>
                  </ul>
               </li>
         </ul>
      </div>
</div>
</nav>
</div>