import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, SharedService, SeoServiceService } from '../service/index'
declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  categoryList = [];
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private api: ApiService, private shared: SharedService, private _seoService: SeoServiceService) { 
    this.shared.cleardata();
    this.getSeoData();
    this.getCategory();
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    $(document).ready(function() { 
      $('.slider4').owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        //animateOut: 'flipOutX',
        animateIn: 'fadeInRight',
        smartSpeed: 450,
        //default settings:
        autoplay: true,
        autoplayTimeout: 3500,
        nav: false,
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });
      
      $('.product-carousel').owlCarousel({
        loop: true,
        margin: 20,
        //default settings:
        autoplay: true,
        nav: true,
        autoplayTimeout: 3500,
        autoplayHoverPause: false,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1
          },
          450: {
            items: 2,
            margin: 10
          },
          768: {
            items: 3
          },
          1000: {
            items: 3
          },
          1200: {
            items: 6
          }
        }
      });
      
     
      $('.filters ul li').click(function(){
        $('.filters ul li').removeClass('active');
        $(this).addClass('active');
        
        var data = $(this).attr('data-filter');
        $grid.isotope({
          filter: data
        })
      });
      
      var $grid = $(".grid").isotope({
        itemSelector: ".all",
        percentPosition: true,
        masonry: {
          columnWidth: ".all"
        }
      });
    })

 
    
  }


  getCategory() {
    this.api.fetchData('product_category',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }

  getSeoData(){
    let patname =  window.location.pathname
    patname = patname.slice(0, 0) + patname.slice(1);

    this.api.fetchDatalive('seodata',{page_name: 'products'},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
        this._seoService.updateTitle(res['data'].title);
        this._seoService.updateDescription(res['data'].description)
  //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }

}
