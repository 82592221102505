import { Component, OnInit } from '@angular/core';
import { SharedService, ApiService, SeoServiceService } from '../service/index'
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor( private shared: SharedService, private api: ApiService, private _seoService: SeoServiceService) { 
    this.shared.cleardata();
    this.getCategory();
    this.getSeoData();

  }

  ngOnInit(): void {
  }
  getCategory() {
    this.api.fetchData('product_category',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }

  getSeoData(){
    let patname =  window.location.pathname
    patname = patname.slice(0, 0) + patname.slice(1);

    this.api.fetchDatalive('seodata',{page_name: 'privacypolicy'},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
        this._seoService.updateTitle(res['data'].title);
        this._seoService.updateDescription(res['data'].description)
  //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }

}
