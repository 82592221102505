 <main>
   <section class="py-5 bg-light">
      <div class="container px-4">
         <div class="row justify-content-center">
            <div class="col-xl-10 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">Select Location</h2>
               </div>
            </div>
         </div>
         <div class="row justify-content-center mt-3">
            <div class="col-lg-4 col-md-6 text-center" *ngFor="let item of location; let i = index">
               <div class="location-gridbox atlanta-grid mb-4">
                  <a  (click)="selectlocation(item.city_slug)" href="javascript:void(0)" 
                  class="img-shine col-12 col-xl-auto overflow-hd px-1 mb-2 flex-grow-1"  [ngClass]="{ 'a_disabled': item.city_slug == 'indore' }">
                     <img src="{{item.image}}" alt="" class="img-fluid">
                     <div class="addressBox">
                        <h4>{{item.city_name}}</h4>
                     </div>
                  </a>
               </div>
            </div>
            <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
               <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                     <div class="d-flex align-items-center">
                        <img src="{{tinyUrl2}}/loader.svg" class="img-fluid" width="50" /> <p class="mb-0 mt-1 mr-3">Loading...</p>
                     </div>
                  </div>
            </div>
         </div>
      </div>
   </section>
</main>