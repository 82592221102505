export const environment = {
  production: true,
  baseUrl: 'https://dev.slabs.igmcorp.net/api/v1/',
  cosmosgranitebaseUrl: 'https://cosmosgranite.com/api/',
  siteKey: '6LfBH_0ZAAAAAO-_knQytXdQohMUyPFZU2tJzbQm'

};
// export const environment = {
//   production: true,
//   baseUrl: 'https://slabs.igmsurfaces.com/api/v1/',
//   cosmosgranitebaseUrl: 'https://cosmosgranite.com/api/',
//   siteKey: '6LcyMQIaAAAAANMiCyaYt6i001_xGgWpuZ3HWTVL'

// };
