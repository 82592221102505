import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { searchForm } from '../forms'
import { SharedService, ApiService, SeoServiceService } from '../service/index';
import { Router, ActivatedRoute } from '@angular/router';


declare let $: any;
declare let jQuery: any;
@Component({
  selector: 'app-quartz',
  templateUrl: './quartz.component.html',
  styleUrls: ['./quartz.component.css']
})
export class QuartzComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  tinyUrl2= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';  
  
  searchForm = searchForm;
  flag;
  locationData;
  filters = [];
  selectedcat;
  selectedcatslug;
  more;
  counter = 0
  params;
  searchedPro;
  location_slug;
  cat_slug;
  filtersdata;
  loadMore = false;
  submitted: boolean= false;
  showFilter = false;
  quartsData
  cosmosFilter;
  view;

  constructor(private fb: FormBuilder, private api: ApiService, public shared: SharedService,
    private activatedRoute: ActivatedRoute,private router: Router, private _seoService:SeoServiceService ) { 
      this.getSeoData();
      this.flag = undefined;
      this.searchForm = this.fb.group({
        search: [null, ''],
      });  
      this.searchForm.patchValue({
        search:null
      })
      let data = this.router.url.split("/");
      localStorage.setItem('pro_catagory',data[1]);
      // localStorage.setItem('pro_catagory',data[2]);
      this.loadMore = false;
      this.location_slug = data[1];
      this.cat_slug = data[2];
      this.counter = 0;
      this.quartsData = undefined
      this.showFilter = false;
      this.cosmosFilter = undefined;
      this.getCategory();
      this.shared.selectedlocation = null;
      this.searchedPro = undefined;
      this.submitted = false
      this.filters = [];
      this.cosmosFilter = undefined;
      this.getAllQuartzFilter()
      this.view = '';
      if(this.shared.resetFilter== true){
        this.shared.resetFilter = undefined;
        this.showFilter = true;
        let data = localStorage.getItem('filtersDataQuartz');
        this.filters = (data && data.length >0) ?  JSON.parse(data): [];
        if(  this.filters.length > 0) {
        setTimeout(()=> {
            localStorage.removeItem("filtersDataQuartz");
            this.searchBycosmosfilter()
          },2000)
        }else {
          this.getQuartsProduct(0);
        }
      } else {
        this.getQuartsProduct(0);
      }
      if(this.shared.showFilter == true) {
        this.showFilter = true;
        this.shared.showFilter = false;
      }
    }
    

  ngOnInit(): void {
  }

 
  ngAfterViewInit(){
    $(document).ready(function() {   
      var sync1 = $(".slider");
      var sync2 = $(".navigation-thumbs");
      
      var thumbnailItemClass = '.owl-item';
      
      var slides = sync1.owlCarousel({
        video:true,
        startPosition: 12,
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        autoplayTimeout:6000,
        autoplayHoverPause:false,
        nav: false,
        dots: false
      }).on('changed.owl.carousel', syncPosition);
      
      function syncPosition(el) {
        var $owl_slider = $(this).data('owl.carousel');
        var loop = $owl_slider.options.loop;
      
        if(loop){
          var count = el.item.count-1;
          var current = Math.round(el.item.index - (el.item.count/2) - .5);
          if(current < 0) {
              current = count;
          }
          if(current > count) {
              current = 0;
          }
        }else{
           current = el.item.index;
        }
      
        var owl_thumbnail = sync2.data('owl.carousel');
        var itemClass = "." + owl_thumbnail.options.itemClass;
      
      
        var thumbnailCurrentItem = sync2
        .find(itemClass)
        .removeClass("synced")
        .eq(current);
      
        thumbnailCurrentItem.addClass('synced');
      
        if (!thumbnailCurrentItem.hasClass('active')) {
          var duration = 300;
          sync2.trigger('to.owl.carousel',[current, duration, true]);
        }   
      }
      var thumbs = sync2.owlCarousel({
        startPosition: 12,
        items:4,
        loop:false,
        margin:0,
        autoplay:false,
        nav: false,
        dots: false,
        onInitialized: function (e) {
          var thumbnailCurrentItem =  $(e.target).find(thumbnailItemClass).eq(this._current);
          thumbnailCurrentItem.addClass('synced');
        },
      })
      .on('click', thumbnailItemClass, function(e) {
          e.preventDefault();
          var duration = 300;
          var itemIndex =  $(e.target).parents(thumbnailItemClass).index();
          sync1.trigger('to.owl.carousel',[itemIndex, duration, true]);
      }).on("changed.owl.carousel", function (el) {
        var number = el.item.index;
        var $owl_slider = sync1.data('owl.carousel');
        $owl_slider.to(number, 100, true);
      });
      
      $('.owl-carousel2').owlCarousel({
        loop: true,
        margin: 40,
        //default settings:
        autoplay: true,
        autoplayTimeout: 3500,
        autoplayHoverPause: false,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
            nav: false
          },
          600: {
            items: 2,
            nav: false
          },
          1000: {
            items: 3,
            nav: false
          }
        }
      });
      
      
          // $('.filters ul li').click(function(){
          //   $('.filters ul li').removeClass('active');
          //   $(this).addClass('active');
            
          //   var data = $(this).attr('data-filter');
          //   $grid.isotope({
          //     filter: data
          //   })
          // });
          
          // var $grid = $(".grid").isotope({
          //   itemSelector: ".all",
          //   percentPosition: true,
          //   masonry: {
          //     columnWidth: ".all"
          //   }
          // });
      
          
    })
 
 

// $(document).ready(function(){

//   $(".filter-button").click(function(){
//      $(this).addClass('active');
//       var value = $(this).attr('data-filter');
      
//       if(value == "all")
//       {
//           //$('.filter').removeClass('hidden');
//           $('.filter').show('1000');
         
//       }
//       else
//       {
// //            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
// //            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
//           $(".filter").not('.'+value).hide('3000');
//           $('.filter').filter('.'+value).show('3000');
      
          
//       }
//   });
  
//   $('.filter-button').removeClass('active');

  


//   $(".filter-button2").click(function(){
//      $(this).addClass('active');
//       var value = $(this).attr('data-filter');
      
//       if(value == "all")
//       {
//           //$('.filter').removeClass('hidden');
//           $('.filter2').show('1000');
         
//       }
//       else
//       {
// //            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
// //            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
//           $(".filter2").not('.'+value).hide('3000');
//           $('.filter2').filter('.'+value).show('3000');
      
          
//       }
//   });
  
//   $('.filter-button2').removeClass('active');

// });

  }

 


  get f() { return this.searchForm.controls; }

  onSearch(e) {
    this.submitted = true;
     // stop here if form is invalid
     if (this.searchForm.invalid) {
      return;
     }
    this.searchedPro = undefined;
    let value = e.target.value.trim();
      this.searchedPro = undefined;
      this.quartsData = undefined;
      if(value.length >2) {
        // this.filters = [];
        // this.loadMore = true;
        this.flag = 'search';
        this.searchBycosmosfilter()
        // let formData: FormData = new FormData(); 
        // formData.append('name',  value ); 
        // formData.append('website',  'igm' ); 
      //       this.api.fetchDataCosmos('getSpecialProductFilter',formData,'POST').subscribe(
      //   res => {
      //     if(res['status'] == 1) {
      //       this.loadMore = false;
      //       this.searchedPro = res['product']
      //     } else {
      //       this.loadMore = false;
      //       this.searchedPro = []
      //     }
      //   }
      // )

       }
       if(value.length == 0) {
         this.flag = undefined;
         if(this.filters.length == 0) {
          this.searchForm.patchValue({
            search:null
          })
          this.counter = 0;
      
            this.getQuartsProduct(0)
        }else {
          this.searchBycosmosfilter();
        }
       }
  }

  clearSearch() {
    this.searchedPro = undefined;
    this.flag = undefined;
    this.searchForm.patchValue({
      search:null
    })
    if(this.filters.length == 0) {
      this.counter = 0;
  
        this.getQuartsProduct(0)
    }else {
      this.searchBycosmosfilter();
    }

  }

  onScrollQuarts() {
    if(this.more != 0 && this.loadMore == false) {
      this.getQuartsProduct(this.counter)
      this.loadMore = true;
    }
  }

  getQuartsProduct(offset) {
    this.counter++
    this.loadMore = true;
    this.api.fetchDataCosmos('specialproductlistquartz', {page:offset*6,website:'igm' },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.more  = res['more']
          this.quartsData =  this.quartsData == undefined ? []:this.quartsData
          this.quartsData = this.quartsData.concat( res['product']);
          this.loadMore = false;
        } else {
          this.quartsData = [];
          this.loadMore = false;
        }
      }
    )
  }

  getAllQuartzFilter(){
    // https://staging.cosmosgranite.com/api/getAllFilter
    this.api.fetchDataCosmos('getAllFilter',{website:'igm'},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
       this.cosmosFilter = res['filter']
        } else {
       
        }
      }
    )
  }

  getCategory() {
    this.api.fetchData('product_category',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }

  scrollTO(){
    setTimeout(() => {
      var elmnt = document.getElementById("accordion");
   let pos =  document.documentElement.scrollTop || document.body.scrollTop;
      if(pos < elmnt.scrollHeight) {
        elmnt.scrollIntoView();
      }

    },200)
  }
  

  selecCosmosFliter(item, type){
    let name  = '';
    let obj
    if(type == 'color') {
       obj = {
        type: type,
        name: item.color
      }
    } else {
       obj = {
        type: type,
        name: item
      }

    }

    if(type == 'color'  || type == 'Series') { 
      let index =  this.filters.findIndex(ind => ind.type == type);
      if(index != -1) {
        this.filters.splice(index,1)
      }
    }
    let y;
    if(type == 'thickness') {
      y = -1;
    } else {
      y  = this.filters.findIndex(f => f.type == type);
    }
  
    let x  = this.filters.findIndex(f => f.name == item);
    if( y == -1 && x == -1) {
      this.filters.push(obj)
      this.searchBycosmosfilter()
    }
  }
  searchBycosmosfilter(){
    let color = [];
    let thikness = [];
    let image_option = [];
    let usage = [];
    let Series = [];
    this.searchedPro = undefined;
    this.quartsData  = undefined;
    let name = this.searchForm.value.search ? this.searchForm.value.search : ''

    this.filters.map((f,i) => {
      if(f.type == 'color') {
       let x = this.cosmosFilter['color'].findIndex(x => x.color == f.name)
       if(x != -1) {
         color.push(this.cosmosFilter['color'][x]['id'])

       }
      }
      // if(f.type == "category") {
      //   category.push(f.name)
      // }
      if(f.type == "thickness") {
        thikness.push(f.name)
      }
      if(f.type == "filter_by_images") {
        image_option.push(f.name)
      }
      if(f.type == "usage") {
        usage.push(f.name)
      }
      if(f.type == "Series") {
        Series.push(f.name)
      }
      this.loadMore = true;
      if((i+1) == this.filters.length) {
        // let formData: FormData = new FormData(); 
        let formData: FormData = new FormData(); 
        formData.append('color_name',  color.toString() ); 
        formData.append('image_option',  image_option.toString()); 
        formData.append('website',  'igm' ); 
        formData.append('usages', usage.toString()); 
        formData.append('thikness', thikness.toString() ); 
        formData.append('series', Series.toString() ); 
        formData.append('name', name ); 
        this.api.fetchDataCosmos('getSpecialProductFilter',formData,'POST').subscribe(
        res => {
          if(res['status'] == 1) {
            this.loadMore = false;
            this.searchedPro = res['product']
          } else {
            this.loadMore = false;
            this.searchedPro = []
          }
        }
      )
      }
    }) 
    if(this.filters.length == 0 && this.searchForm.value.search && this.searchForm.value.search.length > 0) {
      let formData: FormData = new FormData(); 
      formData.append('color_name',  color.toString() ); 
      formData.append('image_option',  image_option.toString()); 
      formData.append('website',  'igm' ); 
      formData.append('usages', usage.toString()); 
      formData.append('thikness', thikness.toString() ); 
      formData.append('series', Series.toString() ); 
      formData.append('name', name ); 
      this.api.fetchDataCosmos('getSpecialProductFilter',formData,'POST').subscribe(
      res => {
        if(res['status'] == 1) {
          this.loadMore = false;
          this.searchedPro = res['product']
        } else {
          this.loadMore = false;
          this.searchedPro = []
        }
      }
    )
    }
  }
  
  clearcosmosFilter(){
    this.searchedPro = undefined;
    this.filters = [];
    if(this.searchForm.value.search && this.searchForm.value.search.length > 0) {
      this.searchBycosmosfilter()
      
    } else {
      this.getQuartsProduct(0)
      this.counter =0;

    }
  }

  removecosmosFilter(x) {
    this.filters.splice(x,1);
    if(this.filters.length == 0) {
      this.clearcosmosFilter();
    }else {
      this.loadMore = true;
      this.flag = 'search'
      this.searchBycosmosfilter()
    }
  }
  
  checkcosmosFilter(event, key, type) {
  
  
    let obj = {
      type: type,
      name: key
    }
    if(type == 'filter_by_images'  && event.target.checked) { 
      let index =  this.filters.findIndex(ind => ind.type == type);
      if(index != -1) {
        this.filters.splice(index,1)
      }
    }
    let y;
    if(type == 'usage') {
      y = -1;
    } else {
      y  = this.filters.findIndex(f => f.type == type);
    }
   

    if(y == -1 && event.target.checked) {
      this.filters.push(obj)
    } else {
      let x  = this.filters.findIndex(f => f.name == key);
      this.filters.splice(x,1);
    }
    if(this.filters.length == 0) {
      this.clearcosmosFilter()
    }else {
      this.searchBycosmosfilter()
    }
  }
  
  getcosmosCheckedValue(key) {
    let x  = this.filters.findIndex(f => f.name == key);
    return (x == -1) ? false : true;  
  }

getClass(key) {
 let x  =  this.filters.findIndex(f => f.type == 'filter_by_images' && f.name  == key);
 return (x != -1) ? true : false;
}

disabledFilter(key, type) {
  let x  = this.filters.findIndex(f => f.name == key);
  if(x != -1) {
    return false;
  } else {
    let x  = this.filters.findIndex(f => f.type == type);
      return (x == -1) ? false : true;  
  }
}
getSeoData(){
  let patname =  this.router.url;
  patname = patname.slice(0, 0) + patname.slice(1);
  this.api.fetchDatalive('seodata',{page_name: 'quartz'},'GET').subscribe(
    res => {
      if(res['status'] == 1) {
        let data = JSON.parse(JSON.stringify(res.data))
      // this._seoService.updateTitle('Best Quartz Products for Commercial and Residential Settings | IGM Corp');
      this._seoService.updateTitle(data['title']);
      // this._seoService.updateTitle('IGM');
      this._seoService.updateDescription(data['description'])
//  this._seoService.updateOgUrl(event['ogUrl']);
      }
    }
  )
}

saveFiltersData() {
  localStorage.setItem('filtersDataQuartz',JSON.stringify(this.filters));

}



}
