import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { helpForm, orderForm , apponitmentForm} from '../forms'
import { SharedService, ApiService, SeoServiceService } from '../service/index';
import { Router, ActivatedRoute } from '@angular/router';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.css']
})
export class BookAppointmentComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';
  model: NgbDateStruct;
  apponitmentForm = apponitmentForm;
  submitted = false;
  message = '';
  loadMore = false;
  location = [];
  markDisabled;
  calendar: NgbCalendar;
  datesDisabled: boolean = false;
  mindate: any;
  fromPage: any = '';
  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute,calendar: NgbCalendar, config: NgbDatepickerConfig,
    private api: ApiService, public shared: SharedService, private _seoService : SeoServiceService,
    private router: Router)  {
      let page = localStorage.getItem('page');
      localStorage.removeItem('page');
      if(page != null && page != undefined && page){
        this.fromPage = page;
      }
      let date = new Date();
      this.mindate = { year: date.getFullYear() , month: date.getMonth()+1 , day: date.getDate() }
      this.calendar = calendar;
      this.shared.cleardata();

      this.disableDays();
      this.getCategory();
      this.getLocation();
      this.getSeoData();

      this.apponitmentForm = this.fb.group({
        name:  ['', Validators.required],
        phone: ['', [ Validators.required, 
          Validators.pattern("^[0-9]*$"),Validators.minLength(10),Validators.maxLength(13)]],
        email:  ['',  [Validators.required, Validators.email]],
        city:  [''],
        message:  ['', Validators.required],
        location:  ['', Validators.required],
        date_time:  ['', Validators.required],
        time:  ['', Validators.required],
      });
     }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
  //   $('#datepicker').datepicker({
  //     daysOfWeekDisabled: [0,6]
  // });
 
  }


  get g() { return this.apponitmentForm.controls; }

  onsubmit(d) {
    this.submitted = true;
    this.loadMore = true;
     // stop here if form is invalid
     if (this.apponitmentForm.invalid) {
      this.loadMore = false;
      return;
     }
     let formData: FormData = new FormData(); 
     formData.append('name', d.name); 
     formData.append('phone', d.phone); 
     formData.append('email', d.email); 
     formData.append('city', d.city); 
     formData.append('message', d.message); 
     formData.append('name', d.name); 
     formData.append('location', d.location); 
     formData.append('date_time', d.date_time.year+ '-' +d.date_time.month +'-'+d.date_time.day ); 
     formData.append('time', d.time); 
     formData.append('page', this.fromPage); 
     this.api.fetchData('book-appointment',formData,'POST').subscribe(
       res => {
         if(res['status'] == 1) {
           this.message = res['msg'];
           setTimeout(() => {
            this.message ="";
           },5000)
           this.submitted = false;
          this.apponitmentForm.reset();
          this.loadMore = false;
         } else {
          this.message = res['msg'];
          this.loadMore = false;     
          this.submitted = false;
         }
       }
     )

  }
  getCategory() {
    this.api.fetchData('product_category',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }
  getLocation() {
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.location =   res['location'];
        } else {
          this.location =  [];

        }
      }
    )
  }

  disableDays() {
    if(this.datesDisabled) {
      this.datesDisabled = false;
      this.markDisabled = (date: NgbDate) => { return false };
    } else {
      this.datesDisabled = true;
      this.markDisabled = (date: NgbDate) => { return this.calendar.getWeekday(date) >= 6 };
    }  
  }
  getSeoData(){
    let patname =  window.location.pathname
    patname = patname.slice(0, 0) + patname.slice(1);

    this.api.fetchDatalive('seodata',{page_name: 'book-appointment'},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
        this._seoService.updateTitle(res['data'].title);
        this._seoService.updateDescription(res['data'].description)
  //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }
}
