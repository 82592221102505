<main>
   <section class="bg-light py-md-5 py-3" *ngIf="productdata">
      <div class="container-fluid" *ngIf="productdata.productDetails" >
         <div class="mb-3 row align-items-center mx-0 justify-content-between">
            <div class="col d-lg-flex justify-content-between align-items-center">
               <h1 class="h3 slider-heading-product" *ngIf="productdata">{{productdata.productDetails.ProductName}}</h1>
               <!-- <h3 class="text-center">Best Product</h3> -->
               <ol class="breadcrumb bg-white justify-content-start mb-0 px-4" *ngIf="productdata">
                  <li class="breadcrumb-item"><a  [routerLink]="['/']" href="javascript:void(0)"  class="text-dark"><i class="fa-24 fa fa-home"></i></a></li>
                  <li class="breadcrumb-item"><a  [routerLink]="['/selectlocation']" href="javascript:void(0)"  class="text-dark">LOCATIONS </a></li>
                  <li class="breadcrumb-item"><a    routerLink="/{{productdata.location_name}}"  href="javascript:void(0)" class="text-dark">
                     <span *ngIf="productdata"> 
                     {{productdata.location_name | uppercase}} 
                     </span>               
                     <span *ngIf="!productdata"> 
                     N/A               
                     </span>  </a>
                  </li>
                  <li class="breadcrumb-item"><a  routerLink="/{{productdata.location_name}}/{{productdata.productDetails.category_slug}}" href="javascript:void(0)" class="text-dark">
                     <span *ngIf="productdata"> 
                     {{productdata.productDetails.ProductCategory | uppercase}} 
                     </span>               
                     <span *ngIf="!productdata"> 
                     N/A               
                     </span>  
                     </a>
                  </li>
                  <li class="breadcrumb-item"><a  routerLink="/{{productdata.location_name}}/{{productdata.productDetails.category_slug}}/{{productdata.productDetails.productSlug}}"  href="javascript:void(0)" class="text-dark">
                     <span *ngIf="productdata"> 
                     {{productdata.productDetails.ProductName | uppercase}} 
                     </span>               
                     <span *ngIf="!productdata"> 
                     N/A               
                     </span>  
                     </a>
                  </li>
               </ol>
            </div>
         </div>
         <div class="row align-items-start mx-0">
            <div class="col-lg-6 col-md-12">
               <div id="sync1" class="slider owl-carousel">
                  <div class="item">
                     <img *ngIf="!productdata.productDetails.ProductPicture" src="{{tinyUrl}}/placeholder.jpg"  
                        class="img-fluid"> 
                     <ng-container *ngIf="productdata.productDetails.ProductPicture && isBrowser">
                        <lib-ngx-image-zoom 
                        [thumbImage]=productdata.productDetails.ProductPicture
                        [fullImage]=productdata.productDetails.ProductPicture
                        [magnification]="1"
                        [enableScrollZoom]="true"
                        [enableLens]="true"
                        [lensWidth]="200"
                        [magnification]="2"
                        (click) ="onclickImg(productdata.productDetails.ProductPicture)"
                        ></lib-ngx-image-zoom>
                     </ng-container>
                  </div>
                  <!-- <div class="item">
                     <img src="{{productdata?.productDetails.ProductPicture}}" class="img-fluid">
                     </div> -->
               </div>
               <!-- <div id="sync2" class="mt-2 navigation-thumbs owl-carousel justify-content-center">
                  <div class="item">
                     <img src="{{productdata?.productDetails.ProductPicture}}" class="img-fluid">
                     <div class="textImg">Zoom</div>
                  </div>
                  <div class="item">
                     <img src="{{productdata?.productDetails.ProductPicture}}" class="img-fluid">
                     <div class="textImg">Full slab</div>
                  </div>
                  </div> -->
            </div>
            <div class="col-lg-6 col-md-12">
               <h5 class="mb-4" *ngIf="productdata && productdata.productDetails.DescriptiononWebsite">About  <span *ngIf="productdata"> 
                  {{productdata.productDetails.ProductName}} 
                  </span>   
               </h5>
               <!-- <p>
                  Presenting the Absolute Cream granite, it's the latest trend in the modern world. The known country of origin of this product is 'Brazil.' 
                  </p>
                  <p>
                  </p>
                  <p>
                  Absolute Cream is the superior series of granite slabs. This high-quality granite surface stresses on authenticity and realism. In every setting, these slabs provide awesome color patterns marked with a strong grip. 
                  </p>
                  <p>
                  Moreover, the granite is specially tailored to suit the needs of Dallas, Tx based customers. The rough and tough surface of the stone works at every place.  Other uses of the stone include constructional and ornamental stones. 
                  </p> -->
               <div>
                  <div *ngIf="productdata && productdata.productDetails.DescriptiononWebsite">
                     <!-- {{productdata.productDetails.DescriptiononWebsite}} --> 
                     <div class="col-md-12 px-0">
                        <div *ngIf="productdata && productdata.productDetails.DescriptiononWebsite" >
                           <!-- N/A -->
                           <span *ngIf="showDescription == false" [innerHtml]="productdata?.productDetails.DescriptiononWebsite  | slice:0:250">
                           </span> 
                           <span *ngIf="showDescription == true" [innerHtml]="productdata?.productDetails.DescriptiononWebsite ">
                           </span> 
                        </div>
                     </div>
                     <div class="col-md-12 px-0 text-right">
                        <a href="javascript:void(0)" *ngIf="showDescription ==false" (click)="showDescription = true"  class="mb-0 h5 text-theme-light text-underline" >View More</a>
                        <a href="javascript:void(0)" *ngIf="showDescription ==true" (click)="showDescription = false"  class="mb-0 h5 text-theme-light text-underline" >View less</a>
                     </div>
                  </div>
                  <span *ngIf="productdata && !productdata.productDetails.DescriptiononWebsite">
                     <!-- N/A -->
                  </span>
               </div>
               <hr>
               <!-- <div class="row px-2">
                  <a class="mt-3 mt-md-0 mx-0 mb-1 btn btn-outlined btn-theme btn-lg d-inline-flex flex flex-wrap align-items-center" href="javascript:void(0)" 
                  (click)="gotoliveInvetory('live_invetory_s')">Live Inventory</a>
               </div> -->
               <div class="row px-2">
               <div class="col-md-12 px-0 mb-1 d-flex flex-wrap align-items-center justify-content-center justify-content-md-start featurebutton">
                  
                  <div class="card p-0" style="max-width: 325px;">
                     <div class="d-flex card-body text-center p-0" *ngIf="qrdata && isBrowser">
                        <qrcode [qrdata]="qrdata" [width]="350" [errorCorrectionLevel]="'M'"></qrcode>
                        <button (click)="downloadQr()" class="ml-1 border-left btn btn-link">Download QR Code</button>
                     </div>
                  </div>
                  
                  <a class="mt-3 pt-1 mt-md-1 mx-3 btn btn-outlined btn-theme btn-lg d-inline-flex flex flex-wrap align-items-center ordersample-btn" href="javascript:void(0)" data-toggle="modal" data-target="#OrderSampleModal"><i class="fa fa-th-large pr-2" aria-hidden="true" (click)="openForm()"></i>Order Sample</a>
                  <a class="mt-3 mt-md-0 mx-0 mb-1 btn btn-outlined btn-theme btn-lg d-inline-flex flex flex-wrap align-items-center liveinventory-btn" href="javascript:void(0)" 
                  (click)="gotoliveInvetory('live_invetory_s')">Live Inventory</a>
               </div>
            </div>
               <!-- <div class="card mb-4" style="max-width: 243px;">
                  <div class="card-body text-center py-2" *ngIf="qrdata && isBrowser">
                     <qrcode [qrdata]="qrdata" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                  </div>
                  <div class="card-footer py-2">
                    <button (click)="downloadQr()" class="btn btn-link">Download QR Code</button>
                  </div>
                  </div>
                  <a class="btn btn-outlined btn-theme btn-lg d-inline-flex flex flex-wrap align-items-center" href="javascript:void(0)" data-toggle="modal" data-target="#OrderSampleModal"><i class="fa fa-th-large pr-2" aria-hidden="true" (click)="openForm()"></i>Order Sample</a> -->              
               <div class="modal fade" id="OrderSampleModal" tabindex="-1" data-backdrop="static" >
                  <div class="modal-dialog modal-lg" role="document">
                     <div class="modal-content">
                        <form [formGroup]="orderForm" 
                        (ngSubmit)="onOrder(orderForm.value)"  ngNativeValidate>
                        <div class="modal-header">
                           <h5 class="modal-title" id="exampleModalLabel">Order Sample</h5>
                           <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetForm()">
                           <span aria-hidden="true">×</span>
                           </button>
                        </div>
                        <div class="modal-body">
                           <div class="form-row">
                              <!--  <div class="col-md-12 form-group">
                                 <hr class="my-2">
                                 </div> -->
                              <div class="col-xl-6 col-md-6 form-group mb-1">
                                 <label class="mb-1" for="name">First Name *</label>
                                 <input type="text" name="name" class="form-control"  data-rule="minlen:4" data-msg="Please enter at least 4 chars" 
                                    [ngClass]="{ 'is-invalid': ordersubmitted && f.fname.errors }" formControlName="fname">
                                 <div *ngIf="ordersubmitted && f.fname.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.fname.errors.required" > First Name is required</div>
                                 </div>
                              </div>
                              <div class="col-xl-6 col-md-6 form-group mb-1">
                                 <label class="mb-1" for="email">Last Name *</label>
                                 <input type="text" class="form-control"  [ngClass]="{ 'is-invalid': ordersubmitted && f.lname.errors }"
                                    formControlName="lname">
                                 <div *ngIf="ordersubmitted && f.lname.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.lname.errors.required">Last Name is required</div>
                                 </div>
                              </div>
                              <div class="col-xl-4 col-md-6 form-group mb-1 mb-1">
                                 <label class="mb-1" for="name">Phone *</label>
                                 <input type="text" name="name" class="form-control" 
                                    [ngClass]="{ 'is-invalid': ordersubmitted && f.phone.errors }" formControlName="phone">
                                 <div *ngIf="ordersubmitted && f.phone.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.phone.errors.required">Phone is required</div>
                                    <div *ngIf="f.phone.errors.pattern"> Only Number  is required</div>
                                    <div *ngIf="f.phone.errors.minlength">Minimum 10 digit is required.</div>
                                    <div *ngIf="f.phone.errors.maxlength">Maximum 13 digits allowed.</div>
                                 </div>
                              </div>
                              <div class="col-xl-4 col-md-6 form-group mb-1 mb-1">
                                 <label class="mb-1" for="email">Email *</label>
                                 <input type="email" class="form-control"
                                    [ngClass]="{ 'is-invalid': ordersubmitted && f.email.errors }" formControlName="email">
                                 <div *ngIf="ordersubmitted && f.email.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                 </div>
                              </div>
                              <div class="col-xl-4 col-md-6 form-group mb-1 mb-1">
                                 <label class="mb-1" for="name">Company Name</label>
                                 <input type="text" name="name" class="form-control"
                                    formControlName="company_name">
                              </div>
                              <div class="col-md-12 form-group">
                                 <label class="mb-1" for="email">Address *</label>
                                 <textarea  class="form-control"
                                    [ngClass]="{ 'is-invalid': ordersubmitted && f.address.errors }" formControlName="address"></textarea>
                                 <div *ngIf="ordersubmitted && f.address.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.address.errors.required">Address is required</div>
                                 </div>
                              </div>
                              <div class="col-xl-4 col-md-6 form-group mb-1 mb-1">
                                 <label class="mb-1" for="City">City *</label>
                                 <input type="City" class="form-control"
                                    [ngClass]="{ 'is-invalid': ordersubmitted && f.city.errors }" formControlName="city">
                                 <div *ngIf="ordersubmitted && f.city.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.city.errors.required">City is required</div>
                                 </div>
                              </div>
                              <div class="col-xl-4 col-md-6 form-group mb-1 mb-1">
                                 <label class="mb-1" for="name">State *</label>
                                 <select type="text" name="name" class="form-control"
                                    [ngClass]="{ 'is-invalid': ordersubmitted && f.state.errors }" formControlName="state">
                                    <option selected="selected" value="--Select--">--Select--</option>
                                    <option value="AL">AL</option>
                                    <option value="AR">AR</option>
                                    <option value="AZ">AZ</option>
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="IA">IA</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MO">MO</option>
                                    <option value="MS">MS</option>
                                    <option value="MT">MT</option>
                                    <option value="NC">NC</option>
                                    <option value="ND">ND</option>
                                    <option value="NE">NE</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VA">VA</option>
                                    <option value="VT">VT</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>
                                 </select>
                                 <div *ngIf="ordersubmitted && f.state.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.state.errors.required">State is required</div>
                                 </div>
                              </div>
                              <div class="col-xl-4 col-md-6 form-group mb-1 mb-1">
                                 <label class="mb-1" for="Country">Country  *</label>
                                 <input type="Country"  class="form-control" [readonly]="true"
                                    formControlName="country">
                              </div>
                              <div class="col-xl-4 col-md-6 form-group mb-1 mb-1">
                                 <label class="mb-1" for="name">Zip/Postal Code *</label>
                                 <input type="text" name="name" class="form-control"
                                    [ngClass]="{ 'is-invalid': ordersubmitted && f.zip.errors }" formControlName="zip">
                                 <div *ngIf="ordersubmitted && f.zip.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.zip.errors.required">Zip/Postal Code is required</div>
                                    <div *ngIf="f.zip.errors.pattern"> Only Number  is required</div>
                                    <div *ngIf="f.zip.errors.minlength">Minimum 5 digit is required.</div>
                                    <div *ngIf="f.zip.errors.maxlength">Maximum 5 digits allowed.</div>
                                 </div>
                              </div>
                              <div class="col-xl-4 col-md-6 form-group mb-1 mb-1">
                                 <label class="mb-1" for="subject">Location *</label>
                                 <select class=" form-control "
                                    [ngClass]="{ 'is-invalid': ordersubmitted && f.location.errors }" formControlName="location">
                                    <option  value="" class="text-dark">Please select location</option>

                                    <option *ngFor="let item of location" value="{{item.city_name}}" class="text-dark">{{item.address}}</option>

                                    <!-- <option value="ATLANTA GA" class="text-dark">Atlanta GA, 123 Interstate W. Pkwy., Lithia Springs, GA</option>
                                    <option value="HOUSTON TX" class="text-dark">Houston TX, 8017 Pinemont Dr. #300, Houston, TX</option>
                                    <option value="NORTH BERGEN NJ" class="text-dark">North Bergen NJ, 2038 83rd Street, North Bergen, NJ</option>
                                    <option value="QUAKERTOWN PA" class="text-dark">Quakertown PA, 2700 Milford Square Pike, Quakertown, PA</option>
                                    <option value="WINDSOR CT" class="text-dark">Windsor CT, 800 Marshall Phelps Rd. #2, Windsor, CT</option> -->
                                 </select>
                                 <div *ngIf="ordersubmitted && f.location.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.location.errors.required">Location is required</div>
                                 </div>
                              </div>
                              <div class="col-xl-4 col-md-6 form-group mb-1 mb-1">
                                 <label class="mb-1" for="name">Customer Type *</label>
                                 <select type="text" name="name" class="form-control"
                                    [ngClass]="{ 'is-invalid': ordersubmitted && f.occupation.errors }" formControlName="occupation">
                                    <!--  <option selected="selected" value="--SELECT--"> --SELECT-- </option> -->
                                    <option value="Home Owner">Home Owner</option>
                                    <option value="Fabricator">Fabricator</option>
                                    <option value="Designer">Designer</option>
                                    <option value="Archtect">Architect</option>
                                    <option value="Builder">Builder</option>
                                    <option value="Retailer/ Wholesaler">Retailer/ Wholesaler</option>
                                    <option value="Other">Other</option>
                                 </select>
                                 <div *ngIf="ordersubmitted && f.occupation.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                                    <div *ngIf="f.occupation.errors.required">Customer is required</div>
                                 </div>
                              </div>
                              <div class="col-md-12 form-group">
                                 <label class="mb-1" for="email">Message </label>
                                 <textarea class="form-control"
                                    formControlName="message"></textarea>
                              </div>
                           </div>
                           <p class="h6 mb-0">*Sample dispatch is subject to the availability of the product in our inventory.</p>
                        </div>
                        <div class="modal-footer">
                           <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                              <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                                 <div class="d-flex align-items-center">
                                    <img src="{{tinyUrl2}}/loader.svg" class="img-fluid" width="50" /> 
                                    <p class="mb-0 mt-1 mr-3">Loading...</p>
                                 </div>
                              </div>
                           </div>
                           <div *ngIf="ordermessage " role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                              <div class=" py-2 px-5 text-center">
                                 <p id="res_message mt-2 text-center" *ngIf="ordermessage" class=""> {{ordermessage}} </p>
                              </div>
                           </div>
                           <button type="button" class="btn btn-secondary" data-dismiss="modal" id="orderForm-close" (click)="resetForm()">Close</button>
                           <button  class="btn btn-primary">Submit</button>
                        </div>
                        </form>
                     </div>
                  </div>
               </div>
               <p></p>
               <div class="row" *ngIf="productdata">
                  <div class="col-sm-6 mb-0 p-2"  [hidden]="!productdata?.productDetails.ProductColor">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">Colors</h5>
                        <p class="mb-0">
                           <span *ngIf="productdata"> 
                           {{productdata.productDetails.ProductColor}} 
                           </span>
                        </p>
                     </div>
                  </div>
                  <!-- <div class="col-sm-6 mb-0 p-2">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">Type</h5>
                        <p class="mb-0">Slab</p>
                     </div>
                     </div> -->
                  <div class="col-sm-6 mb-0 p-2" [hidden]="!productdata?.productDetails.Finish">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">Finish</h5>
                        <p class="mb-0">
                           <span *ngIf="productdata"> 
                           {{productdata.productDetails.Finish}} 
                           </span>
                        </p>
                     </div>
                  </div>
                  <div class="col-sm-6 mb-0 p-2" [hidden]="!productdata?.productDetails.Thickness">
                     <div class="bg-white px-3 py-2" >
                        <h5 class="mb-0">Thickness</h5>
                        <p class="mb-0">
                           <span *ngIf="productdata"> 
                           {{addSpace(productdata.productDetails.Thickness)}} cm
                           </span>
                        </p>
                     </div>
                  </div>
                  <!-- <div class="col-sm-6 mb-0 p-2">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">Price Level</h5>
                        <p class="mb-0">Medium</p>
                     </div>
                     </div> -->
                  <div class="col-sm-6 mb-0 p-2" [hidden]="!productdata?.productDetails.ProductGroup">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">Product Group</h5>
                        <p class="mb-0">
                           <span *ngIf="productdata"> 
                           {{productdata.productDetails.ProductGroup}} 
                           </span>
                        </p>
                     </div>
                  </div>
                  <div class="col-sm-6 mb-0 p-2" [hidden]="!productdata?.productDetails.ProductOrigin">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">County of Origin
                        </h5>
                        <p class="mb-0">
                           <span *ngIf="productdata"> 
                           {{productdata.productDetails.ProductOrigin}} 
                           </span>
                        </p>
                     </div>
                  </div>
                  <div class="col-sm-6 mb-0 p-2" [hidden]="!productdata?.productDetails.also_know_as">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">Also Known As
                        </h5>
                        <p class="mb-0">
                           <span *ngIf="productdata"> 
                           {{productdata.productDetails.also_know_as}} 
                           </span>
                        </p>
                     </div>
                  </div>
                  <div class="col-sm-12 mb-0 p-2" [hidden]="!productdata?.productDetails.Usages">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">Usages</h5>
                        <p class="mb-0">
                           <span *ngIf="productdata"> 
                           {{productdata.productDetails.Usages}} 
                           </span>
                        </p>
                     </div>
                  </div>
                  <div class="col-sm-12 mb-0 p-2" [hidden]="!productdata?.productDetails.Series">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">Series</h5>
                        <p class="mb-0">
                           <span *ngIf="productdata"> 
                           {{productdata.productDetails.Series}} 
                           </span>
                        </p>
                     </div>
                  </div>
               </div>
               <!-- <div class="row" [hidden]="!productdata?.productDetails.also_know_as">
                  <div class="col-sm12 mb-0 p-2">
                     <div class="bg-white px-3 py-2">
                        <h5 class="mb-0">Also Known As</h5>
                        <p class="mb-0">
                           <span *ngIf="productdata"> 
                           {{productdata.productDetails.also_know_as}} 
                           </span>
                        </p>
                     </div>
                  </div>
                  </div> -->
               <div class="row" id="live_invetory_s">
                  <div class="col-sm-12 mb-0">
                     <hr>
                     <a [hidden]="!productdata?.disclaimer" class="mb-0 h5 text-theme-light text-underline" href="javascript:void(0)"   data-toggle="modal" data-target="#exampleModalLong-disclamer">Disclaimer</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div  *ngIf="productdata.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
         <div class=" bg-light py-2 px-5 text-center">
            <p class="mb-0 mt-1"> No product found.</p>
         </div>
      </div>
      <div class="filterbtn1 ">
         <button type="button" class="shadow-lg btn btn-dark"  (click)="shared.showFilter = true; shared.resetFilter = true" routerLink="/{{productdata.location_name}}/{{productdata.productDetails.category_slug}}" ><i class="fa fa-filter"></i></button>
      </div>
   </section>
   <section class="py-5 bg-white" *ngIf="inventory " >
      <div class="container" >
         <div class="row mb-3 align-items-center justify-content-center">
            <div class="col-xl-10 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3" >LIVE INVENTORY</h2>
                 
               </div>
               <p class="mb-0" *ngIf="currentlocation">For large quantity contact us : 
                  <a href="mailto:{{currentlocation?.city_mail}}" class="">
                     {{  currentlocation.city_mail  }}
                  </a>
                  </p>
            </div>
         </div>
         <div class="d-flex row productRow mt-2 pl-md-4 justify-content-center">
            <div class="col-12">
               <owl-carousel-o [options]="customOptions" class="navBard">
             
                 <ng-container *ngFor="let item of inventory.products; let i = index">
                   <ng-template carouselSlide [id]="item?.id">
                     <div class="item imgItem">
                        <div class="liveBox">
                           <img *ngIf="item?.LotBundlePicture" src="{{item?.LotBundlePicture}}"  
                           (click)="onclickinventory(item, i)">
                        </div>
                        <div class="productdeatils">
                           <a href="javascript:void(0);" class="text-dark">
                              <p class="mb-0"> <span *ngIf="item?.LotNumber"> SNo# <span>{{item?.LotNumber}}</span></span>  <span *ngIf="item?.BundleNumber">, Block# <span>{{item?.BundleNumber}}</span> </span> </p>
                              <p class="mb-0" *ngIf="item?.AvgSlabLength && item?.AvgSlabWidth">size: <span>{{item?.AvgSlabLength}}x{{item?.AvgSlabWidth}} </span></p>
                              <p class="mb-0" *ngIf="item?.AvailableSlabs">Slab available : <span>{{item && item?.AvailableSlabs ? item?.AvailableSlabs: 'N/A'}} </span></p>
                              
                           </a>
                        </div>
                     </div>
                  </ng-template>
                 </ng-container>
             
               </owl-carousel-o>
               <!-- <div class="navBard owl-carousel owl-theme theme-owl owl-carousel2" >
                  <ng-container *ngFor="let item of inventory.products; let i = index"   >
                     <div class="item" > -->
                        <!-- <a class="search_btn" data-toggle="lightbox" data-gallery="gallery" href="{{item.LotBundlePicture}}">
                           <i class="fa fa-search-plus" aria-hidden="true"></i>        
                           </a> -->
                        <!-- <div class="liveBox">
                           <img *ngIf="item.LotBundlePicture" src="{{item.LotBundlePicture}}"  
                           (click)="onclickinventory(item, i)"> -->
                           <!-- <img src="{{item.LotBundlePicture}}" > -->
                           <!-- <lib-ngx-image-zoom
                              [thumbImage]=item.LotBundlePicture
                              [fullImage]=item.LotBundlePicture
                              [magnification]="1"
                              [enableScrollZoom]="true"
                              [enableLens]="true"
                              [lensWidth]="200"
                              [magnification]="2"
                              (click)="onclickinventory(item)"
                              ></lib-ngx-image-zoom> -->
                        <!-- </div>
                        <div class="productdeatils">
                           <a href="javascript:void(0);" class="text-dark">
                              <p class="mb-0"> <span *ngIf="item.LotNumber"> SNo# <span>{{item.LotNumber}}</span></span>  <span *ngIf="item.BundleNumber">, Block# <span>{{item.BundleNumber}}</span> </span> </p>
                              <p class="mb-0" *ngIf="item.AvgSlabLength && item.AvgSlabWidth">size: <span>{{item.AvgSlabLength}}x{{item.AvgSlabWidth}} </span></p>
                              <p class="mb-0" *ngIf="item.AvailableSlabs">Slab available : <span>{{item && item.AvailableSlabs ? item.AvailableSlabs: 'N/A'}} </span></p>
                              
                           </a>
                        </div>
                     </div>
                  </ng-container>
               </div> -->
            </div>
         </div>
      </div>
   </section>
   <ng-container *ngIf="inventory && inventory.length == 0 && currentlocation">
      <section class="py-5 text-center" >
         <div class="getwithtouch text-center">
            <h4 class="modal-title popup-title">Get in Touch</h4
               >
            Looking for more details? Get in touch with our {{currentlocation.city_name}} Branch.<br>
            <a href="mailto:{{currentlocation.city_mail}}">{{currentlocation.city_mail}}</a> 
            | <a href="tel:+{{currentlocation.phone}}">{{currentlocation.phone}}</a>
         </div>
      </section>
   </ng-container>
   <section class="py-5 bg-light" >
      <div class="container-fluid"  *ngIf="productdata && productdata.productYouMayLike && productdata.productYouMayLike.length > 0">
         <div class="row mb-3 align-items-center justify-content-center">
            <div class="col-xl-10 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">YOU MAY ALSO LIKE</h2>
               </div>
            </div>
         </div>
         <div class="d-flex row productRow mt-2 pl-4 justify-content-center">
            <ng-container *ngIf="productdata && productdata.productYouMayLike">
               <div class="img-shine col-sm-3 overflow-hd px-1 mb-2 flex-grow-1"
                  *ngFor="let item of productdata.productYouMayLike">
                  <div class="thumb"  >
                     <a  target="_blank" routerLink="/{{productdata.location_name}}/{{item.category_slug}}/{{item.productSlug}}"  *ngIf="item.ProductPicture" href="javascript:void(0)"  style="background-image: url('{{item.ProductPicture}}');">
                     </a>                           
                     <a  target="_blank"  routerLink="/{{productdata.location_name}}/{{item.category_slug}}/{{item.productSlug}}" *ngIf="!item.ProductPicture" class="img-placeholder"  href="javascript:void(0)" style="background-image: url({{tinyUrl}}/placeholder.jpg);"></a>
                  </div>
                  <div class="productdeatils">{{item.ProductName}}</div>
               </div>
            </ng-container>
         </div>
      </div>
      <div class="container-fluid"  *ngIf="productDetailcosmos && productDetailcosmos.related &&productDetailcosmos.related.length > 0">
         <div class="row mb-3 align-items-center justify-content-center">
            <div class="col-xl-10 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">YOU MAY ALSO LIKE</h2>
               </div>
            </div>
         </div>
         <div class="d-flex row productRow mt-2 pl-4 justify-content-center">
            <ng-container *ngIf="productDetailcosmos && productDetailcosmos.related">
               <div class="img-shine col-sm-3 overflow-hd px-1 mb-2 flex-grow-1"
                  *ngFor="let item of productDetailcosmos.related">
                  <!-- <div class="waterMark waterMark3">
                     <img src="{{tinyUrl2}}/COALESCE_LOGO2.png" class="img-fluid" />
                     </div> -->
                  <div class="thumb"  >
                     <a  routerLink="/{{currentlocation.city_slug}}/{{cat_slug}}/{{item.productSlug}}"   *ngIf="item.thumbnail" href="javascript:void(0)"  style="background-image: url('{{item.thumbnail}}');">
                     </a>                           
                     <a   routerLink="/{{currentlocation.city_slug}}/{{cat_slug}}/{{item.productSlug}}"   *ngIf="!item.thumbnail" class="img-placeholder"  href="javascript:void(0)" style="background-image: url({{tinyUrl}}/placeholder.jpg);"></a>
                  </div>
                  <div class="productdeatils coalDetail">{{item.ProductName}}</div>
               </div>
            </ng-container>
         </div>
      </div>
   </section>
   <section id="contact" class="contact section-bg impress_area2 text-white" *ngIf="productdata || productDetailcosmos">
      <div class="container-fluid py-5">
         <div class="row justify-content-center">
            <div class="col-xl-12 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3 text-white">ASK US ANYTHING, WE'RE HERE TO HELP!</h2>
               </div>
            </div>
            <div class="col-lg-6 mt-md-5 mt-3">
               <form [formGroup]="helpForm" 
               (ngSubmit)="onsubmit(helpForm.value)"  ngNativeValidate class="w-100">
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="name">Your Name *</label>
                     <input type="text" name="name" class="text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.name.errors }" formControlName="name">
                     <div *ngIf="submitted && g.name.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.name.errors.required">Name is required</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="email">Your Email *</label>
                     <input type="email" class="text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.email.errors }" formControlName="email">
                     <div *ngIf="submitted && g.email.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.email.errors.required">Email is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="name">Phone *</label>
                     <input type="text" name="name" class="text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.phone.errors }" formControlName="phone">
                     <div *ngIf="submitted && g.phone.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.phone.errors.required">Phone is required</div>
                        <div *ngIf="g.phone.errors.pattern"> Only Number  is required</div>
                        <div *ngIf="g.phone.errors.minlength">Minimum 10 digit is required.</div>
                        <div *ngIf="g.phone.errors.maxlength">Maximum 13 digits allowed.</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="street">Street *</label>
                     <input type="text" class="text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.street.errors }" formControlName="street">
                     <div *ngIf="submitted && g.street.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.street.errors.required">Street is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="city">City *</label>
                     <input type="text" class="text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.city.errors }" formControlName="city">
                     <div *ngIf="submitted && g.city.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.city.errors.required">City is required</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="name">State *</label>
                     <input type="text" name="state" class="text-white form-control bg-transparent"
                        [ngClass]="{ 'is-invalid': submitted && g.state.errors }" formControlName="state">
                     <div *ngIf="submitted && g.state.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.state.errors.required">State is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="name">Product *</label>
                     <input type="text" name="product" class="text-white form-control bg-transparent" 
                        [ngClass]="{ 'is-invalid': submitted && g.product.errors }" formControlName="product">
                     <div *ngIf="submitted && g.product.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.product.errors.product"> Product Name is required</div>
                     </div>
                  </div>
                  <div class="col-md-6 form-group">
                     <label class="mb-1" for="email">Customer Type *</label>
                     <select class="text-white form-control bg-transparent"  name="occupation"
                        [ngClass]="{ 'is-invalid': submitted && g.area.errors }" formControlName="area">
                        <option class="text-dark" value="">Select</option>
                        <option class="text-dark" value="Home Owner">Home Owner</option>
                        <option class="text-dark" value="Fabricator">Fabricator</option>
                        <option class="text-dark" value="Designer">Designer</option>
                        <option class="text-dark" value="Archtect">Architect</option>
                        <option class="text-dark" value="Builder">Builder</option>
                        <option class="text-dark" value="Retailer/ Wholesaler">Retailer/ Wholesaler</option>
                        <option class="text-dark" value="Other">Other</option>
                     </select>
                     <div *ngIf="submitted && g.area.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                        <div *ngIf="g.area.errors.required">Customer Type is required</div>
                     </div>
                  </div>
               </div>
               <div class="form-group">
                  <label class="mb-1" for="message">Message *</label>
                  <textarea class="text-white form-control bg-transparent"
                     [ngClass]="{ 'is-invalid': submitted && g.message.errors }" formControlName="message"></textarea>
                  <div *ngIf="submitted && g.message.errors" class="invalid-feedback alert alert-danger py-1 px-3">
                     <div *ngIf="g.message.errors.required">Message is required</div>
                  </div>
               </div>
               <div class="text-center d-flex justify-content-center">
                  <button class="d-flex align-items-center shadow btn btn-theme py-3 px-5 d-flex">Submit  <i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
               </div>
               <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                     <div class="d-flex align-items-center">
                        <img src="{{tinyUrl2}}/loader.svg" class="img-fluid" width="50" /> 
                        <p class="mb-0 mt-1 mr-3">Loading...</p>
                     </div>
                  </div>
               </div>
               <div *ngIf="message" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class=" py-2 px-5 text-center">
                     <p id="res_message mt-2 text-center" *ngIf="message" class=""> {{message}} </p>
                  </div>
               </div>
               </form>
            </div>
         </div>
      </div>
   </section>
   <div *ngIf="loadMore && !productdata" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center bg-light">
      <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
         <div class="d-flex align-items-center">
            <img src="{{tinyUrl2}}/loader.svg" class="img-fluid" width="50" /> 
            <p class="mb-0 mt-1 mr-3">Loading...</p>
         </div>
      </div>
   </div>
</main>
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary d-none" id="inv-image-pop"  data-toggle="modal" data-target="#inventory-image-pop">
Launch demo modal
</button>
<!-- Modal -->
<div class="modal fade"  id="inventory-image-pop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"data-backdrop="static" aria-hidden="true">
   <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content" *ngIf="selectedInventoryPro && inventory">
         <div class="modal-header py-2">
            <h5 class="modal-title align-items-center" id="exampleModalLongTitle">{{selectedInventoryPro.ProductName}}
               <span class="h6"><small> <span *ngIf="selectedInventoryPro.LotNumber"> SNo# <span>{{selectedInventoryPro.LotNumber}}</span></span> 
               <span *ngIf="selectedInventoryPro.BundleNumber">, Block# <span>{{selectedInventoryPro.BundleNumber}}</span> </span>
               <span class="mb-0" *ngIf="selectedInventoryPro.AvgSlabLength && selectedInventoryPro.AvgSlabWidth"> &nbsp;size: <span>{{selectedInventoryPro.AvgSlabLength}}x{{selectedInventoryPro.AvgSlabWidth}}   </span> </span>
               </small> </span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body p-0">
            <div class="zoom-inv-img-div position-relative" *ngIf="selectedInventoryPro">
               <lib-ngx-image-zoom
               [thumbImage]="selectedInventoryPro && selectedInventoryPro.hasError ? noProductImage :selectedInventoryPro.LotBundlePicture"
               [fullImage]="selectedInventoryPro && selectedInventoryPro.hasError ? noProductImage :selectedInventoryPro.LotBundlePicture"
               [magnification]="1"
               [enableScrollZoom]="true"
               [enableLens]="true"
               [lensWidth]="200"
               [magnification]="2"
               ></lib-ngx-image-zoom>
               <div class="owl-nav d-flex align-items-center justify-content-between"  *ngIf="selectedInventoryPro['length']  != 1">
                  <button type="button" role="presentation" class="btn btn-light owl-prev"
                  [disabled]=" selectedInventoryPro['index'] == 0" (click)="onclickArraow(selectedInventoryPro, 'pre')">
                  <span aria-label="Previous"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                  </button>
                  <button type="button" role="presentation" class="btn btn-light owl-next"
                  [disabled]="selectedInventoryPro['index']+1 == selectedInventoryPro['length']" (click)="onclickArraow(selectedInventoryPro, 'next')">
                  <span aria-label="Next"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                  </button>
               </div>
            </div>
         </div>
         <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div> -->
      </div>
   </div>
</div>
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary d-none" id="order-pop"  data-toggle="modal" data-target="#order-form-result">
Launch demo modal
</button>
<div class="modal fade"  id="order-form-result" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
   <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content ">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle"></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            Thanks for choosing us and reaching out to us, our team will be happy to assist you and get back to you shortly.
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
         </div>
      </div>
   </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModalLong-disclamer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered  modal-lg " role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle"> Disclaimer</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <!-- {{productdata?.disclaimer}} -->
            <span [innerHtml]="productdata?.disclaimer"></span>
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
         </div>
      </div>
   </div>
</div>


<!-- Button trigger modal -->
<button type="button" class="btn btn-primary d-none" id="m-image-pop"  data-toggle="modal" data-target="#main-image-pop">
   Launch demo modal
   </button>
   <!-- Modal -->
   <div class="modal fade"  id="main-image-pop" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"data-backdrop="static" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
         <div class="modal-content" *ngIf="selectedImg">
            <div class="modal-header py-2">
               <h5 class="modal-title align-items-center" id="exampleModalLongTitle" *ngIf="productdata && productdata.productDetails ">{{productdata.productDetails.ProductName}}
                  <!-- <span class="h6"><small> <span *ngIf="selectedInventoryPro.LotNumber"> Lot# <span>{{selectedInventoryPro.LotNumber}}</span></span> 
                  <span *ngIf="selectedInventoryPro.BundleNumber">, Bundle# <span>{{selectedInventoryPro.BundleNumber}}</span> </span>
                  <span class="mb-0" *ngIf="selectedInventoryPro.AvgSlabLength && selectedInventoryPro.AvgSlabWidth"> &nbsp;size: <span>{{selectedInventoryPro.AvgSlabLength}}x{{selectedInventoryPro.AvgSlabWidth}}   </span> </span>
                  </small> </span> -->
               </h5>
               <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
               </button>
            </div>
            <div class="modal-body p-0">
               <div class="zoom-inv-img-div position-relative" *ngIf="selectedImg">
                  <lib-ngx-image-zoom
                  [thumbImage]=selectedImg
                  [fullImage]=selectedImg
                  [magnification]="1"
                  [enableScrollZoom]="true"
                  [enableLens]="true"
                  [lensWidth]="200"
                  [magnification]="2"
                  ></lib-ngx-image-zoom>
                  <!-- <div class="owl-nav d-flex align-items-center justify-content-between"  *ngIf="selectedInventoryPro['length']  != 1">
                     <button type="button" role="presentation" class="btn btn-light owl-prev"
                     [disabled]=" selectedInventoryPro['index'] == 0" (click)="onclickArraow(selectedInventoryPro, 'pre')">
                     <span aria-label="Previous"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                     </button>
                     <button type="button" role="presentation" class="btn btn-light owl-next"
                     [disabled]="selectedInventoryPro['index']+1 == selectedInventoryPro['length']" (click)="onclickArraow(selectedInventoryPro, 'next')">
                     <span aria-label="Next"><i class="fa fa-chevron-right" aria-hidden="true"></i></span>
                     </button>
                  </div> -->
               </div>
            </div>
       
         </div>
      </div>
   </div>