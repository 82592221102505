import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { searchForm } from '../forms'
import { SharedService, ApiService } from '../service/index';
import { Router, ActivatedRoute } from '@angular/router';

declare let $: any;
declare let jQuery: any;
declare let xmlTojson: any;
@Component({
  selector: 'app-all-product-list',
  templateUrl: './all-product-list.component.html',
  styleUrls: ['./all-product-list.component.css']
})
export class AllProductListComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  tinyUrl2= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';
  inventory;
  searchForm = searchForm;
  locationData;
  productdata;
  filters = [];
  selectedcat;
  selectedcatslug;
  more;
  counter = 0
  params;
  searchedPro;
  location_slug;
  cat_slug;
  filtersdata;
  loadMore = false;
  submitted: boolean= false;
  showFilter = false;
  quartsData
  flag;
  cosmosFilter;
  showInvetory = false;
  selectedLink = 'igm'
  showImages = true;
  selectedProduct;
  location;
  allSlabData = []
  allSlabCosData = []
  allSlabCosuniq = []
  cosmosProduct;
  quartzLocation;
  cosmosInventory;
  selectedImg;
  selectedinvLocation
plachholderImg ='https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images/placeholder.jpg'
  constructor(private fb: FormBuilder, private api: ApiService, public shared: SharedService,
    private activatedRoute: ActivatedRoute,private router: Router) {
      localStorage.setItem('cosmos_search','');
      localStorage.setItem('igm_search','');
      localStorage.setItem('igmfiltersdata','');
        this.flag = undefined
        this.searchForm = this.fb.group({
          search: [null, ''],
        });  
        this.searchForm.patchValue({
          search:null
        })
        let data = this.router.url.split("/");

        this.loadMore = false;
        this.location_slug = data[1];
        this.counter = 0;
        this.productdata = undefined
        this.quartsData = undefined
        this.showFilter = true;
        this.cosmosFilter = undefined;
        this.getCategory();
        this.getSingleLocation();
        this.searchedPro = undefined;
        this.submitted = false
        this.filters = [];
        this.shared.cleardata();
        this.getAllFilters()
        this.getQuartzFilter();
        this.getInventoryDetails();
         
  }
  
  ngOnInit(): void {
    // setTimeout(()=> {

    // },2000)
  }

  ngAfterViewInit(){
    $(document).ready(function() {    
    var options = [];

      $( '.dropdown-menu label' ).on( 'click', function( event ) {

        var $target = $( event.currentTarget ),
            val = $target.attr( 'data-value' ),
            $inp = $target.find( 'input' ),
            idx;

        if ( ( idx = options.indexOf( val ) ) > -1 ) {
            options.splice( idx, 1 );
            setTimeout( function() { $inp.prop( 'checked', false ) }, 0);
        } else {
            options.push( val );
            setTimeout( function() { $inp.prop( 'checked', true ) }, 0);
        }

        $( event.target ).blur();
            
        return false;
      });  

 
      
          
    })




  }

  onScroll() {
    if(this.more != 0 && this.loadMore == false) {
      this.productList()
      this.loadMore = true;
    }
  }


  get f() { return this.searchForm.controls; }

  onSearch(data) {
    this.selectedProduct = undefined;
    this.selectedinvLocation =undefined;
    this.showInvetory = false;
    this.submitted = true;
     // stop here if form is invalid
     if (this.searchForm.invalid) {
      return;
     }
    this.productdata = undefined;
    this.searchedPro = undefined;
    let value = data.search.trim();
      if(value.length >3) {
        // this.filters = [];
       this.loadMore = true;
       this.flag = 'search'
       this.searchByfilter()
       
      }
      if(value.length == 0) {

        this.flag = undefined;
        if(this.filters.length == 0) {
          this.counter = 0;
          if(this.selectedLink == 'Cosmos') {
          } else {
            this.productList();
          }
        } else {
          this.searchByfilter();
        }
 
      }
    
  }

  clearSearch() {
    this.selectedProduct = undefined;
    this.selectedinvLocation =undefined;
    this.showInvetory = false;
    this.searchedPro = undefined;
    this.flag = undefined;
    this.searchForm.patchValue({
      search:null
    })
    if(this.filters.length == 0) {
      this.counter = 0;
      if(this.selectedLink == 'Cosmos') {
        localStorage.setItem('cosmos_search', '')
      } else {
        localStorage.setItem('igm_search', '')
        localStorage.setItem('igmfiltersdata', '')

        this.productList();
      }
    } else {
      if(this.selectedLink == 'Cosmos') {
        localStorage.setItem('cosmos_search', '')
      } else {
        localStorage.setItem('igm_search', '')
      }
      this.searchByfilter();
    }
  

  }

  getAllFilters(){
    this.api.fetchData('allProductFilters',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.filtersdata = res['filters'];
        }else {
        }
  })
}


selectFliter(item, type){
  let name  = '';
  if(type == 'category') {
    name = item.category_name
  }
  if(type == 'color' || type == 'thickness' || type == 'group' ) {
    name = item
  }
  let obj = {
    type: type,
    name: name
  }
  if(type == 'color'  || type == 'group') { 
    let index =  this.filters.findIndex(ind => ind.type == type);
    if(index != -1) {
      this.filters.splice(index,1)
    }
  }
  let y 
  if(type == 'thickness') {
    y = -1;
  } else {
    y  = this.filters.findIndex(f => f.type == type);
  }
  let x  = this.filters.findIndex(f => f.name == name);

  if( y == -1 && x == -1) {
    this.filters.push(obj)
    this.searchByfilter()
  }
}

clearFilter(){
  this.selectedProduct = undefined;
  this.selectedinvLocation =undefined;
  this.showInvetory = false;
  this.searchedPro = undefined;
  this.filters = [];
  if(this.searchForm.value.search && this.searchForm.value.search.length > 0) {
 
    this.loadMore = true;
    this.flag = 'search'
    // this.searchByfilter()
    this.searchByfilter()

  } else {
    if(this.selectedLink != 'igm') {
      this.getCosmosProduct()
    } else {
      this.productList()
      this.counter =0;

    }
  }
}

removeFilter(x) {
  this.filters.splice(x,1);
  if(this.filters.length == 0) {
    localStorage.setItem('igmfiltersdata','')
    this.clearFilter();
  }else {
    this.searchByfilter()
  }
}

checkFilter(event, key, type) {
  let name = ''
  if(type == 'usages' || type == 'Finish' || type == 'origine' ||
  type == 'location' ||  type == 'category') {
    name = key
  }
  if( (type == 'Finish' || type == 'origine')  && event.target.checked) {
      let index =  this.filters.findIndex(ind => ind.type == type);
      if(index != -1) {
        this.filters.splice(index,1)
      }
  }

  let obj = {
    type: type,
    name: name
  }
  let y;
  if(type == 'usage'  ||
  type == 'location' ||  type == 'category') {
    y = -1;
  } else {
    y  = this.filters.findIndex(f => f.type == type);
  }
  if(y == -1 && event.target.checked) {
    this.filters.push(obj)
  } else {
    let x  = this.filters.findIndex(f => f.name == key);
    this.filters.splice(x,1);
    if(this.filters.length == 0) {
      this.clearFilter()
    }
  }
  this.searchByfilter()
}

getCheckedValue(key) {
  let x  = this.filters.findIndex(f => f.name == key);
  return (x == -1) ? false : true;  
}

disabledFilter(key, type) {
  let x  = this.filters.findIndex(f => f.name == key);
  if(x != -1) {
    return false;
  } else {
    let x  = this.filters.findIndex(f => f.type == type);
      return (x == -1) ? false : true;  
  }
}


productList() {
    this.loadMore = true;
    this.selectedProduct = undefined;
    this.selectedinvLocation = undefined;
    this.showInvetory = false;
  this.api.fetchDatalive('allProducts',{all:1 },'GET').subscribe(
    res => {
      if(res['status']==1) {
        this.productdata =  res['data'];
        this.location = res['locations'];
        this.location.map( f => {
          let slug = f.api_slug.replace('IGM Surfaces (',"")
          f.location_text = slug.replace(')',"")
        })
        this.loadMore = false;
        this.createInventorydata(this.productdata)
      }else {
        this.loadMore = false;
        this.productdata  =[];
      }
    }
  )
}

  getSingleLocation() {
    this.api.fetchData('location',{'city_slug' :this.location_slug },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.locationData = res['location'][0]
        } else {
          this.locationData = ''
        }
      }
    )
  }

  searchByfilter(){
    this.selectedProduct = undefined;
    this.selectedinvLocation =undefined;
    this.showInvetory = false;

    if(this.selectedLink == 'Cosmos') {
      this.searchByCosmosFilter()
    }else {
      let color = [];
      let category = [];
      let origine = [];
      let group = [];
      let finish = [];
      let thikness = [];
      let city_slug = [];
      let category_slug = [];
      let search = this.searchForm.value.search ? this.searchForm.value.search : ''
      this.productdata = undefined;
      this.searchedPro = undefined;
      this.inventory = undefined;
      this.showInvetory = false;

      // this.searchForm.patchValue({
      //   search:null
      // })
      if( this.searchForm.value.search) {
        localStorage.setItem('igm_search',this.searchForm.value.search);
      }
      if(this.filters.length > 0) {
        localStorage.setItem('igmfiltersdata',JSON.stringify(this.filters));
      }
      this.filters.map((f,i) => {
        if(f.type == 'color') {
          color.push(f.name)
        }
        if(f.type == 'location') {
          let x = this.filtersdata['alllocations'].findIndex(x => x.city_name == f.name)
          if(x != -1) {
            city_slug.push(this.filtersdata['alllocations'][x]['city_slug'])
   
          }
        }
        if(f.type == 'category') {
          let x = this.filtersdata['category'].findIndex(x => x.category_name == f.name)
          if(x != -1) {
            category_slug.push(this.filtersdata['category'][x]['category_slug'])
   
          }     
         }
        // if(f.type == "category") {
        //   category.push(f.name)
        // }
        if(f.type == "origine") {
          origine.push(f.name)
        }
        if(f.type == "group") {
          group.push(f.name)
        }
        if(f.type == "Finish") {
          finish.push(f.name)
        }
        if(f.type == "thickness") {
          thikness.push(f.name)
        }
        this.loadMore = true;
        if((i+1) == this.filters.length) {
          this.api.fetchDatalive('allProducts',{city_slug:city_slug.toString(),category_slug: category_slug.toString(),
          color:color.toString(),origin:origine.toString(),group:group.toString(),thickness:thikness.toString(),
          finish:finish.toString(),search,'all':1 },'GET').subscribe(
            res => {
              if(res['status'] == 1) {
                if(res['data'].length > 0) {
                  this.searchedPro = res['data'];
                  this.createInventorydata(this.searchedPro)
                  this.loadMore = false;
  
                }else {
                  this.searchedPro = [];
                }
                this.loadMore = false;
              } else {
                this.searchedPro = [];
                this.loadMore = false;
              }
            }
          )
        }
      }) 
      if(this.filters.length == 0 && this.searchForm.value.search && this.searchForm.value.search.length >0) {
        this.api.fetchDatalive('allProducts',{city_slug:city_slug.toString(),category_slug: category_slug.toString(),
          color:color.toString(),origin:origine.toString(),group:group.toString(),thickness:thikness.toString(),
          finish:finish.toString(),search,'all':1 },'GET').subscribe(
            res => {
              if(res['status'] == 1) {
                if(res['data'].length > 0) {
                  this.searchedPro = res['data']
                  this.loadMore = false;
                  this.createInventorydata(this.searchedPro)
                }else {
                  this.searchedPro = [];
                }
                this.loadMore = false;
              } else {
                this.searchedPro = [];
                this.loadMore = false;
              }
            }
          )
  
      }
    }
   
  }

  searchByCosmosFilter(){
    let color = [];
    let category = [];
    let origine = [];
    let group = [];
    let finish = [];
    let thikness = [];
    let city_slug = [];
    let category_slug = [];
    let search = this.searchForm.value.search ? this.searchForm.value.search : ''
    this.cosmosProduct = undefined;
    this.searchedPro = undefined;
    this.inventory = undefined;
    this.showInvetory = false;
    // this.searchForm.patchValue({
    //   search:null
    // })
    this.filters.map((f,i) => {
      if(f.type == 'color') {
        color.push(f.name)
      }
      if(f.type == 'location') {
      
        city_slug.push(f.name)
      }
      if(f.type == 'category') {
        let x = this.cosmosFilter['category'].findIndex(x => x.category_name == f.name)
        if(x != -1) {
          category_slug.push(this.cosmosFilter['category'][x]['category_slug'])
 
        }     
       }
  
      if(f.type == "origine") {
        origine.push(f.name)
      }
      if(f.type == "group") {
        group.push(f.name)
      }
      if(f.type == "Finish") {
        finish.push(f.name)
      }
      if(f.type == "thickness") {
        thikness.push(f.name)
      }
    }) 
    if(this.filters.length == 0 && this.searchForm.value.search && this.searchForm.value.search.length >0) {
      if( this.searchForm.value.search) {
        localStorage.setItem('cosmos_search',this.searchForm.value.search);
      }
      this.getQuartzInventory(search);
    }
  }

  getCategory() {
    this.api.fetchData('product_category',{location:this.location_slug},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }

  scrollTO(){
    setTimeout(() => {
      var elmnt = document.getElementById("accordion");
   let pos =  document.documentElement.scrollTop || document.body.scrollTop;
      if(pos < elmnt.scrollHeight) {
        elmnt.scrollIntoView();
      }

    },200)
  }
  

getClass(key) {
 let x  =  this.filters.findIndex(f => f.type == 'filter_by_images' && f.name  == key);
 return (x != -1) ? true : false;
}

openInventory(item, key){
  let elmnt = document.getElementById(item.ProductName);
  if(this.showInvetory != true )  {
    setTimeout(()=> {
      elmnt.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    },1000)
  }
    this.showInvetory = true;
    this.selectedProduct = item.ProductName
    this.selectedinvLocation =key
    this.inventory  = undefined;
    if(key == 'all') {
      this.location.map((f,i) => {
        this.api.fetchDatalive('getInventry',{'location' :f.city_slug, 'product_name':item.ProductName  },'GET').subscribe(
          res => {
            if(res['status'] == 1) {
             this.inventory = this.inventory == undefined? []: this.inventory
             res['products'].map(k => {
             k['location_name'] = f.city_slug;
             if(k.ProductName.toLowerCase() == this.selectedProduct.toLowerCase()) {
               this.inventory.push(k)
             }
            })
              // this.inventory.products.push(this.inventory.products[0])
              // this.inventory.products.push(this.inventory.products[0])
          
            }else {
              if((i+1) == this.location.length && (this.inventory == undefined ||  (this.inventory && this.inventory.length == 0)  )) {
                this.inventory  = [];
              }
    
            }
          }
        )

      })
    } else {
      this.api.fetchDatalive('getInventry',{'location' :key, 'product_name':item.ProductName  },'GET').subscribe(
        res => {
          if(res['status'] == 1) {
            // res['products'][0].location_name = key;
            res['products'].map(f => {
              f.location_name = key
            })
            res['products'].filter(k => k.ProductName.toLowerCase() == this.selectedProduct.toLowerCase());
            this.inventory = res['products']
            // this.inventory.products.push(this.inventory.products[0])
            // this.inventory.products.push(this.inventory.products[0])
        
          }else {
            this.inventory  = [];
  
          }
        }
      )
    }
  
}

getQuartzFilter() {
  this.api.fetchDataCosmos('allProductFilters',{},'GET').subscribe(
    res => {
      if(res['status'] == 1) {
     this.cosmosFilter = res['filters']
     this.quartzLocation =  Object.keys(res['filters']['alllocations'])
      } else {
        this.quartzLocation  = [];
      }
    }
  )
}

getInventoryDetails(){
  this.loadMore = true;
  this.api.inventory('GetInventoryLots',{Name:'', Lot: '',
  Bundle: '', Location: ''},'GET').subscribe(
    res => {
    let searchKey = localStorage.getItem('igm_search');
    let filterdata = localStorage.getItem('igmfiltersdata');
      if(searchKey == '' && filterdata == '') {
        this.productList()
      }else{
        this.searchForm.patchValue({
          search:searchKey
        });
        if(filterdata != '') {
          this.filters = JSON.parse(filterdata)
        }
        this.onSearch({search:searchKey})
      }
    let data1 = res.replace('<?xml version="1.0" encoding="utf-8"?>',"")
    let data2 = data1.replace('<string xmlns="http://tempuri.org/">',"")
    let data3 = data2.replace('</string>',"")
    let data = JSON.parse(data3);
    this.allSlabData = data;
    data.map(f => {

      let tempArray = [];
      // tempArray = data.filter(recorData => recorData.AvaialbleLocationName ===  f.AvaialbleLocationName && recorData.ProductName == f.ProductName)
      
      f.AvailableSlabs =  data.reduce(function(sum, record){
        if(record.AvaialbleLocationName ===  f.AvaialbleLocationName && record.ProductName.trim() == f.ProductName.trim() && record.ProductStatus == "INSTOCK") {
           return sum + record.AvailableSlabs;
         } else{
            return sum
        }; 
      }, 0);
      f.AvailableQuantity =  data.reduce(function(sum, record){
        if(record.AvaialbleLocationName ===  f.AvaialbleLocationName && record.ProductName.trim() == f.ProductName.trim() && record.ProductStatus == "INSTOCK") {
            // if(record.ProductStatus == "INSTOCK") {
            //   sum + record.AvailableQuantity;
            // }
           return  sum + record.AvailableQuantity;
         } else{
            return sum
        }; 
      }, 0);
   

      
    })
    // 
    // let tempp = this.allSlabData.filter(f => f.ProductName == 'Absolute Black LF 3CM')
    // this.productdata[i]['productData'][index]['inventoryData'] = data;
    // let total = data.reduce(function(sum, record){
    //   if( record.ProductStatus == "INSTOCK") {
    //       // if(record.ProductStatus == "INSTOCK") {
    //       //   sum + record.AvailableQuantity;
    //       // }
    //      return  sum + record.AvailableQuantity;
    //    } else{
    //       return sum
    //   }; 
    // }, 0);
  })
}

getSlab(item,key){
  if( this.allSlabData &&  this.allSlabData.length > 0) {
    let x;
      x =this.allSlabData.findIndex(f => f.AvaialbleLocationName == key && f.ProductName.trim() == item.ProductName.trim())
      if(x != -1) {
        item[key+"_slab"] = true;
        item['slabdata'] = this.allSlabData[x]['AvailableSlabs'];
      }

    return x != -1 ?  item['slabdata'] :0
  }
}
getquantity(item,key){
  if( this.allSlabData &&  this.allSlabData.length > 0  ) {
    let x

      x =this.allSlabData.findIndex(f => f.AvaialbleLocationName == key && f.ProductName.trim() == item.ProductName.trim())
      if(x != -1) {
        item[key+"_qty"] = true;
        item['qtydata'] = parseInt(this.allSlabData[x]['AvailableQuantity']);
      }
    return x != -1 ? item['qtydata']:0
  }
}

getCosmosProduct() {
  // this.loadMore = true
  // this.api.fetchDataCosmos('allProducts',{all:1},'GET').subscribe(
  //   res => {
  //     if(res['status'] == 1) {
  //       this.cosmosProduct = res['data']
  //       this.createInventoryCosdata(this.cosmosProduct)

  //     } else {
  //       this.cosmosProduct = []
  //     }
  //     this.loadMore = false;
  //   }
  // )
  this.cosmosProduct = []
}

createInventorydata(data) {
  data.map(f => {
    f['productData'].map(j => {
      let temp = [];
      this.location.map(child => {
      
        let obj = {
          location_text: child.location_text,
          city_slug: child.city_slug,
          slab:this.getSlab(j, child.api_slug),
          qty:this.getquantity(j, child.api_slug),
        }
        temp.push(obj);
      } )
      j['inventory_data'] = temp;

    })
  })
}
createInventoryCosdata(data) {
  data.map(f => {
    f['productData'].map(j => {
      let temp = [];
      this.quartzLocation.map(child => {
      
        let obj = {
          location_text: child,
          slab:this.getSlabCos(j, child),
          qty:this.getQTYCos(j, child),
        }
        temp.push(obj);
      } )
      j['inventory_data'] = temp;

    })
  })
}

clearData() {
  this.productdata = undefined;
  // this.cosmosFilter = undefined;
  // this.filters = undefined;
  this.searchedPro = undefined;
  this.inventory = undefined;
  this.cosmosInventory = undefined;
  this.showInvetory = false;
  this.cosmosProduct = undefined;
  this.selectedProduct = undefined;
  this.selectedinvLocation = undefined;
  this.filters = [];
  this.searchForm.patchValue({
    search:null
  })
}
 
getKeys(obj) 
{
  return Object.keys(obj)
}

getQuartzInventory(search) {
  this.loadMore = true;
  // https://staging.cosmosgranite.com/api/getLiveInventoryProduct
  this.api.fetchDataCosmoslive('getLiveInventoryProduct', {name: this.searchForm.value.search}, 'GET').subscribe(res => {
    if(res['status'] == 1) {
      this.allSlabCosuniq = JSON.parse(JSON.stringify(res['data']))
      this.allSlabCosData = res['data'] ?  res['data'] : [];
      // this.getCosmosProduct()
        this.allSlabCosData.map((f,i) => {
          let tempArray = [];
          // tempArray =  this.allSlabCosData.filter(recorData => recorData.location ==  f.location && recorData.ProductName == f.ProductName)
        f.AvailableSlabs = this.allSlabCosData.reduce(function(sum, record){
          if(record.location.trim() ===  f.location.trim() && record.ProductName.trim() == f.ProductName.trim()  && record.ProductStatus.trim() == "INSTOCK") {
            return sum + parseFloat(record.AvailableSlabs);
             
           } else{
              return sum
          }; 
        }, 0);
        f.AvailableQuantity =  this.allSlabCosData.reduce(function(sum, record){
          if(record.location.trim() ===  f.location.trim() && record.ProductName.trim() == f.ProductName.trim()&& record.ProductStatus.trim() == "INSTOCK") {
             return sum + parseFloat(record.AvailableQuantity);
           } else{
              return sum
          }; 
        }, 0);
        if((i+1) ==  this.allSlabCosData.length) {
          // this.createInventoryCosdata(this.searchedPro)
          this.api.fetchDataCosmoslive('allProducts',{
            search: search,'all':1 },'GET').subscribe(
              res => {
                if(res['status'] == 1) {
                  if(res['data'].length > 0) {
                    this.searchedPro = res['data']
                    this.createInventoryCosdata(this.searchedPro)
                    this.loadMore = false;
                  }else {
                    this.searchedPro = [];
                  }
                  this.loadMore = false;
                } else {
                  this.searchedPro = [];
                  this.loadMore = false;
                }
              }
            )
        }
       })
       if(this.allSlabCosData.length == 0) {
        this.searchedPro = []
        this.loadMore = false;
       }
 

  } else{
    this.searchedPro = []
  }
  
  })
}

getSlabCos(item,key){
  if( this.allSlabCosData &&  this.allSlabCosData.length > 0) {
    let x;
      x =this.allSlabCosData.findIndex(f => f.location.trim() == key.trim() && f.ProductName.toLowerCase().trim() === item.ProductName.toLowerCase().trim())
      if(x != -1) {
        item[key+"_slab"] = true;
        item['slab'] = this.allSlabCosData[x]['AvailableSlabs'];
        item['qty'] = this.allSlabCosData[x]['AvailableQuantity'];
      }

    return x != -1 ?  parseFloat(item['slab']) :0
  }
}
getQTYCos(item,key){
  if( this.allSlabCosData &&  this.allSlabCosData.length > 0) {
    let x;
      x =this.allSlabCosData.findIndex(f => f.location.trim() == key.trim() && f.ProductName.toLowerCase().trim() == item.ProductName.toLowerCase().trim())
      if(x != -1) {
        item[key+"_slab"] = true;
        // item['slab'] = this.allSlabData[x]['AvailableSlabs'];
        item['qty'] = this.allSlabCosData[x]['AvailableQuantity'];
      }

    return x != -1 ? parseInt( item['qty']) :0
  }
}


openInventorycosmos(name, key){
  let elmnt = document.getElementById(name);
  if(this.showInvetory != true )  {
    setTimeout(()=> {
      elmnt.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    },1000)
  }
  this.showInvetory = true;
  this.selectedProduct = name;
  this.selectedinvLocation =key
  this.cosmosInventory = undefined
//  this.cosmosInventory = this.allSlabCosData.filter(f => f.ProductName == name)
 if(key == 'all') {
  this.quartzLocation.map((f,i) => {
    this.api.fetchDataCosmoslive('getInventry',{'location' :f, 'product_name':name  },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
         this.cosmosInventory = this.cosmosInventory == undefined? []: this.cosmosInventory
         res['products'].map(k => {
          k['location_name'] = f;
          if(k.ProductName.toLowerCase() == this.selectedProduct.toLowerCase()) {
            this.cosmosInventory.push(k)
          }
         })
          // this.inventory.products.push(this.inventory.products[0])
          // this.inventory.products.push(this.inventory.products[0])
      
        }else {
          if(res['status'] == 0 && this.cosmosInventory == undefined) { 
            this.cosmosInventory = [];
          }
          if((i+1) == this.quartzLocation.length &&  this.cosmosInventory && this.cosmosInventory.length == 0) {
            this.cosmosInventory  = [];
          }

        }
      }
    )

  })
} else {
  this.api.fetchDataCosmoslive('getInventry',{'location' :key, 'product_name':name  },'GET').subscribe(
    res => {
      if(res['status'] == 1) {
        if(res['products'].length > 0) {
          res['products'][0].location_name = key;
          res['products'].map(f => {
            f.location_name = key
          })
          this.cosmosInventory = res['products'].filter(k => k.ProductName.toLowerCase().trim() == this.selectedProduct.toLowerCase().trim());
        }
        // this.inventory.products.push(this.inventory.products[0])
        // this.inventory.products.push(this.inventory.products[0])
        if(res['products'].length == 0) {
          this.cosmosInventory  = [];
        }
    
      }else {
        this.cosmosInventory  = [];

      }
    }
  )
}

}

closeInventory() {
  this.showInvetory = false;
  this.selectedProduct= undefined;
  this.cosmosInventory = undefined;
  this.selectedinvLocation = undefined;
}

getTotal(item) {
  let totol = 0;
  let totalSlab = 0;
  item.map(f => {
    totol += parseFloat(f.slab)
    totalSlab += parseFloat(f.qty)
  })
  let obj = {
    totol, totalSlab
  }
  return obj;
}


openImgInv(item) {
  this.selectedImg = item.LotBundlePicture
  document.getElementById('open-inv-img').click()
}

onClickCosmosTab(){
  let searchKey = localStorage.getItem('cosmos_search');
    if(searchKey == '') {
    }else{
      this.searchForm.patchValue({
        search:searchKey
      });
      this.onSearch({search:searchKey})
    }
}
}

