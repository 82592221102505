<main>
   <section class="tringle about-area section-padding2 bg-light">
      <div class="container py-md-5 py-3">
         <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
               <div class="about-caption">
                  <!-- Section Tittle -->
                  <div class="large-title text-left">
                     <!-- <span>Service</span> -->
                     <h2 class="mb-3">projects</h2>
                     
                  </div>
                  <p class="text-left mb-1">
                     Our Commercial Division is ready to help you with your next project, no matter how large or small. We have over 70 years of experience in the commercial natural stone market and now we bring our expertise to the manmade quartz market as well. We can help in the specification process as well as manage the engineering and fabrication and shipping duites. From overseas factories direct to your job site, IGM can make your next project a success. We import many varities of natural stone and manmade quartz from Brazil, China, India, Italy, Spain, Portugal, and Germany.
                  </p>
               </div>
            </div>
            <div class="col-lg-6 col-md-12">
               <div class="owl-carousel owl-theme theme-owl slider4">
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                     <img src="{{tinyUrl}}/gallery/5.jpg" class="rounded m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                     <img src="{{tinyUrl}}/gallery/7.jpg" class="rounded m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                     <img src="{{tinyUrl}}/gallery/9.jpg" class="rounded m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                     <img src="{{tinyUrl}}/gallery/12.jpg" class="rounded m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                     <img src="{{tinyUrl}}/gallery/14.jpg" class="rounded m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                     <img src="{{tinyUrl}}/gallery/17.jpg" class="rounded m-auto w-auto img-fluid">
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="contact section-bg impress_area2 text-white">
      <div class="container-fluid">
         <div class="row justify-content-center">
            <div class="col-lg-12 p-md-5 p-3 text-center">
               <!-- <h2 class="h2-mob">Call today to schedule an appointment with our representative</h2>
               <a class="text-white h1 h1-mob" href="tel:201-869-5200"><i class="fa fa-phone fa mr-2"></i>201-869-5200.</a> -->
               <p class="text-center mt-3 mb-0"><button class="btn btn-theme mb-2 border-0" (click)="onClickBookAppoimnent()" tabindex="0"> Click here to Schedule an appointment.<i  aria-hidden="true" class="ml-2 fa fa-long-arrow-right"></i></button></p>
            </div>
         </div>
      </div>
   </section>
   <section class="py-5" id="projectList">
      <div class="container " >
         <div class="row mb-3 align-items-start justify-content-center">
            <div class="col-xl-4 col-lg-4 text-center">
               <div class="large-title text-center">
                  <!-- <span>Service</span> -->
                  <h2 class="mb-3">projects</h2>
               </div>
               <ul class="list-group mt-lg-5 pt-lg-2 mb-4">
                  <li class="list-group-item p-0 bg-light" *ngFor="let item of projectListdata"> <a class="text-left nav-link "  data-toggle="modal" data-target="#exampleModalLong-project" 
                     (click)="selectedProject = item" href="javascript:void(0)">{{item.Project}}</a></li>
                  <!-- <li class="list-group-item p-0">    <a class="text-left nav-link " data-toggle="modal" data-target="#exampleModalLong-project"
                     (click)="name = 'Classic Appt Charlotte'" href="javascript:void(0)">Classic Appt Charlotte</a></li>
                  <li class="list-group-item p-0 bg-light">   <a class="text-left nav-link "data-toggle="modal" data-target="#exampleModalLong-project" 
                     (click)="name = 'A-1 Appt Dallas'" href="javascript:void(0)">A-1 Appt Dallas</a></li>
                  <li class="list-group-item p-0">     <a class="text-left nav-link " data-toggle="modal" data-target="#exampleModalLong-project"
                     (click)="name = 'Hotel Holiday-inn DC'" href="javascript:void(0)">Hotel Holliday-inn DC</a></li> -->
               </ul>
            </div>
            <div class="col-xl-8 col-lg-8 text-center">
               <ul class="justify-content-center nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item">
                     <a class="nav-link active h4" id="residential" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">RESIDENTIAL</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link h4" id="commercial" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">COMMERCIAL</a>
                  </li>
                  <!-- <li class="nav-item">
                     <a class="nav-link h4" id="projects" data-toggle="pill" href="#pills-project" role="tab" aria-controls="pills-project" aria-selected="false">PROJECT</a>
                     </li> -->
               </ul>
               <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="residential">
                     <div class="row">
                        <div class="col-lg-12 text-center">
                           <button class="btn btn-default filter-button" data-filter="all">All</button>
                           <button class="btn btn-default filter-button" data-filter="kitchen">Kitchen</button>
                           <button class="btn btn-default filter-button" data-filter="bathroom">Bathroom</button>
                           <button class="btn btn-default filter-button" data-filter="livingSpace">Living Space</button>
                           <button class="btn btn-default filter-button" data-filter="bedroom">Bedroom</button>
                           <button class="btn btn-default filter-button" data-filter="landry">Laundry Room</button>
                        </div>
                        <br>
                     </div>
                     <div class="row justify-content-center align-items-center mt-3">
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/1.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 kitchen">
                           <img src="{{tinyUrl}}/gallery/1.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/2.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 kitchen">
                           <img src="{{tinyUrl}}/gallery/2.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/3.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 kitchen">
                           <img src="{{tinyUrl}}/gallery/3.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/4.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 kitchen">
                           <img src="{{tinyUrl}}/gallery/4.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/5.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 kitchen">
                           <img src="{{tinyUrl}}/gallery/5.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/7.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 bathroom">
                           <img src="{{tinyUrl}}/gallery/7.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/8.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 bathroom">
                           <img src="{{tinyUrl}}/gallery/8.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/9.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 livingSpace">
                           <img src="{{tinyUrl}}/gallery/9.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/10.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 livingSpace">
                           <img src="{{tinyUrl}}/gallery/10.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/11.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 bedroom">
                           <img src="{{tinyUrl}}/gallery/11.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/12.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 bedroom">
                           <img src="{{tinyUrl}}/gallery/12.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/13.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter mb-3 px-2 landry">
                           <img src="{{tinyUrl}}/gallery/13.jpg" class="img-fluid">
                        </a>
                     </div>
                  </div>
                  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="commercial">
                     <div class="row">
                        <div class="col-lg-12 text-center">
                           <button class="btn btn-default filter-button2" data-filter="all">All</button>
                           <button class="btn btn-default filter-button2" data-filter="WaitingArea">Waiting Area</button>
                           <button class="btn btn-default filter-button2" data-filter="Reception">Reception</button>
                           <button class="btn btn-default filter-button2" data-filter="Lobby">Lobby</button>
                        </div>
                        <br>
                     </div>
                     <div class="row justify-content-center align-items-center mt-3">
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/14.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2 WaitingArea">
                           <img src="{{tinyUrl}}/gallery/14.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/11.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2 WaitingArea">
                           <img src="{{tinyUrl}}/gallery/15.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/16.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2 Reception">
                           <img src="{{tinyUrl}}/gallery/16.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/17.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2 Reception">
                           <img src="{{tinyUrl}}/gallery/17.jpg" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="{{tinyUrl}}/gallery/18.jpg" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2 Lobby">
                           <img src="{{tinyUrl}}/gallery/18.jpg" class="img-fluid">
                        </a>
                     </div>
                  </div>
                  <!-- <div class="tab-pane fade" id="pills-project" role="tabpanel" aria-labelledby="projects">
                     <div class="row">
                        <div class="col-lg-12 text-center">
                        
                        </div>
                        <br>
                     </div>
                     <div class="row justify-content-center align-items-center mt-3">
                        <div data-toggle="lightbox" data-gallery="gallery" href="assets/images/one.png" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2 WaitingArea">
                           <img src="assets/images/one.png" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="assets/images/Two.png" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2 WaitingArea">
                           <img src="assets/images/Two.png" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="assets/images/Three.png" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2 Reception">
                           <img src="assets/images/Three.png" class="img-fluid">
                        </a>
                        <a data-toggle="lightbox" data-gallery="gallery" href="assets/images/Four.png" class="gallery_product col-lg-4 col-md-4 col-sm-4 col-6 filter2 mb-3 px-2 Reception">
                           <img src="assets/images/Four.png" class="img-fluid">
                        </div>
                      
                     </div>
                     </div> -->
               </div>
            </div>
         </div>
      </div>
   </section>
</main>
<div class="modal fade" id="exampleModalLong-project" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered  modal-lg modal-dialog-scrollable " role="document">
      <div class="modal-content" *ngIf="selectedProject">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle"> {{selectedProject.Project}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body py-2 px-4 bg-light">
           <!--  <p class="my-3 h2 text-center text-uppercase"> Coming Soon </p> -->
            <div class="large-title text-center "><p class="my-3 py-3">
                {{selectedProject.desc}}
            </p></div>
            <!-- <p>
               Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
               Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
               when an unknown printer took a galley of type and scrambled it to make a type specimen book.
               It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.\
               It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.<span ></span>
            </p>
            <p>
               Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
               Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
               when an unknown printer took a galley of type and scrambled it to make a type specimen book.
               It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.\
               It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.<span ></span>
            </p> -->
         </div>
         <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
         </div>
      </div>
   </div>
</div>