<main>
   <section class="tringle about-area section-padding2 bg-light">
      <div class="container py-md-5 py-3">
         <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
               <div class="about-caption">
                  <!-- Section Tittle -->
                  <div class="large-title text-left">
                     <!-- <span>Service</span> -->
                     <h2 class="mb-3">Customer Service</h2>
                  </div>
                  <p class="text-left mb-1">
                     At IGM Surfaces, we give utmost importance to delivering the best customer service. Right from
                     pre-sales to post-sales support, we assist you at every step of the way so that you make the right
                     product choice and also have a hassle-free buying experience.
                  </p>
                  <p class="text-left mb-1">
                     Our customer support team works proactively round the clock to help you choose the best slab
                     material, address your concerns, resolve your queries, and answer any questions you may have. We
                     thus ensure you a pleasant in-store & online experience as our highly experienced and proficient
                     team of customer support executives attend to and meet your specific requirements.
                  </p>
               </div>
            </div>
            <div class="col-lg-6 col-md-12">
               <div class="owl-carousel owl-theme theme-owl slider4 mSliderHeight">
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                        <img src="{{tinyUrl}}/cs/1.png" class="m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                        <img src="{{tinyUrl}}/cs/2.png" class="m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                        <img src="{{tinyUrl}}/cs/3.png" class="m-auto w-auto img-fluid">
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="py-5" id="lcoation">
      <div class="container-fluid px-4">
         <div class="row justify-content-center">
            <div class="col-xl-10 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">IGM Locations</h2>
               </div>
            </div>
         </div>
         <div class="row justify-content-center mt-3 colorRepeat">
            <a *ngFor="let item of location" role="button"
               class="rounded img-shine col-sm-4 col-xl overflow-hd px-1 mb-2 flex-grow-1 change-cursore"
               [ngClass]="{ 'a_disabled': item.city_slug == 'indore' }">
               <div class="rounded color-box owl-content2">
                  <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                  <h4 class="cursor-pointer" (click)="navigateRoute(item.city_slug)">{{item.city_name}}</h4>
                  <p style="clear: both;">
                     <!-- 123 Interstate W. Pkwy., <br>Lithia Springs, GA -->
                     {{item.address}}
                  </p>
                  <p> <i class="fa fa-phone  mb-1" aria-hidden="true"></i> {{item.phone}}</p>
                  <p class="d-flex text-break align-items-start justify-content-center"><i
                        class="fa fa-envelope pr-1 pt-1" aria-hidden="true"></i>{{item.city_mail}}</p>
               </div>
            </a>
         </div>
         <div class="row">
            <div class="col-md-12 text-center igminfobar">
               <p> <span class="home_addr_title"> Home Office:</span> {{getAddres()}} <span
                     *ngIf="getPhone() !== undefined">&nbsp;|&nbsp; Phone: <i class="fa fa-phone  mb-1"
                        aria-hidden="true"></i> {{getPhone()}}</span></p>
            </div>
         </div>
      </div>
   </section>
   <section id="contact" class="contact section-bg impress_area2 text-white">
      <div class="container-fluid">
         <div class="row">
            <div class="col-lg-12 d-flex align-items-stretch contact-form-wrap flex-wrap">
               <div class="row">
                  <div class="d-flex align-items-center col-lg-6 p-md-5 p-3 bg-dark">
                     <div>
                        <div class="col-xl-12 col-md-12 px-sm-0 text-center">
                           <div class="large-title text-left">
                              <h2 class="mb-3 text-white">Get in Touch!</h2>
                              <p class="text-left mb-1">Do you have any concerns, questions, or inquiries regarding any
                                 of our products or services offered in your location? Are you a designer, fabricator,
                                 or architect with some customized requirements, choices, or needs for your commercial
                                 or residential project? Do you or your client have any specific choice while searching
                                 for the perfect slab material for your space or setting?</p>
                              <p class="text-left mb-1">
                                 If any of these rings a bell, then please drop us a message by filling all the details
                                 in the form given below. And our team of experts would get back to you ASAP!
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6 p-md-5 p-3">
                     <form [formGroup]="contactForm" (ngSubmit)="onContactus(contactForm.value)" ngNativeValidate>
                        <div class="form-row">
                           <div class="col-md-6 form-group">
                              <label for="name">Your Name</label>
                              <input type="text" name="first_name" formControlName="first_name"
                                 class="text-white form-control bg-transparent"
                                 [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" id="first_name"
                                 data-rule="minlen:4" data-msg="Please enter at least 4 chars">
                              <div *ngIf="submitted && f.first_name.errors"
                                 class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.first_name.errors.required">Name is required</div>
                              </div>
                           </div>
                           <div class="col-md-6 form-group">
                              <label for="email">Your Email</label>
                              <input type="email" class="text-white form-control bg-transparent" name="email"
                                 formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                 id="email" data-rule="email" data-msg="Please enter a valid email">
                              <div *ngIf="submitted && f.email.errors"
                                 class="invalid-feedback alert alert-danger py-1 px-3">
                                 <div *ngIf="f.email.errors.required">Email is required</div>
                              </div>
                           </div>
                        </div>
                        <div class="form-group">
                           <label for="subject">Subject</label>
                           <input type="text" class="text-white form-control bg-transparent" name="subject"
                              formControlName="subject" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"
                              id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject">
                           <div *ngIf="submitted && f.subject.errors"
                              class="invalid-feedback alert alert-danger py-1 px-3">
                              <div *ngIf="f.subject.errors.required">Subject is required</div>
                           </div>
                        </div>
                        <div class="form-group">
                           <label for="subject">Location</label>
                           <select class="text-white form-control bg-transparent" formControlName="location"
                              [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
                              <option value="" class="text-dark">Please select location</option>

                              <option *ngFor="let item of location" value="{{item.city_name}}" class="text-dark">
                                 {{item.address}}</option>

                              <!-- <option value="Atlanta GA" class="text-dark">Atlanta GA, 123 Interstate W. Pkwy., Lithia Springs, GA</option>
                        <option value="Houston TX" class="text-dark">Houston TX, 8017 Pinemont Dr. #300, Houston, TX</option>
                        <option  value="North Bergen NJ"class="text-dark">North Bergen NJ, 2038 83rd Street, North Bergen, NJ</option>
                        <option  value="Quakertown PA" class="text-dark">Quakertown PA, 2700 Milford Square Pike, Quakertown, PA</option>
                        <option value="Windsor CT" class="text-dark">Windsor CT, 800 Marshall Phelps Rd. #2, Windsor, CT</option> -->
                           </select>
                           <div *ngIf="submitted && f.location.errors"
                              class="invalid-feedback alert alert-danger py-1 px-3">
                              <div *ngIf="f.location.errors.required">Location is required</div>
                           </div>
                        </div>
                        <div class="form-group">
                           <label for="message">Message</label>
                           <textarea class="text-white form-control bg-transparent" name="message" rows="8"
                              formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
                              data-rule="required" data-msg="Please write something for us"></textarea>
                           <div *ngIf="submitted && f.message.errors"
                              class="invalid-feedback alert alert-danger py-1 px-3">
                              <div *ngIf="f.message.errors.required">Message is required</div>
                           </div>
                        </div>
                        <div class="text-center d-flex justify-content-center">
                           <button class="d-flex align-items-center shadow btn btn-theme py-3 px-5 d-flex">Send
                              Message<i class="ml-2 fa fa-long-arrow-right" aria-hidden="true"></i></button>
                        </div>
                        <div *ngIf="loadMore" role="status"
                           class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                           <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                              <div class="d-flex align-items-center">
                                 <img src="{{tinyUrl2}}/loader.svg" class="img-fluid" width="50" />
                                 <p class="mb-0 mt-1 mr-3">Loading...</p>
                              </div>
                           </div>
                        </div>
                        <div *ngIf="message" role="status"
                           class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                           <div class=" py-2 px-5 text-center">

                              <p id="res_message mt-2 text-center" *ngIf="message" class=""> {{message}} </p>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="py-5 bg-white shadow-lg" id="care">
      <div class="container">
         <div class="row align-items-center justify-content-between">
            <div class="col-12 col-md-12 order-md-2 text-center">
               <div class="large-title">
                  <h2 class="mb-3">Care and Cleaning</h2>
               </div>
            </div>
         </div>
         <div class="row align-items-center justify-content-between">
            <div class="img-shine col-12 col-md-12 col-lg-5 order-lg-2 text-right">
               <!-- Image -->
               <img src="{{tinyUrl}}/bgBox/1.jpg" alt="..." class="img-fluid w-lg-50 w-50">
               <!-- Image -->
               <div class="text-left mt-n13 mt-lg-n15 mb-10 mb-md-0">
                  <img src="{{tinyUrl}}/bgBox/4.jpg" alt="..." class="img-fluid w-lg-75 w-50">
               </div>
            </div>
            <div class="col-12 col-md-12 col-lg-7 order-lg-1 mt-lg-0 mt-md-4 mb-4">
               <p class="mb-2">
                  The right approach to stone care and maintenance begins with understanding its geological composition
                  and classification. To preserve the beauty and make it last longer, we must know the properties and
                  characteristics of the specific stone (quartz or natural stone) being taken into consideration.
               </p>
               <p class="m-0">
                  For regular cleaning of the stone, take the guidance of your fabricator and choose the right cleaning
                  products to prevent any issues like etching or dullness of the surface. Also, get the right sealant
                  applied in the case of marble, limestone, quartzite, travertine, or granite stone product, especially
                  when installed in high-traffic areas like kitchens and bath areas.
               </p>
               <p class="m-0">
                  IGM doesn’t provide any warranty for the products. However, if proper care is taken, the stones could
                  do wonders for a longer period. To preserve the beauty of the stone: several points should be kept in
                  mind. For instance, it is recommended to place coasters under the glasses if they contain citrus
                  juices or alcohol. Secondly, mats are required whenever you are placing hot pans on the stone.
               </p>
            </div>
         </div>
         <div class="row">
            <div class="col-xl-6 mb-3">
               <div class="h-100 card mb-3 shadow-sm border-0">
                  <div class="card-header border-0 p-0">
                     <h6 class="mb-0 P-4">
                        <div class="p-3 text-left text-dark w-100">How Cleaning Should be Done</div>
                     </h6>
                  </div>
                  <div class="collapse show">
                     <div class="card-body pb-0 pt-2">
                        <p class="mb-0">Cleaning stone surfaces with mild liquid dishwashing detergent and water keep
                           the stone’s beauty intact for a long time. It is recommended to use a clean rag mop for the
                           best results. To remove soap scum, use a solution of ammonia and water.</p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-xl-6 mb-3">
               <div class="h-100 card mb-3 shadow-sm border-0">
                  <div class="card-header border-0 p-0">
                     <h6 class="mb-0 P-4">
                        <div class="p-3 text-left text-dark w-100"> Cleaning Products</div>
                     </h6>
                  </div>
                  <div class="collapse show">
                     <div class="card-body pb-0 pt-2">
                        <p class="mb-0">Products containing vinegar or lemon might dull the stone’s beauty. Make sure
                           that you do not mix bleach and ammonia, as this creates harmful gases. Scouring creams
                           contain abrasives, which might scratch stones.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-xl-6 mb-3">
               <div class="h-100 card mb-3 shadow-sm border-0">
                  <div class="card-header border-0 p-0">
                     <h6 class="mb-0 P-4">
                        <div class="p-3 text-left text-dark w-100">Sealing the Stone
                        </div>
                     </h6>
                  </div>
                  <div class="collapse show">
                     <div class="card-body pb-0 pt-2">
                        <p class="mb-0">Sealing products are impregnators, which do not act as a sealant but more like a
                           repellant. Sealing makes the stone more resistant to stain. Talk with the experts about
                           sealing procedures, and you might know the best sealing practices. Consult with the specific
                           sealing manufacturer and the frequency to use.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-xl-6 mb-3">
               <div class="h-100 card mb-3 shadow-sm border-0">
                  <div class="card-header border-0 p-0">
                     <h6 class="mb-0 P-4">
                        <div class="p-3 text-left text-dark w-100">Finding Stains and Removing Them
                        </div>
                     </h6>
                  </div>
                  <div class="collapse show">
                     <div class="card-body pb-0 pt-2">
                        <p class="mb-0">After identifying stains, it becomes easier to remove them. Stains can be
                           acidic, metallic, or paint based. If you are still wondering what caused the stain, then do
                           check out for staining agents that are present. Consider asking some questions like - what is
                           its shape or what color is it.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="py-5 bg-light" id="faq">
      <div class="container">
         <div class="row mb-3 align-items-center justify-content-center">
            <div class="col-xl-12 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">FAQ</h2>
                  <p>Here is a list of the most common and frequently asked questions along with their answers. </p>
               </div>
            </div>
         </div>
         <div class="row mb-3 align-items-center justify-content-center">
            <div class="col-xl-10 col-md-12 px-sm-0">
               <div id="accordion">
                  <div class="card mb-3 shadow-sm border-0">
                     <div class="card-header border-0 p-0" id="headingOne">
                        <h5 class="mb-0">
                           <button class="p-3 text-left text-dark btn btn-link w-100" data-toggle="collapse"
                              data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Q. Do all
                              stone countertops require sealing?</button>
                        </h5>
                     </div>
                     <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion"
                        style="">
                        <div class="card-body">
                           <p>The need for sealing any stone depends on its porosity and the stone being considered. In
                              most cases, the porous stones require sealant application to prevent problems like etching
                              and staining. </p>
                           <p>
                              While marble, limestone, sandstone, and onyx require sealing, quartz and soapstone do not
                              require to be sealed. On the other hand, granite and flagstone may need to be sealed
                              sometimes.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="card mb-3 shadow-sm border-0">
                     <div class="card-header border-0 p-0" id="headingTwo">
                        <h5 class="mb-0">
                           <button class="p-3 text-left text-dark btn btn-link collapsed w-100" data-toggle="collapse"
                              data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Q. Should
                              regular cleaning products be used for stones?</button>
                        </h5>
                     </div>
                     <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion"
                        style="">
                        <div class="card-body">
                           <p>No, it is usually not recommended to use regular cleaning products on the stone surfaces.
                              The chemicals in these cleaning products can adversely affect the stone and lead to
                              etching or make them appear dull. It is best to consult the fabricator for specific
                              instructions on cleaning, sealing, and maintenance of the installed stone product. </p>
                        </div>
                     </div>
                  </div>
                  <div class="card mb-3 shadow-sm border-0">
                     <div class="card-header border-0 p-0" id="headingThree">
                        <h5 class="mb-0">
                           <button class="p-3 text-left text-dark btn btn-link collapsed w-100" data-toggle="collapse"
                              data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Q. Why do
                              texture and color vary from slab to slab in the case of natural stone products?</button>
                        </h5>
                     </div>
                     <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion"
                        style="">
                        <div class="card-body">
                           <p>No two natural stone slabs are the same, and each one is unique. As natural stone is a
                              product of nature, it is subject to variations. And this is why for the same stone, the
                              characteristics like mineral deposits and veinings would vary from quarry to quarry. </p>
                        </div>
                     </div>
                  </div>
                  <div class="card mb-3 shadow-sm border-0">
                     <div class="card-header border-0 p-0" id="headingFour">
                        <h5 class="mb-0">
                           <button class="p-3 text-left text-dark btn btn-link collapsed w-100" data-toggle="collapse"
                              data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Q. Is
                              natural stone a great investment? </button>
                        </h5>
                     </div>
                     <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion"
                        style="">
                        <div class="card-body">
                           <p>Natural stones offer great value for money, and the ROI (return on investment) for
                              remodeling projects in kitchens and master baths is more than 90%. They are a long-term
                              investment that can enhance the home value.</p>
                        </div>
                     </div>
                  </div>
                  <div class="card mb-3 shadow-sm border-0">
                     <div class="card-header border-0 p-0" id="headingFive">
                        <h5 class="mb-0">
                           <button class="p-3 text-left text-dark btn btn-link collapsed w-100" data-toggle="collapse"
                              data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Q. Which is
                              a better choice: quartz or natural stone?</button>
                        </h5>
                     </div>
                     <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion"
                        style="">
                        <div class="card-body">
                           <p>While quartz is an engineered stone, marble, granite, limestone & others are purely
                              natural stones. Whether it is natural stones or quartz, both are beautiful, and the choice
                              of stone would depend on the requirements and area of application. </p>
                           <p>If you want to create a high-traffic kitchen, quartz should be used, but natural stone is
                              best suited for ageless kitchen space. The quartz stone doesn’t require sealing and is
                              easy to maintain, and the natural stone requires a sealant application.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>