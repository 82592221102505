import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LocationsComponent } from './locations/locations.component';
import { ProductsComponent } from './products/products.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { CompanyComponent } from './company/company.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { ProjectsComponent } from './projects/projects.component';
import { ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocationdetailComponent } from './locationdetail/locationdetail.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { SelectlocationComponent } from './selectlocation/selectlocation.component';
import { SingleProductCatagoryPageComponent } from './single-product-catagory-page/single-product-catagory-page.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LightboxModule } from 'ngx-lightbox';
import { AgmCoreModule } from '@agm/core';
import { QuartzComponent } from './quartz/quartz.component';
import { QuartzDetailComponent } from './quartz-detail/quartz-detail.component';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { QRCodeModule } from 'angularx-qrcode';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'; // <-- #2 import module
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatterService } from './service/ngb-date-custom-parser-formatter.service';
import {Location} from '@angular/common';
import { AllProductListComponent } from './all-product-list/all-product-list.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';  
import { CookieModule } from 'ngx-cookie';

import { CookieService } from 'ngx-cookie-service';
import { CookiesComponent } from './cookies/cookies.component';
import { ImageuploadComponent } from './imageupload/imageupload.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { CheckForUpdateService } from './service/check-for-update.service';
import { HandleUnrecoverableStateService  } from './service/handle-unrecoverable-state.service';
import { LogUpdateService } from './service/log-update.service';
import { PromptUpdateService } from './service/prompt-update.service';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LocationsComponent,
    ProductsComponent,
    ContactComponent,
    AboutComponent,
    CompanyComponent,
    CustomerServiceComponent,
    ProjectsComponent,
    LocationdetailComponent,
    ProductdetailComponent,
    SelectlocationComponent,
    SingleProductCatagoryPageComponent,
    PrivacyPolicyComponent,
    QuartzComponent,
    QuartzDetailComponent,
    BookAppointmentComponent,
    FileuploadComponent,
    AllProductListComponent,
    PagenotfoundComponent,
    CookiesComponent,
    ImageuploadComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgxImageZoomModule,
    LightboxModule,
     AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCECNx6YKAjaYfP9Eq7FXAMB1QmjUKvMZk'
    }),
    NgxCaptchaModule,
    QRCodeModule,
    RxReactiveFormsModule,
    NgbModule,
    NgxMaterialTimepickerModule,
    CookieModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatterService },Title,Meta,CookieService,
    CheckForUpdateService,
    HandleUnrecoverableStateService,
    LogUpdateService,
    PromptUpdateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 30]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) {
        // anchor navigation
        /* setTimeout is the core line to solve the solution */
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
        })
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

 
}
// const __stripTrailingSlash = (Location as any).stripTrailingSlash;  
// Location.stripTrailingSlash = function (url) {  
//   if (url.endsWith('//')) {  
//     url=url;  
//   }  
 
//   const queryString$ = url.match(/([^?]*)?(.*)/);  
//   if (queryString$[2].length > 0) {  
//     return /[^\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);  
//   }  
//   return /[^\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);  
// }; 