import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { searchForm } from '../forms'
import { SharedService, ApiService, SeoServiceService } from '../service/index';
import { Router, ActivatedRoute } from '@angular/router';


declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-single-product-catagory-page',
  templateUrl: './single-product-catagory-page.component.html',
  styleUrls: ['./single-product-catagory-page.component.css']
})
export class SingleProductCatagoryPageComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  tinyUrl2= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';  
  
  searchForm = searchForm;
  locationData;
  productdata;
  filters = [];
  selectedcat;
  selectedcatslug;
  more;
  counter = 0
  params;
  searchedPro;
  location_slug;
  cat_slug;
  filtersdata;
  loadMore = false;
  submitted: boolean= false;
  showFilter = false;
  quartsData
  flag;
  cosmosFilter;
  popupdata;

  constructor(private fb: FormBuilder, private api: ApiService, public shared: SharedService,
    private activatedRoute: ActivatedRoute,private router: Router, private _seoService: SeoServiceService) {
      this.activatedRoute.params.subscribe((params) => {
        console.log('params', params.type)
        if(params.type == '' ) {
          let url = this.router.url.split("/");
          this.router.navigate(['/'+url[1]]);
        }
        this.api.fetchData('product_category',{},'GET').subscribe(
          res => {
            if(res['status'] == 1) {
             let x  =  res['category'].findIndex(f => f.category_slug == params.type)
             if(x == -1) {
              this.router.navigate(['**']);
             }
            }
          }
        )
        this.flag = undefined
        this.searchForm = this.fb.group({
          search: [null, ''],
        });  
        this.searchForm.patchValue({
          search:null
        })
        this.params = params.type
        let data = this.router.url.split("/");
        localStorage.setItem('location',data[1]);
        localStorage.setItem('pro_catagory',data[2]);
        this.loadMore = false;
        this.location_slug = data[1];
        this.cat_slug = data[2];
        this.counter = 0;
        this.productdata = undefined
        this.quartsData = undefined
        this.showFilter = false;
        this.cosmosFilter = undefined;
        this.getCategory();
        this.getSeoData();
        this.getSingleLocation();
        this.shared.selectedlocation = localStorage.getItem('location') ? localStorage.getItem('location') : null;
        this.searchedPro = undefined;
        this.submitted = false
        this.filters = [];
        this.getAllFilters()
        if(this.shared.resetFilter== true){
          this.shared.resetFilter = undefined;
          this.showFilter = true;
          let data = localStorage.getItem('filtersData');
          this.filters = (data && data.length >0) ?  JSON.parse(data): [];
          if(  this.filters.length > 0) {
          setTimeout(()=> {
              localStorage.removeItem("filtersData");
              this.searchByfilter()
            },2000)
          }else {
            this.productList(0,1)
          }
        } else {
          this.productList(0,1)
        }
       
          if(this.shared.showFilter == true) {
            this.showFilter = true;
            this.shared.showFilter = false;
          }
          this.getPopupdata();

      });
  }
  
ngOnInit(): void {

}

ngAfterViewInit(){
  $(document).ready(function() {    

    var sync1 = $(".slider");
    var sync2 = $(".navigation-thumbs");
    
    var thumbnailItemClass = '.owl-item';
    
    var slides = sync1.owlCarousel({
      video:true,
      startPosition: 12,
      items:1,
      loop:true,
      margin:0,
      autoplay:true,
      autoplayTimeout:6000,
      autoplayHoverPause:false,
      nav: false,
      dots: false
    }).on('changed.owl.carousel', syncPosition);
    
    function syncPosition(el) {
      var $owl_slider = $(this).data('owl.carousel');
      var loop = $owl_slider.options.loop;
    
      if(loop){
        var count = el.item.count-1;
        var current = Math.round(el.item.index - (el.item.count/2) - .5);
        if(current < 0) {
            current = count;
        }
        if(current > count) {
            current = 0;
        }
      }else{
          current = el.item.index;
      }
    
      var owl_thumbnail = sync2.data('owl.carousel');
      var itemClass = "." + owl_thumbnail.options.itemClass;
    
    
      var thumbnailCurrentItem = sync2
      .find(itemClass)
      .removeClass("synced")
      .eq(current);
    
      thumbnailCurrentItem.addClass('synced');
    
      if (!thumbnailCurrentItem.hasClass('active')) {
        var duration = 300;
        sync2.trigger('to.owl.carousel',[current, duration, true]);
      }   
    }
    var thumbs = sync2.owlCarousel({
      startPosition: 12,
      items:4,
      loop:false,
      margin:0,
      autoplay:false,
      nav: false,
      dots: false,
      onInitialized: function (e) {
        var thumbnailCurrentItem =  $(e.target).find(thumbnailItemClass).eq(this._current);
        thumbnailCurrentItem.addClass('synced');
      },
    })
    .on('click', thumbnailItemClass, function(e) {
        e.preventDefault();
        var duration = 300;
        var itemIndex =  $(e.target).parents(thumbnailItemClass).index();
        sync1.trigger('to.owl.carousel',[itemIndex, duration, true]);
    }).on("changed.owl.carousel", function (el) {
      var number = el.item.index;
      var $owl_slider = sync1.data('owl.carousel');
      $owl_slider.to(number, 100, true);
    });
    
    $('.owl-carousel2').owlCarousel({
      loop: true,
      margin: 40,
      //default settings:
      autoplay: true,
      autoplayTimeout: 3500,
      autoplayHoverPause: false,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: false
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
          items: 3,
          nav: false
        }
      }
    });
    
    
        $('.filters ul li').click(function(){
          $('.filters ul li').removeClass('active');
          $(this).addClass('active');
          
          var data = $(this).attr('data-filter');
          $grid.isotope({
            filter: data
          })
        });
        
        var $grid = $(".grid").isotope({
          itemSelector: ".all",
          percentPosition: true,
          masonry: {
            columnWidth: ".all"
          }
        });
    
        
  })


$(document).ready(function(){

$(".filter-button").click(function(){
    $(this).addClass('active');
    var value = $(this).attr('data-filter');
    
    if(value == "all")
    {
        //$('.filter').removeClass('hidden');
        $('.filter').show('1000');
        
    }
    else
    {
//            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
//            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
        $(".filter").not('.'+value).hide('3000');
        $('.filter').filter('.'+value).show('3000');
    
        
    }
});

$('.filter-button').removeClass('active');




$(".filter-button2").click(function(){
    $(this).addClass('active');
    var value = $(this).attr('data-filter');
    
    if(value == "all")
    {
        //$('.filter').removeClass('hidden');
        $('.filter2').show('1000');
        
    }
    else
    {
//            $('.filter[filter-item="'+value+'"]').removeClass('hidden');
//            $(".filter").not('.filter[filter-item="'+value+'"]').addClass('hidden');
        $(".filter2").not('.'+value).hide('3000');
        $('.filter2').filter('.'+value).show('3000');
    
        
    }
});

$('.filter-button2').removeClass('active');

});
}

onScroll() {
  if(this.more != 0 && this.loadMore == false) {
    this.productList(this.counter,2)
    this.loadMore = true;
  }
}


get f() { return this.searchForm.controls; }

onSearch(e) {
  this.submitted = true;
    // stop here if form is invalid
    if (this.searchForm.invalid) {
    return;
    }
  this.productdata = undefined;
  this.searchedPro = undefined;
  let value = e.target.value.trim();
    if(value.length >2) {
      // this.filters = [];
      this.loadMore = true;
      this.flag = 'search'
      this.searchByfilter()
      // this.api.fetchData('filters',{'city_slug' :this.location_slug, category_slug:this.params,
      //     search: value, 'all':1 },'GET').subscribe(
      //       res => {
      //         if(res['status'] == 1) {
      //           if(res['data'].length > 0) {
      //             this.searchedPro = res['data'][0]['productData']
      //             this.loadMore = false;
      //           }else {
      //            this.searchedPro = [];
      //            this.loadMore = false;
      //          }
      //       } else {
      //        this.loadMore = false;
      //         this.searchedPro = [];
      //         }
      //       }
      //     )
    }
    if(value.length == 0) {

      this.flag = undefined;
      if(this.filters.length == 0) {
        this.counter = 0;
        this.productList(0,1);
      } else {
        this.searchByfilter();
      }

    }
  
}

clearSearch() {
  this.searchedPro = undefined;
  this.flag = undefined;
  this.searchForm.patchValue({
    search:null
  })
  if(this.filters.length == 0) {
    this.counter = 0;
    this.productList(0,1);
  } else {
    this.searchByfilter();
  }


}

categoriesByLocation(){
    this.api.fetchData('allfilters',{'city_slug': this.location_slug},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          res['filters']['category'].map(f => {
            let selectedcat =  this.params ? this.params : localStorage.getItem('pro_catagory')
            if(f.category_slug == selectedcat) {
              this.selectedcat = f.category_name;
              this.selectedcatslug = f.category_slug;
            }
          })
        }else {
        }
  })
}

  getAllFilters(){
    this.api.fetchData('allfilters',{'city_slug': this.location_slug,
    category_slug: this.params},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.filtersdata = res['filters'];
        }else {
        }
  })
}


selectFliter(item, type){
  let name  = '';
  if(type == 'category') {
    name = item.category_name
  }
  if(type == 'color' || type == 'thickness' || type == 'group') {
    name = item
  }
  let obj = {
    type: type,
    name: name
  }
  if(type == 'color'  || type == 'group') { 
    let index =  this.filters.findIndex(ind => ind.type == type);
    if(index != -1) {
      this.filters.splice(index,1)
    }
  }
  let y 
  if(type == 'thickness') {
    y = -1;
  } else {
    y  = this.filters.findIndex(f => f.type == type);
  }
  let x  = this.filters.findIndex(f => f.name == name);

  if( y == -1 && x == -1) {
    this.filters.push(obj)
    this.searchByfilter()
  }
}

clearFilter(){
  this.searchedPro = undefined;
  this.filters = [];
  if(this.searchForm.value.search && this.searchForm.value.search.length > 0) {
 
    console.log('search by filters')
    this.loadMore = true;
    this.flag = 'search'
    // this.searchByfilter()
    this.searchByfilter()

  } else {
    this.productList(0,1)
    this.counter =0;
  }
}
removeFilter(x) {
  this.filters.splice(x,1);
  if(this.filters.length == 0) {
    this.clearFilter();
  }else {
    this.searchByfilter()
  }
}

checkFilter(event, key, type) {
  let name = ''
  if(type == 'usages' || type == 'Finish' || type == 'origine') {
    name = key
  }
  if( (type == 'Finish' || type == 'origine')  && event.target.checked) {
      let index =  this.filters.findIndex(ind => ind.type == type);
      if(index != -1) {
        this.filters.splice(index,1)
      }
  }

  let obj = {
    type: type,
    name: name
  }
  let y  = this.filters.findIndex(f => f.type == type);

  if(y == -1 && event.target.checked) {
    this.filters.push(obj)
  } else {
    let x  = this.filters.findIndex(f => f.name == key);
    this.filters.splice(x,1);
    if(this.filters.length == 0) {
      this.clearFilter()
    }
  }
  this.searchByfilter()
}

getCheckedValue(key) {
  let x  = this.filters.findIndex(f => f.name == key);
  return (x == -1) ? false : true;  
}

disabledFilter(key, type) {
  let x  = this.filters.findIndex(f => f.name == key);
  if(x != -1) {
    return false;
  } else {
    let x  = this.filters.findIndex(f => f.type == type);
      return (x == -1) ? false : true;  
  }
}


productList(offset,limit) {


  
  this.loadMore = true;
  this.counter++;
  this.api.fetchData('productlist',{'location' :this.location_slug, 'category_name':this.params,offset:offset*8, limit:8  },'GET').subscribe(
    res => {
      if(res['status']==1) {
        if( res['product']) {
          this.productdata =  this.productdata == undefined ? []:this.productdata
          this.productdata = this.productdata.concat( res['product']);
        }
        this.more  = res['more'];
        this.loadMore = false;
      }else {
        this.loadMore = false;
        this.productdata  =[];
      }
    }
  )
}

getSingleLocation() {
  this.api.fetchData('location',{'city_slug' :this.location_slug },'GET').subscribe(
    res => {
      if(res['status'] == 1) {
        this.locationData = res['location'][0]
      } else {
        this.locationData = ''
      }
    }
  )
}

searchByfilter(){
  let color = [];
  let category = [];
  let origine = [];
  let group = [];
  let finish = [];
  let thikness = [];
  let search = this.searchForm.value.search ? this.searchForm.value.search : ''
  this.productdata = undefined;
  this.searchedPro = undefined;
  // this.searchForm.patchValue({
  //   search:null
  // })
  this.filters.map((f,i) => {
    if(f.type == 'color') {
      color.push(f.name)
    }
    // if(f.type == "category") {
    //   category.push(f.name)
    // }
    if(f.type == "origine") {
      origine.push(f.name)
    }
    if(f.type == "group") {
      group.push(f.name)
    }
    if(f.type == "Finish") {
      finish.push(f.name)
    }
    if(f.type == "thickness") {
      thikness.push(f.name)
    }
    this.loadMore = true;
    if((i+1) == this.filters.length) {
      this.api.fetchData('filters',{'city_slug' :this.location_slug, category_slug:this.params,
      color:color.toString(),origin:origine.toString(),group:group.toString(),thickness:thikness.toString(),
      finish:finish.toString(),search,'all':1 },'GET').subscribe(
        res => {
          if(res['status'] == 1) {
            if(res['data'].length > 0) {
              this.searchedPro = res['data'][0]['productData']
              this.loadMore = false;

            }else {
              this.searchedPro = [];
            }
            this.loadMore = false;
          } else {
            this.searchedPro = [];
            this.loadMore = false;
          }
        }
      )
    }
  }) 
  if(this.filters.length == 0 && this.searchForm.value.search && this.searchForm.value.search.length >0) {
    this.api.fetchData('filters',{'city_slug' :this.location_slug, category_slug:this.params,
      color:color.toString(),origin:origine.toString(),group:group.toString(),thickness:thikness.toString(),
      finish:finish.toString(),search,'all':1 },'GET').subscribe(
        res => {
          if(res['status'] == 1) {
            if(res['data'].length > 0) {
              this.searchedPro = res['data'][0]['productData']
              this.loadMore = false;

            }else {
              this.searchedPro = [];
            }
            this.loadMore = false;
          } else {
            this.searchedPro = [];
            this.loadMore = false;
          }
        }
      )

  }
}

getCategory() {
  this.api.fetchData('product_category',{location:this.location_slug},'GET').subscribe(
    res => {
      if(res['status'] == 1) {
        this.shared.categoryList = res['category']
      }
    }
  )
}

scrollTO(){
  setTimeout(() => {
    var elmnt = document.getElementById("accordion");
  let pos =  document.documentElement.scrollTop || document.body.scrollTop;
    if(pos < elmnt.scrollHeight) {
      elmnt.scrollIntoView();
    }

  },200)
}
  

getClass(key) {
let x  =  this.filters.findIndex(f => f.type == 'filter_by_images' && f.name  == key);
return (x != -1) ? true : false;
}

getSeoData(){
let patname =  this.router.url;
patname = patname.slice(0, 0) + patname.slice(1);

this.api.fetchDatalive('seodata',{page_name: patname},'GET').subscribe(
  res => {
    if(res['status'] == 1) {
    this._seoService.updateTitle(res['data'].title);
    this._seoService.updateDescription(res['data'].description)
//  this._seoService.updateOgUrl(event['ogUrl']);
    }
  }
)
}

saveFiltersData() {
  localStorage.setItem('filtersData',JSON.stringify(this.filters));
  // localStorage.setItem('filtersData',JSON.stringify(this.filters));

}

getPopupdata() {
  // this.cookieService.set( 'Test', 'Hello World' );
  // this.cookieValue = this.cookieService.get('Test');
  // let cookie =this.cookieService.get(this.location_slug);
  let string = this.location_slug+this.params;
  let cookie = this.getCookie(string)
  console.log(cookie);
  let todaydate = new Date();
  todaydate.setDate(todaydate.getDate() + 1);

  if(!cookie) {
    // this.cookieService.put(this.location_slug,'closed',{
    //   expires : todaydate,
    //   httpOnly: true,
    //   secure:true
    // });
    // console.log('runnn')
    
    this.api.fetchData('popupdata',{location:this.location_slug}, 'GET').subscribe(
      res => {
        if(res['response'] == 1) {
          this.setCookie(string, 'closed', todaydate)
          this.popupdata = res['data'];
          setTimeout(()=> {
            document.getElementById('locaion-pop-notification1').click();
          },300)
          setTimeout(()=> {
            document.getElementById('close-popup-loc').click();
          },10000)
        }
      }
    )
  }
}

 setCookie(cname, cvalue, exdays) {
  // var d = new Date();
  // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+exdays.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

 getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


}