<main>
   <section class="py-5 bg-light">
      <div class="container px-4">
         <div class="row justify-content-center">
            <div class="col-xl-10 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">Locations</h2>
               </div>
            </div>
         </div>
         <div class="row justify-content-center mt-3">
            <div class="col-lg-4 col-md-6 text-center" *ngFor="let item of location; let i = index">
               <div class="location-gridbox atlanta-grid mb-4"  [ngClass]="{ 'a_disabled': item.city_slug == 'indore' }">
                  <a  style="background-image: url('{{item.image}}')"  [ngClass]="{ 'a_disabled': item.city_slug == 'indore' }" (click)="selectlocation(item.city_slug)" href="javascript:void(0)" class="img-shine col-12 overflow-hd px-1 mb-2 flex-grow-1"
                 >
                     <!-- <img src="{{item.image}}" alt="" class="img-fluid"> -->
                     <div class="addressBox addressBox2">
                  <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                  <h4>{{item.city_name}}</h4>
                  <p class="mb-2">
                     <!-- 123 Interstate W. Pkwy., <br>Lithia Springs, GA -->
                     {{item.address}}
                  </p>
                  <p class="mb-2"> <i class="fa fa-phone  mb-1" aria-hidden="true"></i>  {{item.phone}}</p>
                  <p class="mb-2"><i class="fa fa-envelope  " aria-hidden="true"></i> {{item.city_mail}}</p>
               </div>
                  </a>
               </div>
            </div>

            <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
               <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
                     <div class="d-flex align-items-center">
                        <img src="{{tinyUrl2}}/loader.svg" class="img-fluid" width="50" /> <p class="mb-0 mt-1 mr-3">Loading...</p>
                     </div>
                  </div>
            </div>
            <!-- <div class="col-lg-4 text-center">
               <div class="location-gridbox atlanta-grid mb-4">
                  <a routerLink="../locationDetails" (click)="selectlocation('quakertown')" href="javascript:void(0)" class="img-shine col-md-4 col-xl-auto overflow-hd px-1 mb-2 flex-grow-1">
                     <img src="https://slabs.igmcorp.net//assets/Site/images/location/atlanta.png" alt="" class="img-fluid">
                     <div class="addressBox">
                  <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                  <h4>Atlanta GA</h4>
                  <p>
                     123 Interstate W. Pkwy., <br>Lithia Springs, GA
                  </p>
               </div>
                  </a>
               </div>
            </div>
            <div class="col-lg-4 text-center">
               <div class="location-gridbox atlanta-grid mb-4">
                  <a routerLink="../locationDetails" (click)="selectlocation('quakertown')" href="javascript:void(0)" class="img-shine col-md-4 col-xl-auto overflow-hd px-1 mb-2 flex-grow-1">
                     <img src="https://slabs.igmcorp.net//assets/Site/images/location/houston.png" alt="" class="img-fluid">
                     <div class="addressBox">
                  <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                  <h4>Houston TX</h4>
                  <p>
                     8017 Pinemont Dr. #300, <br>Houston, TX
                  </p>
               </div>
                  </a>
               </div>
            </div>
            <div class="col-lg-4 text-center">
               <div class="location-gridbox atlanta-grid mb-4">
                  <a routerLink="../locationDetails" (click)="selectlocation('quakertown')" href="javascript:void(0)" class="img-shine col-md-4 col-xl-auto overflow-hd px-1 mb-2 flex-grow-1">
                     <img src="https://slabs.igmcorp.net//assets/Site/images/location/north-bergen.png" alt="" class="img-fluid">
                     <div class="addressBox">
                  <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                  <h4>North Bergen NJ</h4>
                  <p>2038 83rd Street,<br> North Bergen, NJ</p>
               </div>
                  </a>
               </div>
            </div>
            <div class="col-lg-4 text-center">
               <div class="location-gridbox atlanta-grid mb-4">
                  <a routerLink="../locationDetails" (click)="selectlocation('quakertown')" href="javascript:void(0)" class="img-shine col-md-4 col-xl-auto overflow-hd px-1 mb-2 flex-grow-1">
                     <img src="https://slabs.igmcorp.net//assets/Site/images/location/quakertown.png" alt="" class="img-fluid">
                     <div class="addressBox">
                  <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                  <h4>Quakertown PA</h4>
                  <p>2700 Milford Square Pike, <br>Quakertown, PA</p>
               </div>
                  </a>
               </div>
            </div>
            <div class="col-lg-4 text-center">
               <div class="location-gridbox atlanta-grid mb-4">
                  <a routerLink="../locationDetails" (click)="selectlocation('quakertown')" href="javascript:void(0)" class="img-shine col-md-4 col-xl-auto overflow-hd px-1 mb-2 flex-grow-1">
                     <img src="https://slabs.igmcorp.net//assets/Site/images/location/windsor.png" alt="" class="img-fluid">
                     <div class="addressBox">
                  <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                  <h4>Windsor CT</h4>
                  <p>800 Marshall Phelps Rd. #2,<br> Windsor, CT</p>
               </div>
                  </a>
               </div>
            </div> -->
         </div>
      </div>
   </section>
</main>