<main>
   <section class="impress_area pb-5" style="background-image: url('{{tinyUrl}}/bg-location.jpg')">
      <div class="container py-md-5 pt-3 pb-4">
         <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
               <div class="about-caption" *ngIf="locationData">
                  <!-- Section Tittle -->
                  <div class="large-title text-center">
                     <!-- <span>Service</span> -->
                     <h2 class="mb-3"> {{locationData?.city_name}}</h2>
                  </div>
                  <div class="d-flex d-lg-none justify-content-center">
                     <a href="http://maps.google.com/?q={{locationData?.address}}"class="text-left text-md-justify mb-1 d-flex justify-content-center mwI">
                     <i class="animated bounceIn text-dark mr-1 fa fa-map-marker fa-3x" aria-hidden="true" style="-webkit-animation-duration: 3s; animation-duration: 3s; animation-iteration-count: infinite;"></i>                     
                     </a> 
                     <a href="tel:{{locationData?.phone}}" class="text-dark text-justify mb-0 d-flex justify-content-center align-items-center px-3">
                     <i class="animated bell text-dark mr-1 fa fa-phone fa-2x" aria-hidden="true" style="-webkit-animation-duration: 3s; animation-duration: 3s; animation-iteration-count: infinite;"></i>
                     </a>
                     <a href="mailto:{{locationData?.city_mail}}" class="text-justify mb-0 d-flex justify-content-center align-items-center px-3">
                     <i class="animated bounceIn text-dark mr-1 fa fa-envelope fa-2x" aria-hidden="true" style="-webkit-animation-duration: 3s; animation-duration: 3s; animation-iteration-count: infinite;"></i>
                     </a>
                  </div>
                  <div class="d-none d-lg-block">
                     <p class="text-left text-md-justify mb-1 d-flex justify-content-center mwI">
                        <i class="text-dark mr-1 fa fa-map-marker fa-2x" aria-hidden="true"></i>
                        {{locationData?.address}}
                     </p>
                     <div class="mt-2 d-flex justify-content-center text-justify mb-0 d-flex justify-content-center align-items-center flex-wrap">
                        <p class="text-justify mb-0 d-flex justify-content-center align-items-center px-3">
                           <i class="text-dark mr-1 fa fa-phone fa-2x" aria-hidden="true"></i>
                           <a href="tel:{{locationData?.phone}}" class="text-dark">
                           {{locationData?.phone}}
                           </a>
                        </p>
                        <p class="text-justify mb-0 d-flex justify-content-center align-items-center px-3">
                           <i class="text-dark mr-1 fa fa-envelope fa-2x" aria-hidden="true"></i>
                           <a href="mailto:{{locationData?.city_mail}}" class="text-dark">
                           {{locationData?.city_mail}}
                           </a>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row justify-content-center mt-3 mx-0 px-3">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb px-3 bg-white mb-0" *ngIf="locationData">
                     <li class="breadcrumb-item"><a  [routerLink]="['/']" href="javascript:void(0)"  class="text-dark"><i class="fa fa-home"></i></a></li>
                     <li class="breadcrumb-item"><a  [routerLink]="['/selectlocation']" href="javascript:void(0)"  class="text-dark">LOCATIONS</a></li>
                     <li class="breadcrumb-item "  >
                        <a href="javascript:void(0)"   routerLink="/{{locationData.city_slug}}" class="text-dark">
                        {{locationData?.city_name}}
                        </a>
                     </li>
                     <li class="breadcrumb-item "  >
                        <a href="javascript:void(0)"   routerLink="/{{locationData.city_slug}}/{{params}}" class="text-dark">
                        <span  *ngIf="selectedcat ">
                        {{selectedcat | uppercase}}
                        </span>
                        <span  *ngIf="params && !selectedcat">
                        {{params | uppercase}}
                        </span>
                        </a>
                     </li>
                  </ol>
               </nav>
            </div>
         </div>
      </div>
   </section>
   <div class="search-Row">
      <div class="row justify-content-center align-items-center flex-nowrap">
         <div class="col-md-8">
            <div class="w-100 search_container">
               <form [formGroup]="searchForm" 
               (ngSubmit)="onSearch(searchForm.value)"  ngNativeValidate>
               <div class="finder">
                  <div class="finder__outer">
                     <div class="finder__inner">
                        <input class="finder__input"   formControlName="search" (keyup)="onSearch($event)"   type="text" name="q" placeholder="Search...">
                        <div *ngIf="!searchedPro || !flag " class="finder__icon" ref="icon"></div>
                        <div *ngIf="searchedPro  && flag == 'search'" (click)="clearSearch()" >
                        <i class="fa fa-times fa-2x"></i>
                     </div>
                  </div>
               </div>
            </div>
            </form>
         </div>
      </div>
      <div class="col-auto">
      <div class="filterbtn text-center">
           <div *ngIf="!showFilter">
              <button type="button" class="shadow-lg btn btn-dark" (click)="showFilter = true; scrollTO()" ><i class="fa fa-filter"></i></button>
              Filter
           </div>
           <div *ngIf="showFilter" class="primary">
              <button type="button" class="shadow-lg btn btn-primary" (click)="showFilter = false"><i class="fa fa-filter"></i></button>
              Filter
           </div>
        </div>
      </div>
      <!--end of col-->
   </div>
   </div>
   <section class="pt-3 pt-sm-5 pb-5">
      <div class="container-fluid">
         <div class="row">
            <div class="col-md-5 order-md-1 col-lg-3 sidebar-filter" *ngIf="showFilter"></div>
            <div class="col-md-7 order-md-2 col-lg-9 " [ngClass]="{'col-md-12 col-lg-12 ':!showFilter}">
               <div class="row mb-3 align-items-center justify-content-center">
                  <div class="col-xl-10 col-md-12 px-sm-0 text-center">
                     <div class="large-title" *ngIf="locationData">
                        <h2 class="mb-3" *ngIf="selectedcat && !selectedcat">{{selectedcat | uppercase}}</h2>
                        <!-- <img *ngIf="selectedcat && !selectedcat" src="{{tinyUrl}}/COALESCE_LOGO.png" width="200"/> -->
                        
                        <h2 class="mb-3" *ngIf="params != 'quartz'">{{params | uppercase}}</h2>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-md-5 col-lg-4 order-md-1 col-xl-3 sidebar-filter" *ngIf="showFilter">
               <div id="accordion" class="p-3 border">
                  <div class="row d-xl-none">
               <div class="col-lg-12">
                  <ng-container *ngFor="let item of filters; let i = index">
                     <div class="chip ml-1">
                        {{item.name}} <span *ngIf="params != 'quartz'  && item.type == 'thickness'">cm </span>
                        <i class="close fa fa-times"  (click)="removeFilter(i)"></i>
                     </div>
                  </ng-container>
               </div>
            </div>
                  <div class="card mb-2 border-0">
                     <div class="card-header border-0 p-0 bg-transparent border-0" id="headingTwo">
                        <h5 class="mb-0">
                           <button aria-controls="collapseTwo" aria-expanded="false" data-target="#collapseTwo" data-toggle="collapse" class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed">Colors</button>
                        </h5>
                     </div>
                     <div id="collapseTwo" class="collapse" data-parent="#accordion" style="">
                        <div class="card-body border-0 p-0">
                           <ul class="list-group">
                              <li class="list-group-item border-0 px-md-3 p-0" *ngIf="filtersdata">
                                 <ng-container *ngFor="let item of filtersdata.color">
                                    <div class="chip"  (click)="selectFliter(item.ProductColor, 'color')"
                                    [ngClass]="{ 'chip-disable': disabledFilter(item.ProductColor, 'color') }" >{{item.ProductColor}} 
                                     </div>
                                   
                                 </ng-container>
                      
                        </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="card mb-2 border-0"   *ngIf="filtersdata && filtersdata.finish">
                  <div class="card-header border-0 p-0 bg-transparent border-0">
                     <button aria-controls="collapseThree" aria-expanded="false" 
                        data-target="#collapseThree" data-toggle="collapse" class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed" >Finish</button>
                  </div>
                  <div id="collapseThree" class="collapse" data-parent="#accordion" style="">
                     <div class="card-body py-0 px-0 px-md-4">
                       
                        <ul class="list-group border-0 pl-1" >
                           <li class="list-group-item px-0 border-0 position-relative pl-3 py-1" *ngFor="let item of filtersdata.finish; let i = index">
                              <input class="form-check-input" type="checkbox" value="" id="UdefaultCheck1234_{{i}}"
                              (click)="checkFilter($event, item.Finish,'Finish')"
                              [checked]="getCheckedValue(item.Finish)"
                            
                              >
                              <label class="form-check-label" for="UdefaultCheck1234_{{i}}">
                              {{item.Finish}}
                              </label>
                           </li>
                           
                        </ul>
                     </div>
                  </div>
               </div>
              
               <div class="card mb-2 border-0"  *ngIf="filtersdata && filtersdata.thickness" >
                  <div class="card-header border-0 p-0 bg-transparent border-0">
                     <button class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Thickness</button>
                  </div>
                  <div id="collapseFive" class="collapse" data-parent="#accordion" style="">
                     <div class="card-body py-0 px-0 px-md-2">
                        <ul class="list-group">
                           <li class="list-group-item border-0">
                              <div class="chip"  *ngFor="let item of filtersdata.thickness; let i = index"  (click)="selectFliter(item.Thickness,'thickness')"
                              [ngClass]="{ 'chip-disable': disabledFilter(item.Thickness, 'thickness') }"> {{item.Thickness}}cm 
                     </div>
                     </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="card mb-2 border-0"  *ngIf="filtersdata && filtersdata.usages.length > 0" >
               <div class="card-header border-0 p-0 bg-transparent border-0">
                  <button class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">Usages</button>
               </div>
               <div id="collapseTen" class="collapse" data-parent="#accordion" style="">
                  <div class="card-body  px-0 px-md-4 pt-2 border-0">
                     <ul class="list-group border-0 pl-1">
                        <li class="list-group-item px-0 border-0 position-relative pl-3 py-1"
                           *ngFor="let item of filtersdata.usages; let i = index">
                           <input class="form-check-input" type="checkbox" value="" id="qweudefaultCheck1_{{i}}"
                           (click)="checkFilter($event, item.Usages,'usages')"
                           [checked]="getCheckedValue(item.Usages)"
                           [disabled]="disabledFilter(item.Usages, 'usages')">
                           <label class="form-check-label" for="qweudefaultCheck1_{{i}}">
                           {{item.productUsages}}
                           </label>
                        </li>
                       
                     </ul>
                  </div>
               </div>
            </div>
            <div class="card mb-2 border-0" *ngIf="filtersdata && filtersdata.origine">
               <div class="card-header border-0 p-0 bg-transparent border-0">
                  <button class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">Country of Origin</button>
               </div>
               <div id="collapseSeven" class="collapse" data-parent="#accordion" style="">
                  <div class="card-body py-0 px-0 px-md-4">
                    
                     <ul class="list-group border-0 pl-1">
                        <li class="list-group-item px-0 border-0 position-relative pl-3 py-1" 
                           *ngFor="let item of filtersdata.origine; let i = index">
                           <input class="form-check-input" type="checkbox" value="" id="{{item.ProductOrigin}}_{{i}}_{{i}}"
                           (click)="checkFilter($event, item.ProductOrigin,'origine')"
                           [checked]="getCheckedValue(item.ProductOrigin)"
                          >
                           <label class="form-check-label" for="{{item.ProductOrigin}}_{{i}}_{{i}}">
                           {{item.ProductOrigin}}
                           </label>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div class="card mb-2 border-0" *ngIf="filtersdata && filtersdata.group">
               <div class="card-header border-0 p-0 bg-transparent border-0"> 
                  <button class="px-md-3 py-md-2 text-left text-dark btn bg-transparent w-100 border-bottom-1 collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">Product Group</button>
               </div>
               <div id="collapseNine" class="collapse" data-parent="#accordion" style="">
                  <div class="card-body py-0 px-0 px-md-4">
                     <ul class="list-group">
                        <li class="list-group-item cursor-pointer py-md-3 py-1" *ngFor="let item of filtersdata.group"
                         [ngClass]="{ 'chip-disable': disabledFilter(item.ProductGroup, 'group') }" (click)="selectFliter(item.ProductGroup, 'group')">{{item.ProductGroup}}</li>
                        
                     </ul>
                  </div>
               </div>
            </div>
            <div class="col-md-12 text-center">
               <hr>
               <button class="btn btn-dark" (click)="clearFilter()" [disabled]="filters.length == 0">Reset Filter</button>
               <button class="btn btn-dark vFilterBtnS ml-3" (click)="showFilter = false">Apply</button>

            </div>
         </div>
         
      </div>
      <div class="col-md-7 col-lg-8 order-md-2 col-xl-9" [ngClass]="{'col-md-12 col-lg-12 col-xl-12':!showFilter}">
         <div class="grid-repeat">
            <div class="row">
               <div class="col-lg-12">
                  <ng-container *ngFor="let item of filters; let i = index">
                     <div class="chip ml-1">
                        {{item.name}} <span *ngIf="params != 'quartz' && item.type == 'thickness'">cm </span>
                        <i class="close fa fa-times" (click)="removeFilter(i)"></i>
                     </div>
                  </ng-container>
               </div>
            </div>


            <div class="d-flex row productRow mt-2 pl-4" *ngIf="searchedPro  " > 
               <div class="img-shine col-md-6 col-lg-4 col-xl-3 overflow-hd px-1 mb-sm-2 mb-0 flex-grow-1"
                  *ngFor="let item of searchedPro; let  i= index" 
                  >
                  <div class="thumb">
                     <a *ngIf="item.ProductPicture"  
                     routerLink="/{{item.city_slug}}/{{item.category_slug}}/{{item.productSlug}}" target="_blank" (click)="saveFiltersData()"  href="javascript:void(0)" style="background-image: url({{item.ProductPicture}});"></a>
                     <a *ngIf="!item.ProductPicture"
                      routerLink="/{{item.city_slug}}/{{item.category_slug}}/{{item.productSlug}}"  target="_blank" (click)="saveFiltersData()" class="img-placeholder"  href="javascript:void(0)" style="background-image: url({{tinyUrl}}/placeholder.jpg);">
                     </a>
                  </div>
                  <div class="productdeatils">
                     {{item.ProductName}}
                  </div>
               </div>
               <div class="row justify-content-center pt-4 mx-0">
               </div>
               <div  *ngIf="searchedPro.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class=" bg-light py-2 px-5 text-center">
                     <p class="mb-0 mt-1"> No product found.</p>
                  </div>
               </div>
               <hr class="my-5">
            </div>

            <ng-container >
               <div class="d-flex row productRow mt-2 pl-4"  infiniteScroll
               [infiniteScrollDistance]="8"
               [infiniteScrollThrottle]="50"
               (scrolled)="onScroll()" *ngIf="productdata">
               <div class="img-shine col-md-6 col-lg-4 col-xl-3 overflow-hd px-1 mb-sm-2 mb-0 flex-grow-1"
                  *ngFor="let item of productdata; let  i= index" 
                  >
                  <div class="thumb"   (click)="saveFiltersData()">
                     <a *ngIf="item.ProductPicture"   routerLink="/{{item.city_slug}}/{{item.category_slug}}/{{item.productSlug}}"  target="_blank" (click)="saveFiltersData()" href="javascript:void(0)" style="background-image: url('{{item.ProductPicture}}');"></a>
                     <a *ngIf="!item.ProductPicture"   routerLink="/{{item.city_slug}}/{{item.category_slug}}/{{item.productSlug}}" target="_blank"  (click)="saveFiltersData()" class="img-placeholder"  href="javascript:void(0)" style="background-image: url({{tinyUrl}}/placeholder.jpg);">
                     </a>
                  </div>
                  <div class="productdeatils">
                     {{item.ProductName}}
                  </div>
               </div>
               <div class="row justify-content-center pt-4 mx-0" *ngIf="productdata.length" >
               </div>
               <hr class="my-5">
               <div  *ngIf="productdata && productdata.length == 0"class="d-flex flex-wrap col-12 justify-content-center align-items-center">
                  <div class=" bg-light py-2 px-5 text-center">
                     <p class="mb-0 mt-1"> No product found.</p>
                  </div>
               </div>
         </div>
         </ng-container>
  
      <div *ngIf="loadMore" role="status" class="d-flex flex-wrap col-12 justify-content-center align-items-center">
         <div class="bg-light py-0 px-1 text-center rounded shadow-lg">
            <div class="d-flex align-items-center">
               <img src="{{tinyUrl2}}/loader.svg" class="img-fluid" width="50" /> 
               <p class="mb-0 mt-1 mr-3">Loading...</p>
            </div>
         </div>
      </div>
      </div>
      </div>
      </div>
      </div>
   </section>
</main>

<!-- Large modal -->
<button type="button" class="btn btn-primary d-none"  id="locaion-pop-notification1" data-toggle="modal" data-target=".bd-example-modal-lgloc">Large modal</button>

<div class="modal fade bd-example-modal-lgloc " *ngIf="popupdata && popupdata[0]" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content position-relative"> 
       <div class="modal-header" *ngIf="popupdata[0].banner_image">
          <!-- <h5 class="modal-title" id="exampleModalLabel"></h5> -->
            <span class="cross-notification-icon cursor-pointer">
               <span aria-hidden="true" id="close-popup-loc1">&times;</span>
               <button type="button" class="close" id="close-popup-loc" data-dismiss="modal" aria-label="Close"> </button>
            </span>
          <div class="modal-body">
             <img width="100%" src="{{popupdata[0].banner_image}}" alt="">
   
          </div>
       </div>
    </div>
    <div class="modal-content position-relative" *ngIf="!popupdata[0].banner_image" 
    style="background-image: url('{{popupdata[0].bgbanner_image}}')"> 

       <div class="modal-header"   >
          <h5 class="modal-title" id="exampleModalLabel">{{popupdata[0].title}}</h5>
            <span class="">
               <span aria-hidden="true" id="close-popup-loc">&times;</span>
               <button type="button" class="close" id="close-popup-loc" data-dismiss="modal" aria-label="Close"> </button>
            </span>
         </div>
         <div class="modal-body">
            <!-- <img width="100%" src="{{popupdata[0].bgbanner_image}}" alt=""> -->
            <P>{{popupdata[0].content}}</P>
  
         </div>
    </div>
  </div>
</div>