import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service'
import { SharedService } from '../service/shared.service'
import { Clipboard } from '@angular/cdk/clipboard';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  tinyUrl = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  year = new Date().getFullYear();
  address: any;
  copyTextflag = false;
  constructor(private api: ApiService, private shared: SharedService, private clipboard: Clipboard) {

  }

  ngOnInit(): void {
    this.getAddress()
  }

  getAddress() {
    this.api.fetchData('address', {}, 'GET').subscribe(res => {
      if (res['status'] == 1) {
        if (res['data'].length > 0) {
          this.address = res['data'][0]['address'];
          this.shared.homeAddress = this.address;
          this.shared.phone = res['data'][0]['phone'];
        } else {
          this.address = 'N/A';

        }
      } else {
        this.address = 'N/A';
      }
    })
  }

  copyToClip() {
    this.copyTextflag = true;
    let textToBeCopied = this.address;
    console.log(textToBeCopied)
    this.clipboard.copy(textToBeCopied);
    setTimeout(() => {
      this.copyTextflag = false;

    }, 1000)
    // this.activeModal.close();
  }

}
