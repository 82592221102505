<main>
   <section class="tringle about-area section-padding2 bg-light">
      <div class="container py-md-5 py-3">
         <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
               <div class="about-caption">
                  <!-- Section Tittle -->
                  <div class="large-title text-left">
                     <!-- <span>About Us</span> -->
                     <h2 class="mb-3">About Company</h2>
                  </div>
                  <p class="text-left mb-1">
                     Since 1948, IGM Surfaces is a leading provider of domestic & imported slab materials that has been
                     serving the designers, architects, and fabricators for their commercial or residential project
                     requirements. Having a vast experience of more than 70 years, we have been catering to the custom
                     needs of homeowners and professionals by bringing in our broad expertise in the natural stone and
                     engineered quartz market.
                  </p>
                  <p class="text-left mb-1">
                     We specialize in the commercial natural stone market and import different varieties of natural
                     stones and quartz from various countries across the world like Brazil, China, India, Italy, Spain,
                     Portugal, and Germany.
                  </p>
                  <p class="text-left mb-1">
                     From the specification process to managing engineering, fabrication & shipping duties, we have it
                     all covered. We source the premium stone products from overseas factories and deliver them safely
                     to your job site to ensure that your next project is a success.
                  </p>
               </div>
            </div>
            <div class="col-lg-6 col-md-12">
               <div class="owl-carousel owl-theme theme-owl slider4 mSliderHeight">
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                        <img src="{{tinyUrl}}/img_1.png" class="m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                        <img src="{{tinyUrl}}/img_2.png" class="m-auto w-auto img-fluid">
                     </span>
                  </div>
                  <div class="shiny item text-center text-lg-right mt-lg-0 mt-3">
                     <span class="show-off">
                        <img src="{{tinyUrl}}/img_3.png" class="m-auto w-auto img-fluid">
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="bg-dark shadow-lg" id="story">
      <div class="container-fluid ml-0">
         <div class="row">
            <div class="col-md-6 col-lg-4 px-sm-0 pt-sm-0 pt-3">
               <div class="owl-carousel slider5">
                  <div class="item">
                     <img src="{{tinyUrl}}/products/dolomite.jpg" class="img-fluid" />
                  </div>
                  <div class="item">
                     <img src="{{tinyUrl}}/products/granite.jpg" class="img-fluid" />
                  </div>
               </div>
            </div>
            <div class="py-5 d-flex align-items-center py-5 col-md-6 col-lg-8 px-sm-0">
               <div class="card-detail__wrapper shadow-lg text-dark">
                  <div class="card-detail">
                     <article class="card card-text-2">
                        <div class="card-header bg-transparent pb-0 border-0 bg-white">
                           <div class="large-title text-left">
                              <h2 class="mb-0">Our Story</h2>
                           </div>
                        </div>
                        <div class="card-body bg-transparent pt-2">
                           <p class="text-left mb-2">
                              IGM Surfaces is a 70-year-old legacy brand in the commercial natural stone market and has
                              been providing premium-quality domestic and imported slab materials. We have been meeting
                              the custom requirements of several fabricators, architects, and designers for their
                              residential and commercial projects.
                           </p>
                           <p class="text-left mb-2">
                              Through all these years since 1948, we have been evolving and expanding our business
                              consistently across the US. All of our warehouses based in different locations have a team
                              of experts that can help you choose the perfect product and make your project successful.
                              Get in touch with us NOW!
                           </p>
                        </div>
                     </article>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section id="careers" class="img-shine impress_area" style="background-image: url('{{tinyUrl}}/career.jpg')">
      <div class="py-5 container">
         <div class="row align-items-start justify-content-center">
            <div class="col-xl-12 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">Careers</h2>
               </div>
            </div>
            <div class="col-xl-12 col-md-12 text-center">
               <p class="text-left mb-1">We, at IGM Surfaces, work diligently & passionately in our respective teams of
                  well-qualified and experienced professionals to help our clients accomplish their project goals.</p>
               <p class="text-left mb-1">We are always looking for individuals who are passionate about their work, have
                  a deep interest and knowledge of their field or domain, and can build a good relationship with the
                  team and customers.</p>
               <p class="text-left mb-1">Check out our latest openings here! And if you think you are a perfect fit for
                  any of the roles, please send us a cover letter with your resume. Our concerned team would review it
                  and would get back to you shortly!</p>
            </div>
            <div class="col-xl-12 col-md-12 text-center">
               <h2 class="text-left mt-3 h2-mob">Current Opening</h2>
               <p class="text-left mb-3">
                  We sincerely welcome your job applications as we're always looking for well-experienced and skilled
                  professionals. Please do share your resume with us at <a href="mailto:career@igmcorp.com"
                     class="big">career@igmcorp.com</a>.
               </p>
               <table class="table table-light shadow-lg text-left">
                  <thead class="thead-dark">
                     <tr>
                        <th scope="col">#</th>
                        <th scope="col">Title</th>
                        <th scope="col">Job Distribution</th>
                     </tr>
                  </thead>
                  <tbody>
                     <!-- <tr>
                        <th scope="row">1</th>
                        <td>Sales officer Distribution</td>
                        <td>This is a front line sales position within IGM, to be based at assigned area office.</td>
                     </tr> -->
                     <tr>
                        <th scope="row">1</th>
                        <td>Procurement Specialist (Houston, TX)</td>
                        <td>Procurement Specialist to drive strategic sourcing initiatives to improve the
                           quality of products; manage supplier overhead; provide progress expertise to execute sourcing
                           strategies; participate in international trade fairs; negotiate purchase price and
                           contractual terms and conditions; conduct business and process analysis identifying areas to
                           improve; monitor suppliers and oversee inventory control activities. Req.: Associate’s degree
                           (or foreign equivalent) in Business Administration or a related field of study with 3 years
                           of experience (any title) or in the alternate B.S. degree (or foreign equivalent) in Business
                           Administration or a related field of study with 1 year of experience (any title); Experience
                           to include working as a sourcing strategies, international trade fair, negotiating purchase
                           price, business process analysis, & inventory control. Travel to various unanticipated
                           location within the U.S. possible. Resume: IGM Surfaces LLC DBA IGM Corp, 501 S New Hope Rd,
                           Raleigh, NC 27610. Refer: NCSG22.</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   </section>
   <section class="text-dark">
      <div class="pt-5 container">
         <div class="row justify-content-around align-items-start">
            <div class="col-md-12 col-lg-12">
               <div class="about-content-right mb-md-4 mb-lg-0">
                  <div class="large-title text-center">
                     <h2 class="mb-3">Professionals</h2>
                  </div>
                  <p>At IGM Surfaces, we provide a premium range of stone products and best-in-class services to several
                     industry professionals, ranging from architects to designers to fabricators.</p>
                  <p>We offer diverse stone choices like Quartz, Granite, Marble, Quartzite, Porcelain, Dolomite,
                     Limestone, Calcite, Slate, and Soapstone to professionals from different sectors for their
                     projects. These products are all durable and come in myriad colors, textures, patterns, and
                     finishes and are ideal for both residential and commercial indoor and outdoor settings.</p>
               </div>
            </div>
         </div>
      </div>
      <div class="container-fluid" id="Professionals">
         <div class="mt-4 row justify-content-around align-items-start" id="accordion">
            <div class="col-lg-12 d-flex flex-wrap px-0">
               <div class="col-lg-3 col-md-6 col-sm-12 px-0  color-1 color-tab-2">
                  <div class="box-1 border-0">
                     <div class="border-0 p-0" id="headingOne">
                        <h5 class="mb-0">
                           <button id="Architect"
                              class="ensure-quality-block py-3 p-md-3 p-xl-5 text-left text-dark btn btn-link w-100"
                              data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                              aria-controls="collapseOne">
                              <span class="d-flex align-items-center flex-wrap justify-content-center">
                                 <img src="{{tinyUrl}}/icon/architect-light.svg" height="100">
                                 <p class="text-white col-12 mt-3 mb-0 mb-md-3 text-center px-0">Architect</p>
                              </span>
                           </button>
                        </h5>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-12 px-0  color-2 color-tab-1">
                  <div class="box-1 border-0">
                     <div class="border-0 p-0" id="headingTwo">
                        <h5 class="mb-0">
                           <button id="Designer"
                              class="ensure-quality-block py-3 p-md-3 p-xl-5 text-left text-dark btn btn-link w-100 collapsed"
                              data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                              aria-controls="collapseTwo">
                              <span class="d-flex align-items-center flex-wrap justify-content-center">
                                 <img src="{{tinyUrl}}/icon/interior-design-light.svg" height="100">
                                 <p class="text-white col-12 mt-3 mb-0 mb-md-3 text-center px-0">Designer</p>
                              </span>
                           </button>
                        </h5>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-12 px-0  color-1 color-tab-1">
                  <div class="box-1 border-0">
                     <div class="border-0 p-0" id="headingThree">
                        <h5 class="mb-0">
                           <button id="Kitchen"
                              class="ensure-quality-block py-3 p-md-3 p-xl-5 text-left text-dark btn btn-link w-100 collapsed"
                              data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                              aria-controls="collapseThree">
                              <span class="d-flex align-items-center flex-wrap justify-content-center">
                                 <img src="{{tinyUrl}}/icon/kitchen-light.svg" height="100">
                                 <p class="text-white col-12 mt-3 mb-0 mb-md-3 text-center px-0">Kitchen and Bath
                                    Studios</p>
                              </span>
                           </button>
                        </h5>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-12 px-0  color-2  color-tab-2">
                  <div class="box-1 border-0">
                     <div class="border-0 p-0" id="headingFour">
                        <h5 class="mb-0">
                           <button id="Fabricators"
                              class="ensure-quality-block py-3 p-md-3 p-xl-5 text-left text-dark btn btn-link w-100 collapsed"
                              data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                              aria-controls="collapseFour">
                              <span class="d-flex align-items-center flex-wrap justify-content-center">
                                 <img src="{{tinyUrl}}/icon/fabric-light.svg" height="100">
                                 <p class="text-white col-12 mt-3 mb-0 mb-md-3 text-center px-0">Fabricators</p>
                              </span>
                           </button>
                        </h5>
                     </div>
                  </div>
               </div>
            </div>
            <div class="w-100 px-0">
               <div class="col-lg-12 px-0">
                  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion"
                     style="">
                     <div class="border-top-0 container box-1 pt-4 card-body mb-5">
                        <p>An architect lends a functional and beautiful touch by introducing aesthetic and durable
                           elements into the setting, be it traditional and contemporary. Right from designing the
                           layout to bringing it into reality, they can improve the visual appeal and help transform
                           your residential or commercial space with adept use of architectural materials.</p>
                        <p>For over seven decades, the architects from the US, India, and other parts of the globe have
                           been choosing from our wide range of supreme quality quartz stones and different types of
                           natural stone products like granite, marble, porcelain, and others to meet their unique
                           project needs.</p>
                        <p>So if you are an architect, reach out to us at IGM Surfaces for more details and further
                           assistance!

                        </p>
                     </div>
                  </div>
                  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion" style="">
                     <div class="border-top-0 container box-1 pt-4 card-body mb-5">
                        <p>The discerning designers have a knack for designing any interior or exterior space while
                           keeping in mind the style of that setting. They know how to retain the essence of the space
                           and can transform it with the right blend of colors, textures, and patterns.</p>
                        <p>For multiple residential and commercial uses, we help designers in making the right choice of
                           stone products that would perfectly suit the project. These products not only add durability
                           & beauty to space, but they also come with various other beneficial features & properties.
                        </p>
                        <p>So are you a designer planning to revamp a space or creating one from scratch? If yes, then
                           IGM Surfaces has you covered! We would be happy to discuss it in detail and cater to all your
                           custom designing needs.

                        </p>
                     </div>
                  </div>
                  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion"
                     style="">
                     <div class="border-top-0 container box-1 pt-4 card-body mb-5">
                        <p>The kitchen and bath areas are high-utility areas and may require high maintenance. The
                           typical applications in this space include shower surrounds, tabletops, countertops,
                           vanities, backsplashes, walls, flooring, sinks, and others.</p>
                        <p>All these applications are prone to daily wear and tear, chemical attack, thermal shock,
                           stains, skid/slip, dust, cracks, and moisture. So since the kitchens & baths require elegant
                           and functionally durable stone products that are easy to clean and maintain, our quartz &
                           natural stone products are a perfect pick and can last long without even losing their sheen.
                        </p>
                        <p>So do you have any kitchen & bath projects to build from scratch or renovate? If yes, let us
                           know about your specific requirements & our expert team would help you choose the right
                           product.

                        </p>
                     </div>
                  </div>
                  <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion"
                     style="">
                     <div class="border-top-0 container box-1 pt-4 card-body mb-5">
                        <p>The stone fabricators turn a raw or rough stone into a refined stone by achieving the right
                           size, edge, and shape as per clients’ needs. They help homeowners & professionals in creating
                           true masterpieces by building their dream commercial and residential spaces. </p>
                        <p>In the form of both tiles and slabs, IGM Surfaces offers the highest quality quartz products
                           and various natural stones like granite, dolomite, limestone & marble that are widely known
                           to be an ideal and truly practical choice for building a robust, low-maintenance, and
                           beautiful setting. </p>
                        <p class="">If you are a professional stone fabricator, we can help you choose from our
                           wide-ranging stone products for any and every project requirement you may have. Contact us
                           right away for expert assistance!
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>

<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true">
   <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Your Customer Appointment
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            ...
         </div>
         <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button> -->
         </div>
      </div>
   </div>
</div>