import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { contactForm } from '../forms';
import { ApiService, SharedService, SeoServiceService } from '../service/index';
import { AgmCoreModule } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';

declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  tinyUrl = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  contactForm = contactForm;
  message
  contactFormres
  loadMore
  location = []
  public map: any = { lat: 39.7797003, lng: -86.272835 };
  // 39.9526° N, 75.1652° W

  getLatLng = [
    { lat: 33.771191, lng: -84.598640, name: 'Atlanta GA', location: 'Atlanta GA, 123 Interstate W. Pkwy.,Lithia Springs, GA' },
    { lat: 29.839710, lng: -95.511550, name: 'Houston TX', location: 'Houston TX 8017 Pinemont Dr. #300, Houston, TX' },
    { lat: 40.809870, lng: -74.016660, name: 'North Bergen NJ', location: 'North Bergen NJ 2038 83rd Street, North Bergen, NJ' },
    // { lat: 40.439610, lng: -75.375960, name: 'Quakertown PA' , location: 'IGM Phildelphia, 725 County Line Road\r\nHuntingdon Valley PA 19006'},
    { lat: 39.9526, lng: -75.1652, name: 'PHILADELPHIA PA', location: 'IGM Philadelphia, 725 County Line Road\r\nHuntingdon Valley PA 19006' },
    { lat: 41.890120, lng: -72.673760, name: 'Windsor CT', location: 'Windsor CT 800 Marshall Phelps Rd. #2, Windsor, CT' },
  ]
  constructor(private fb: FormBuilder, private api: ApiService, private shared: SharedService, private _seoService: SeoServiceService, private router: Router,) {
    this.shared.cleardata();
    this.loadMore = false;

    this.getCategory();
    this.getLocation()
    this.getSeoData()
  }
  submitted: boolean = false;
  ngOnInit(): void {
    this.contactFormres = undefined;
    this.contactForm = this.fb.group({
      first_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      location: ['', Validators.required],
    });
    this.message = ''
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      $('.slider4').owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        //animateOut: 'flipOutX',
        animateIn: 'fadeInRight',
        smartSpeed: 450,
        //default settings:
        autoplay: true,
        autoplayTimeout: 3500,
        nav: false,
        responsive: {
          0: {
            items: 1,
            margin: 0,
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });

    })

  }
  get f() { return this.contactForm.controls; }

  onContactus(d) {
    this.loadMore = true;
    this.submitted = true;
    this.message = '';
    this.contactForm     // stop here if form is invalid
    if (this.contactForm.invalid) {
      this.loadMore = false;
      return;
    }

    let formData: FormData = new FormData();
    formData.append('location_name', d.location,);
    formData.append('Name', d.first_name);
    formData.append('Email', d.email);
    formData.append('Message', d.message);
    formData.append('Subject', d.subject);

    this.api.fetchData('contactpage', formData, "POST").subscribe(res => {
      this.contactFormres = res
      if (res.status == 1) {
        this.contactForm.reset();
        this.submitted = false;
        this.loadMore = false;
        this.message = res.msg
        setTimeout(() => {
          this.message = '';
        }, 3000)
      } else {
        this.contactFormres = res
        this.message = res.msg
        this.submitted = false;
        this.loadMore = false;
      }
    })
  }






  getCategory() {
    this.api.fetchData('product_category', {}, 'GET').subscribe(
      res => {
        if (res['status'] == 1) {
          this.shared.categoryList = res['category']
        }
      }
    )
  }

  getLocation() {
    this.api.fetchData('alllocation', {}, 'GET').subscribe(
      res => {
        if (res['status'] == 1) {
          this.location = res['location'];
        } else {
          this.location = [];

        }
      }
    )
  }

  getSeoData() {
    let patname = window.location.pathname;
    patname = patname.slice(0, 0) + patname.slice(1);

    this.api.fetchData('seodata', { page_name: 'contact' }, 'GET').subscribe(
      res => {
        if (res['status'] == 1) {
          this._seoService.updateTitle(res['data'].title);
          this._seoService.updateDescription(res['data'].description)
          //  this._seoService.updateOgUrl(event['ogUrl']);
        }
      }
    )
  }
  getAddres() {
    return this.shared.homeAddress;
  }
  getPhone() {
    return this.shared.phone;
  }
  navigateRoute(url) {
    this.router.navigate(["/" + url]);
  }
}
