import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { CompanyComponent } from './company/company.component';
import { ContactComponent } from './contact/contact.component';
import { CustomerServiceComponent } from './customer-service/customer-service.component';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { HomeComponent } from './home/home.component';
import { LocationdetailComponent } from './locationdetail/locationdetail.component';
import { LocationsComponent } from './locations/locations.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProductdetailComponent } from './productdetail/productdetail.component';
import { ProductsComponent } from './products/products.component';
import { ProjectsComponent } from './projects/projects.component';
import { QuartzDetailComponent } from './quartz-detail/quartz-detail.component';
import { QuartzComponent } from './quartz/quartz.component';
import { SelectlocationComponent } from './selectlocation/selectlocation.component';
import { SingleProductCatagoryPageComponent } from './single-product-catagory-page/single-product-catagory-page.component';
import  { AuthGuard } from './auth.guard'
import { AllProductListComponent } from './all-product-list/all-product-list.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ImageuploadComponent } from './imageupload/imageupload.component';

const routes: Routes = [
  // { path: 'home', component: HomeComponent },
  { path: '',  component: HomeComponent  }, 
  { path: 'selectlocation', component: SelectlocationComponent},
  { path: 'location', component: LocationsComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'company', component: CompanyComponent},
  { path: 'customerService', component: CustomerServiceComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  // { path: 'locationDetails', component: LocationdetailComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'atlanta', component: LocationdetailComponent },
  { path: 'houston', component: LocationdetailComponent },
  { path: 'north-bergen', component: LocationdetailComponent },
  { path: 'quakertown', component: LocationdetailComponent },
  { path: 'windsor', component: LocationdetailComponent },
  // { path: 'indore', component: LocationdetailComponent },
  { path: 'atlanta/:type', component: SingleProductCatagoryPageComponent },
  { path: 'houston/:type', component: SingleProductCatagoryPageComponent },
  { path: 'north-bergen/:type', component: SingleProductCatagoryPageComponent},
  { path: 'quakertown/:type', component: SingleProductCatagoryPageComponent },
  { path: 'windsor/:type', component: SingleProductCatagoryPageComponent},
  // { path: 'indore/:type', component: SingleProductCatagoryPageComponent},
  { path: 'atlanta/:type/:type', component: ProductdetailComponent },
  { path: 'houston/:type/:type', component: ProductdetailComponent },
  { path: 'north-bergen/:type/:type', component: ProductdetailComponent},
  { path: 'quakertown/:type/:type', component: ProductdetailComponent },
  { path: 'windsor/:type/:type', component: ProductdetailComponent },
  // { path: 'indore/:type/:type', component: ProductdetailComponent },
  { path: 'quartz', component: QuartzComponent },
  { path: 'quartz/:type', component: QuartzDetailComponent },
  { path: 'book-appointment', component: BookAppointmentComponent },
  { path: 'admin/fileupload/:type', component: FileuploadComponent,canActivate:[AuthGuard] },
  { path: 'admin/imageUpload/:type', component: ImageuploadComponent,canActivate:[AuthGuard] },
  { path: 'admin/cgminternal', component: AllProductListComponent ,  canActivate:[AuthGuard]},
  { path: 'page-not-found', component: PagenotfoundComponent },
	{ path: '**', redirectTo: '/page-not-found' }];

  
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 30],
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
