import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { SharedService, ApiService } from '../service/index';
declare let $: any;
declare let jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: []
})
export class HeaderComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/igmcorp/assets/images';
  tinyUrl2= 'https://cosmosgranite.nyc3.digitaloceanspaces.com/igmcorp/assets/images';  
  newArival = [];
  AlllocationList = [];
  location;
  params
  hoveredDivId = false
  defaultDivStyles= {display: 'none'};
  hoveredDivStyles= {display: 'block'};
  constructor(private router: Router, public shared: SharedService, private api: ApiService, private activatedRoute: ActivatedRoute) { 
    this.newArival = [];
    this.AlllocationList = [];
    this.location = undefined
    this.newArrival();
    this.getLocation();
    this.location = localStorage.getItem('location') ? localStorage.getItem('location') : null;
    this.shared.selectedlocation = localStorage.getItem('location') ? localStorage.getItem('location') : null;
   
    let data = this.router.url.split("/");
    // let data = window.location.pathname.split("/");
    this.params = data[1];

  }

  ngOnInit(): void {
    this.router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        let eleId = document.getElementById("igm-body");
        eleId.classList.remove("open-menu-body");
      }
    });
  }
 ngAfterViewInit() {
  $(document).ready(function() {
    if ($(window).width() >= 992) {
      $('.headeDesign .dropdown').click(function () {
        let ele = $(this).find(".dropdown-large")
        $(this).find(".dropdown-large").css('display', 'none');
  
      });
      $('.headeDesign .dropdown').mouseleave(function () {
        $(this).find(".dropdown-large").css('display', 'none');
  
      });
      $('.headeDesign .dropdown').mouseover(function () {
        $(this).find(".dropdown-large").css('display', 'block');
      });
    } else if ($(window).width() <= 991) {
      $('.headeDesign li a').click(function () {
        $(".navbar-collapse").collapse('hide');
        $(".dropdown-large").hide('add');
        $(this).find(".dropdown-menu").css('display', 'none');
      });
      $('.mob-toggle').click(function () {
        $(".dropdown-large").removeClass('add');
        $(this).parent(".mob-droupdown").find(".dropdown-large").toggle('add');
      });
    }
});
}
 mobileToggle(){
    $(".navbar-collapse").collapse('hide');
      $(".dropdown-large").hide('add');
      $(this).find(".dropdown-menu").css('display', 'none');
 }
  goto(cat){
    // localStorage.setItem('pro_catagory',cat)
    //  this.location = localStorage.getItem('location');
    // if(this.location != null) {
    //   this.router.navigate(['/'+location+'/'+cat]);
    // } else {
    //   this.router.navigate(['/selectlocation'], { queryParams: {'category': cat}});
    //   this.shared.selectedCat = cat
    // }

  }
  newArrival() {
    // this.api.fetchData('newarraival',{ limit: 4},'GET').subscribe(
    //   res => {
    //     this.shared.newArival = res;
    //   }
    // )
    this.api.fetchDataCosmos('getNewArrivalProduct', {page:1,website:'igm' },'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.shared.newArival = res['product'];
        } else {
          this.shared.newArival = [];
        }
      }
    )
  }

  
  getLocation() {
    this.api.fetchData('alllocation',{},'GET').subscribe(
      res => {
        if(res['status'] == 1) {
          this.AlllocationList =   res['location'];
        
        } else {
          this.AlllocationList =  [];

        }
      }
    )
  }

  findLocationName(key) {
    // console.log(this.AlllocationList)
    if(this.AlllocationList.length == 0) {
      return ''
    } else {
      let item  = this.AlllocationList.filter(f => f.city_slug == key )
      // console.log(item);
     return item&& item.length ? item[0]['city_name'] : ''
    }
  }

  getItemLength() {
   let length =  this.shared.categoryList.length 
   if(length > 2 && length <= 5) {
     return 3
   }
   if(length > 5 && length <= 8) {
     return 3
   }
   if(length > 8) {
     return 4
   }
    
  }

  showDivWithHoverStyles(divId: number) {
    if(divId == 1){
      this.hoveredDivId = true;
    }
  }

  showAllDivsWithDefaultStyles() {
    this.hoveredDivId = false;
  }

  appendBodyCls(){
    let eleId = document.getElementById("igm-body");
    let btnvalue = document.getElementById('togglebtn').getAttribute('aria-expanded');
    
    if(btnvalue == null || 'false'){
      eleId.classList.add("open-menu-body");
    }if(btnvalue == 'true'){      
      eleId.classList.remove("open-menu-body");
    }
  }
}
