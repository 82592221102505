<main>
   <section>
      <div class="container-fluid">
         <div class="row">
            <div class="owl-carousel owl-theme theme-owl hero-slider" *ngIf="sliderData">
               <div class="item" *ngFor="let item of sliderData; let i = index"
                  style="background-image: url({{item.image}})">
                  <div class="owl-overlay"></div>
                  <div class="owl-content shadow">
                     <h3>{{item.text}}</h3>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="py-md-5 py-3 bg-white">
      <div class="container-fluid">
         <div class="row mb-3 align-items-center justify-content-center">
            <div class="col-xl-10 col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">FIND YOUR PERFECT MATCH</h2>
                  <p class="mb-4">
                     We offer a wide array of stone products, ranging from quartz, porcelain to natural stones like
                     marble, granite, quartzite, dolomite, limestone, travertine and soapstone, to make your space look
                     beautiful and last for years long.
                  </p>
               </div>
            </div>
         </div>
         <div class="d-flex row productRow mt-2 pl-4">
            <div class="img-shine col-sm-4 col-xl-2 overflow-hd px-1 mb-2 flex-grow-1">
               <div class="thumb">
                  <a href="javascript:void(0)" [routerLink]="['/quartz']"
                     style="background-image: url({{tinyUrl}}/products/quartz.jpg)">
                  </a>
               </div>
               <div class="productdeatils">Coalesce Quartz </div>
            </div>
            <div class="img-shine col-sm-4 col-xl-2 overflow-hd px-1 mb-2 flex-grow-1">
               <div class="thumb">
                  <a href="javascript:void(0)" [routerLink]="['/selectlocation']" [queryParams]="{ category: 'marble'}"
                     style="background-image: url({{tinyUrl}}/products/marble.jpg);">
                  </a>
               </div>
               <div class="productdeatils">Marble</div>
            </div>
            <div class="img-shine col-sm-4 col-xl-2 overflow-hd px-1 mb-2 flex-grow-1">
               <div class="thumb">
                  <a href="javascript:void(0)" [routerLink]="['/selectlocation']" [queryParams]="{ category: 'granite'}"
                     style="background-image: url({{tinyUrl}}/products/granite.jpg);">
                  </a>
               </div>
               <div class="productdeatils">Granite</div>
            </div>
            <div class="img-shine col-sm-4 col-xl-2 overflow-hd px-1 mb-2 flex-grow-1">
               <div class="thumb">
                  <a href="javascript:void(0)" [routerLink]="['/selectlocation']"
                     [queryParams]="{ category: 'quartzite'}"
                     style="background-image: url({{tinyUrl}}/products/quartzite.jpg);">
                  </a>
               </div>
               <div class="productdeatils">Quartzite</div>
            </div>
            <div class="img-shine col-sm-4 col-xl-2 overflow-hd px-1 mb-2 flex-grow-1">
               <div class="thumb">
                  <a href="javascript:void(0)" [routerLink]="['/selectlocation']"
                     [queryParams]="{ category: 'soap-stone'}"
                     style="background-image: url({{tinyUrl}}/products/Black-Soapstone.jpg);">
                  </a>
               </div>
               <div class="productdeatils">Soap Stone</div>
            </div>
            <div class="img-shine col-sm-4 col-xl-2 overflow-hd px-1 mb-2 flex-grow-1">
               <div class="thumb">
                  <a href="javascript:void(0)" [routerLink]="['/selectlocation']"
                     [queryParams]="{ category: 'dolomite'}"
                     style="background-image: url({{tinyUrl}}/products/dolomite.jpg);">
                  </a>
               </div>
               <div class="productdeatils">Dolomite</div>
            </div>
         </div>
      </div>
   </section>
   <!-- GALLERY -->
   <section class="testimonials bg-light">
      <div class="container-fluid py-sm-5 pt-5 pb-0">
         <div class="row">
            <div class="col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="text-dark">GALLERY</h2>
               </div>
            </div>
         </div>
         <div class="row mt-4">
            <div class="col-sm-12">
               <div id="customers-gallery" class="owl-carousel">
                  <!--TESTIMONIAL 1 -->
                  <div class="item">
                     <img class="img-fluid" src="{{tinyUrl}}/gallery/1.jpg" alt="">
                  </div>
                  <div class="item">
                     <img class="img-fluid" src="{{tinyUrl}}/gallery/2.jpg" alt="">
                  </div>
                  <div class="item">
                     <img class="img-fluid" src="{{tinyUrl}}/gallery/3.jpg" alt="">
                  </div>
                  <div class="item">
                     <img class="img-fluid" src="{{tinyUrl}}/gallery/4.jpg" alt="">
                  </div>
                  <div class="item">
                     <img class="img-fluid" src="{{tinyUrl}}/gallery/5.jpg" alt="">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <!-- Location -->
   <section class="py-4 bg-white">
      <div class="container">
         <div class="row">
            <div class="col-md-12 px-sm-0 text-center">
               <div class="large-title">
                  <h2 class="mb-3">IGM Locations</h2>
                  <p class="text-justify mb-1">IGM Surfaces offers a broad range of premium quality domestic to imported
                     slab materials across various locations in the United States and India. Our US locations are
                     Atlanta GA, Houston TX, North Bergen NJ, Quakertown PA &amp; Windsor, CT. </p>
                  <p class="text-justify mt-0 mb-1">In different locations, we have a distinct set of product inventory
                     for quartz and natural stones (marble, granite, quartzite, dolomite, limestone, travertine, and
                     soapstone) that come in various colors, textures, patterns, and finishes. </p>
                  <p class="text-justify mt-0 mb-1">You may find the links to photos along with the descriptions of
                     several products that are available at each of our regional warehouses. Please visit our local
                     warehouse for any of your residential or commercial project requirements and check out the current
                     inventory there.</p>
               </div>
            </div>
         </div>
      </div>
      <div class="container-fluid px-4">
         <div class="row justify-content-center mt-3 colorRepeat" *ngIf="location">
            <ng-container class="tets" *ngFor="let item of location">

               <a role="button"
                  class="rounded img-shine col-sm-4 col-xl overflow-hd px-1 mb-2 flex-grow-1 change-cursore"
                  [ngClass]="{ 'a_disabled': item.city_slug == 'indore' }">
                  <div class="rounded color-box owl-content2">
                     <i class="fa fa-map-marker fa-2x mb-3" aria-hidden="true"></i>
                     <h4 class="cursor-pointer " (click)="navigateRoute(item.city_slug)">{{item.city_name}}
                     </h4>
                     <p style="clear: both;">
                        {{item.address}}
                     </p>
                     <p> <i class="fa fa-phone  mb-1 " aria-hidden="true"></i> {{item.phone}}</p>
                     <p class="d-flex text-break align-items-start justify-content-center "><i
                           class="fa fa-envelope pr-1 pt-1 " aria-hidden="true"></i>{{item.city_mail}}</p>
                  </div>
               </a>
            </ng-container>
         </div>
         <div class="row">
            <div class="col-md-12 text-center igminfobar">
               <p> <span class="home_addr_title"> Home Office:</span> {{getAddres()}} <span
                     *ngIf="getPhone() !== undefined || null">&nbsp;|&nbsp; Phone: <i class="fa fa-phone  mb-1"
                        aria-hidden="true"></i> {{getPhone()}}</span></p>
            </div>
         </div>
      </div>
   </section>
</main>